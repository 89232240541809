import React, { Fragment, useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Col,
  Card,
  CardBody,
  Row,
  Label,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  setSalesDialog,
  getSalesOrderStatus,
} from "../../../store/salesOrderStatusSlice";
import {
  updateSalesOrderStatus,
  downloadSalesOrderPickingTicketPdf,
} from "../../../store/salesOrderSlice";
import { salesOrderStatus } from "../../../../../utils/common";
import SaleOrderDiscussion from "../../view/content/saleOrderDiscussion";

const SalesOrderModal = () => {
  const dispatch = useDispatch();
  const [dropdownStatusOpen, setDropdownStatusOpen] = useState(false);
  const [statusName, setStatusName] = useState("");
  const [loading, isSetLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const authData = useSelector(({ authReducer }) => authReducer);
  const salesDialogProps = useSelector(
    (state) => state.order.saleOrderStatus.salesDialogProps
  );
  const [discussionSection, setDiscussionSection] = useState(false);

  const processPDFDownload = (name) => {
    const orderId = salesDialogProps.data.id;
    const data = {
      id: orderId,
      params: {
        name: name,
        seller: authData.defaultSeller?.value,
      },
    };
    console.log(data);
    dispatch(downloadSalesOrderPickingTicketPdf(data)).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${salesDialogProps.data.order_no}_${name.replace(
          /\s+/g,
          "-"
        )}_${new Date().toJSON().slice(0, 10)}.pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const toggleModal = () => {
    closeDialog();
    const data = {
      search: search,
    };
    dispatch(getSalesOrderStatus(data)).then((res) => {
      if (!res.error) isSetLoading(false);
    });
  };

  const toggleStatusDropdown = () => {
    setDropdownStatusOpen(!dropdownStatusOpen);
  };

  const downloadPicking = () => {
    processPDFDownload("Picking Ticket");
  };

  const handleStatusChange = (newStatusId) => {
    const orderId = salesDialogProps.data.id;

    dispatch(updateSalesOrderStatus({ id: orderId, status_id: newStatusId }))
      .then((res) => {
        console.log("statys : " + res.payload.id);
        setStatusName(res.payload.name || "");
        setDiscussionSection(res.payload.id == 2);
      })
      .catch((error) => {
        console.error("Error updating status:", error);
      });
  };

  const closeDialog = () => {
    const obj = { props: { isOpen: false }, type: "new", data: "" };
    dispatch(setSalesDialog(obj));
  };

  const dropdownStatusOptions = salesOrderStatus.map((status) => (
    <DropdownItem key={status.id} onClick={() => handleStatusChange(status.id)}>
      {status.name}
    </DropdownItem>
  ));

  useEffect(() => {
    if (salesDialogProps.data) {
      setStatusName(salesDialogProps.data.status || "");
      setDiscussionSection(salesDialogProps.data.status_id === 2);
    }
  }, [salesDialogProps.data]);

  return (
    <Modal
      isOpen={salesDialogProps.props.isOpen}
      toggle={toggleModal}
      size="xl"
    >
      <ModalHeader toggle={toggleModal}>
        <p
          style={{ fontSize: "20px" }}
        >{`Sales Order #${salesDialogProps.data.order_no}`}</p>
        <span
          style={{
            fontWeight: "lighter",
            color: "rgb(115, 115, 115)",
            fontSize: "16px",
          }}
        >
          Status :{" "}
          <span
            style={{
              textTransform: "uppercase",
            }}
          >
            {statusName}
          </span>
        </span>
      </ModalHeader>
      <ModalBody>
        {salesDialogProps.data && (
          <>
            <Container fluid={true}>
              <div className="d-flex mb-2 px-2">
                <Dropdown
                  isOpen={dropdownStatusOpen}
                  toggle={toggleStatusDropdown}
                >
                  <DropdownToggle
                    color="info"
                    className="btn btn-sm btn-info"
                    style={{ height: 38, marginRight: 8 }}
                  >
                    Status{" "}
                    <i className="fa fa-caret-down" aria-hidden="true"></i>
                  </DropdownToggle>
                  <DropdownMenu>{dropdownStatusOptions}</DropdownMenu>
                </Dropdown>

                <Button
                  color="info"
                  type="button"
                  className="btn btn-sm btn-warning"
                  style={{ height: 38, marginRight: 8 }}
                  onClick={downloadPicking}
                >
                  Picking Ticket
                </Button>
              </div>

              <Col sm="12" className="p-2">
                {discussionSection && (
                  <Card
                    style={{
                      border: "1px solid rgb(230, 230, 230)",
                      borderRadius: "8px",
                    }}
                  >
                    <CardBody
                      style={{ paddingTop: "24px", paddingBottom: "24px" }}
                    >
                      <Row>
                        <Col sm="6">
                          <Label
                            style={{
                              fontWeight: "bolder",
                              fontSize: "16px",
                              paddingBottom: "10px",
                            }}
                          >
                            Discussion
                          </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          <SaleOrderDiscussion
                            salesOrderId={salesDialogProps.data.id}
                            salesOrderComments={salesDialogProps.data.comments}
                            isInputShow={true}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                )}
              </Col>
            </Container>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default SalesOrderModal;
