import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, Card, Nav } from "reactstrap";
import ModalFormSubmit from "../../../../share-components/form/modalFormSubmit";
import Breadcrumb from "../../../../layout/breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import withReducer from "../../../../store/withReducer";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import userReducer from "../../store";
import {
  closeEditInDialog,
  deleteInternalStaff,
  getInternalStaff,
  getStaffSettings,
  openEditInDialog,
  openNewInDialog,
  setSettings,
  storeInternalStaff,
  updateInternalStaff,
} from "../../store/staffSlice";
import InternalStaffForm from "./internalStaffForm";

const defaultValues = {
  id: "",
  name: "",
  email: "",
  phone: "",
};
const dataMenu = [
  {
    type: 0,
    text: "User",
    link: "/user",
  },
  {
    type: 1,
    text: "Internal Staff",
    link: "",
  },
];

const schema = yup.object().shape({
  name: yup.string().required("This is required field"),
  email: yup.string().required("You must enter an Email"),
});

const InternalStaff = () => {
  const [staffData, setStaffData] = useState([]);
  const [isCreated, setIsCreated] = useState(false);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState,
    reset,
    control,
    register,
    setValue,
    trigger,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { errors } = formState;

  const staffState = useSelector(({ staff }) => staff.staff);

  useEffect(() => {
    dispatch(getStaffSettings()).then((res) => {
      if (!res.error) dispatch(setSettings(res.payload));
    });
  }, [dispatch]);

  const closeDialog = () => {
    dispatch(closeEditInDialog());
  };

  const onSubmitForm = (dialog, data) => {
    const externalStaffIds = data.external_staff
      ? data.external_staff.map((person) => person.id)
      : [];

    const formData = {
      id: data.id,
      name: data.name,
      email: data.email,
      number: data.mobile_number,
      external_staff_ids: externalStaffIds,
    };
    if (dialog.type === "new") {
      dispatch(storeInternalStaff({ ...formData })).then((res) => {
        if (!res.error) {
          setIsCreated(true);
          closeDialog(dialog.type);
        }
      });
    } else {
      dispatch(updateInternalStaff({ ...formData })).then((res) => {
        if (!res.error) {
          closeDialog(dialog.type);
          setIsCreated(true);
        }
      });
    }
  };

  const onEdit = (row) => {
    reset({
      id: row.id,
      name: row.name,
      email: row.email,
      mobile_number: row.mobile_number || "",
      external_staff: row.external_staff || [],
    });
    dispatch(openEditInDialog(row));
  };

  const onDelete = (row) => {
    dispatch(deleteInternalStaff(row.id));
    setIsCreated(true);
  };

  useEffect(() => {
    dispatch(getInternalStaff()).then((res) => {
      setStaffData(res.payload);
      setIsCreated(false);
    });
  }, [dispatch, isCreated]);

  const tableColumns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      center: false,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      left: true,
    },
    {
      name: "Phone",
      selector: (row) => row.mobile_number,
      sortable: true,
      left: true,
    },
  ];

  return (
    <Fragment>
      <Container fluid className="">
        <Breadcrumb data={dataMenu} title="Internal Sales Person" />
        <div>
          <div>
            <Row>
              <Col sm="12">
                <Card>
                  <Row>
                    <Col sm="6">
                      <Nav tabs className="border-tab"></Nav>
                    </Col>
                    <Col sm="6">
                      <div className="float-sm-right p-3">
                        <a
                          className="btn btn-sm btn-primary"
                          onClick={() => {
                            dispatch(openNewInDialog());
                          }}
                        >
                          {" "}
                          <i className="fa fa-plus"></i> Add New Staff
                        </a>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>

            <CommonDataTable
              headerColumns={tableColumns}
              gridData={staffData}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          </div>

          <ModalFormSubmit
            defaultValues={defaultValues}
            schema={schema}
            formState={formState}
            handleSubmit={handleSubmit}
            onSubmitForm={onSubmitForm}
            noAction
            reset={reset}
            title="Internal Sales Person"
            selector="staff"
            dialog={({ staff }) => staff.staff.addDialog}
            closeDialog={closeDialog}
            setValue={setValue}
            getValues={getValues}
            btnText="Save"
          >
            <InternalStaffForm
              control={control}
              errors={errors}
              register={register}
              setValue={setValue}
              trigger={trigger}
              getValues={getValues}
              staffState={staffState}
            />
          </ModalFormSubmit>
        </div>
      </Container>
    </Fragment>
  );
};

export default withReducer([{ staff: userReducer }])(InternalStaff);
