import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Button,
  Col,
  Card,
  CardBody,
  Row,
  Label,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { setSalesBillingDialog } from "../../../store/salesOrderStatusSlice";
import { downloadSalesOrderPickingTicketPdf } from "../../../store/salesOrderSlice";
import SaleOrderDiscussion from "../../view/content/saleOrderDiscussion";

const SalesOrderBillingModal = () => {
  const dispatch = useDispatch();
  const [statusName, setStatusName] = useState("");
  const [loading, isSetLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const authData = useSelector(({ authReducer }) => authReducer);
  const salesBillingDialogProps = useSelector(
    (state) => state.order.saleOrderStatus.salesBillingDialogProps
  );
  const [discussionSection, setDiscussionSection] = useState(false);
  const processPDFDownload = (name) => {
    const orderId = salesBillingDialogProps.data.id;
    const data = {
      id: orderId,
      params: {
        name: name,
        seller: authData.defaultSeller?.value,
      },
    };
    console.log(data);
    dispatch(downloadSalesOrderPickingTicketPdf(data)).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${salesBillingDialogProps.data.order_no}_${name.replace(
          /\s+/g,
          "-"
        )}_${new Date().toJSON().slice(0, 10)}.pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const toggleModal = () => {
    closeDialog();
  };

  const downloadPicking = () => {
    processPDFDownload("Picking Ticket");
  };

  const closeDialog = () => {
    const obj = { props: { isOpen: false }, type: "new", data: "" };
    dispatch(setSalesBillingDialog(obj));
  };

  useEffect(() => {
    if (salesBillingDialogProps.data) {
      setStatusName(salesBillingDialogProps.data.status || "");
      setDiscussionSection(salesBillingDialogProps.data.status_id === 3);
    }
  }, [salesBillingDialogProps.data]);

  return (
    <Modal
      isOpen={salesBillingDialogProps.props.isOpen}
      toggle={toggleModal}
      size="xl"
    >
      <ModalHeader toggle={toggleModal}>
        <p
          style={{ fontSize: "20px" }}
        >{`Sales Order #${salesBillingDialogProps.data.order_no}`}</p>
        <span
          style={{
            fontWeight: "lighter",
            color: "rgb(115, 115, 115)",
            fontSize: "16px",
          }}
        >
          Status :{" "}
          <span
            style={{
              textTransform: "uppercase",
            }}
          >
            {statusName}
          </span>
        </span>
      </ModalHeader>
      <ModalBody>
        {salesBillingDialogProps.data && (
          <>
            <Container fluid={true}>
              <Col sm="12" className="p-2">
                {discussionSection && (
                  <Card
                    style={{
                      border: "1px solid rgb(230, 230, 230)",
                      borderRadius: "8px",
                    }}
                  >
                    <CardBody
                      style={{ paddingTop: "24px", paddingBottom: "24px" }}
                    >
                      <Row>
                        <Col sm="6">
                          <Label
                            style={{
                              fontWeight: "bolder",
                              fontSize: "16px",
                              paddingBottom: "10px",
                            }}
                          >
                            Discussion
                          </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          <SaleOrderDiscussion
                            salesOrderId={salesBillingDialogProps.data.id}
                            salesOrderComments={
                              salesBillingDialogProps.data.comments
                            }
                            isInputShow={false}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                )}
              </Col>
            </Container>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default SalesOrderBillingModal;
