import React, { Fragment, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Form,
  Container,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { CarouseNavigation } from "../../../../../utils/common";
import withReducer from "../../../../../store/withReducer";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PreLoader from "../../../../../share-components/ui/preLoader";
import ButtonLoader from "../../../../../share-components/ui/buttonLoader";
import salesOrderReturnItemReducer from "../../../store";
import {
  editSalesOrderReturn,
  sendReturnItemUpdate,
  showSalesOrderReturnCreditNotes,
} from "../../../store/saleOrderReturnItemSlice";
import ReturnJqx from "./content/returnJqx";
import Breadcrumb from "../../../../../layout/breadcrumb";
import Slider from "react-slick";
import {
  getCountryList,
  getCountryListData,
  setOrderDiscountDialog,
  setShippingDiscountDialog,
  setTaxDialog,
} from "../../../store/draftOrderSlice";
import OrderDiscount from "../../../draft-order/form/content/orderDiscount";
import OrderShipment from "../../../draft-order/form/content/orderShipment";
import OrderTax from "../../../draft-order/form/content/orderTax";
import { returnPaymentCalculation } from "./content/calculations";

const dataMenu = [
  {
    type: 1,
    text: "Return Form",
    link: "/order/sale/return",
  },
  {
    type: 0,
    text: "Return",
  },
];

const settings = {
  dots: false,
  infinite: false,
  slidesToShow: 5,
  slidesToScroll: 1,
  nextArrow: <CarouseNavigation icon="fa-arrow-right" />,
  prevArrow: <CarouseNavigation icon="fa-arrow-left" />,
};

const defaultValues = {
  id: "",
  ordered_products: [],
  credit_notes: [],
  discount: "",
  shipping: "",
  duty: "",
  tax: "",
  currency: "£",
  is_online: 0,
};
const schema = yup.object().shape({
  // customer: yup.object().shape({value: yup.string(),}).nullable().required('You must select a customer.'),
  // shipping: yup.object().shape({rate_name: yup.string(),}).required("shipment is required").typeError('shipment is required'),
});
const ReturnForm = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  let history = useHistory();
  const [handelRowSelect, setHandelRowSelect] = useState(true);
  const draftOrderState = useSelector(({ order }) => order.draftOrder);
  const [payment, setPayment] = useState("");
  const [selectedRowsData, setSelectedRowsData] = useState("");
  const [isButtonClicked, setButtonClicked] = useState(false);
  const [makeRefundOnShopify, setMakeRefundOnShopify] = useState(false);

  const salesOrderInvoiceState = useSelector(
    (state) => state.order.salesOrderInvoice
  );
  const returnState = useSelector(({ order }) => order.salesOrderReturn);
  const {
    handleSubmit,
    formState,
    reset,
    control,
    register,
    setValue,
    trigger,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { errors, isDirty } = formState;

  useEffect(() => {
    dispatch(getCountryList()).then((res) => {
      dispatch(getCountryListData(res.payload));
    });
  }, [dispatch]);

  useEffect(() => {
    let isMounted = true;

    Promise.all([
      dispatch(editSalesOrderReturn(id)),
      dispatch(showSalesOrderReturnCreditNotes(id)),
    ])
      .then(([orderedProductsRes, creditNotesRes]) => {
        if (isMounted) {
          if (!orderedProductsRes.error) {
            setValue("ordered_products", orderedProductsRes.payload);
            console.log("Ordered Products ", orderedProductsRes.payload[0]);
            setValue("is_online", orderedProductsRes.payload[0]?.is_online);
            console.log("Ordered Products is_online ", getValues("is_online"));
          }

          if (!creditNotesRes.error) {
            setValue("credit_notes", creditNotesRes.payload);
          }

          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch, id]);

  console.log("draftOrderState  ", draftOrderState);

  const onSubmit = (data) => {
    setButtonClicked(true);
    const selectedProducts = data.ordered_products
      .filter(function (v, i) {
        return v.is_selected;
      })
      .map(function (item, index) {
        return {
          approve_line_item_id: item.approve_line_item_id,
          quantity: item.quantity,
          is_declined: item.is_declined ? 1 : 0,
          re_stock: item.re_stock == "Yes" ? 1 : 0,
          amount: item.total_price,
        };
      });
    const returnData = {
      approve_id: id,
      items: selectedProducts,
      shipping_charges: getValues("shipping"),
      discount: getValues("discount"),
      duty: getValues("tax"),
      total_amount: selectedProducts
        .filter(function (v, i) {
          return v.is_declined == 0;
        })
        .reduce((sum, product) => sum + product.amount, 0),
      total_net_amount: payment.total,
      total_discount_amount: payment.discountPrice,
      sub_credit_amount: payment.subTotalWithDiscount,
      total_shipping_amount: payment.shippingPrice,
      total_tax_amount: payment.taxPrice,
      is_refund: makeRefundOnShopify ? 1 : 0,
    };
    console.log(returnData);
    dispatch(sendReturnItemUpdate({ ...returnData })).then((res) => {
      if (!res.error) {
        console.log("Success ", res.payload);
        history.push(`/order/sale/return/${id}/view/${res.payload.id}`);
      }
    });
  };

  const handleSelectedRows = (rowData) => {
    console.log("Selected Rows Data:", rowData);
    setSelectedRowsData(rowData);
    setPayment(returnPaymentCalculation(getValues, rowData));
    const currency = getValues("currency");
    console.log("Selected Rows currency Data:", rowData[0]?.currency.symbol);
    setValue("currency", rowData[0]?.currency.symbol);
    console.log("Selected Rows currency Data:", getValues("currency"));
  };

  useEffect(() => {
    setPayment(returnPaymentCalculation(getValues, selectedRowsData));
  }, [getValues("discount"), getValues("shipping")]);
  console.log("Test Discount ", getValues("discount"));

  const handleTotalsChange = (newPayment) => {
    // setPayment(newPayment);
  };
  console.log("Test payment ", payment.totalCreditAmount);

  return (
    <Fragment>
      {loading ? (
        <>
          <PreLoader></PreLoader>
        </>
      ) : (
        <>
          <Breadcrumb title="Return" data={dataMenu} />

          <Container fluid={true} className="product-wrapper">
            <Row className="mt-3">
              <Col
                md={12}
                className={
                  getValues("credit_notes").length > 5 ? "slick-arrow" : ""
                }
              >
                <Slider {...settings}>
                  {getValues("credit_notes").map((item, i) => (
                    <div className="p-1 " key={i}>
                      <Card className={id == item.id ? "active-invoice" : ""}>
                        <CardBody>
                          <Link
                            to={`${process.env.PUBLIC_URL}/order/sale/return/${id}/view/${item.id}`}
                          >
                            <p className="font-weight-bolder f-14">
                              Return ID : {item.id}
                            </p>
                          </Link>
                          <p>Date : {item.date}</p>
                        </CardBody>
                      </Card>
                    </div>
                  ))}
                </Slider>
              </Col>
            </Row>

            <Form noValidate="">
              {/* <Form noValidate="" onSubmit={handleSubmit(onSubmit)}> */}
              {getValues("ordered_products").length > 0 && (
                <div className="product-grid">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md={12}>
                          {getValues("ordered_products").length > 0 && (
                            <ReturnJqx
                              getValues={getValues}
                              setValue={setValue}
                              trigger={trigger}
                              returnState={returnState}
                              orderedProducts={getValues("ordered_products")}
                              handleSelectedRows={handleSelectedRows}
                              onRowsChange={handelRowSelect}
                              onTotalsChange={handleTotalsChange}
                              orderDiscount={getValues("discount")}
                              taxPercentage={
                                getValues("tax") ? getValues("tax").rate : 0
                              }
                              shipmentCost={
                                getValues("shipping")
                                  ? getValues("shipping").fixed_cost
                                  : 0
                              }
                            />
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>

                  {getValues("ordered_products").filter(function (v, i) {
                    return v.is_selected;
                  }).length > 0 && (
                    <>
                      <Row>
                        <Col md={8}>
                          <Card>
                            <CardBody style={{ padding: "24px" }}>
                              <Row>
                                <Col md="12">
                                  <h6 className="pl-4 pb-3">Payment</h6>
                                </Col>
                              </Row>
                              <Col md="12">
                                <table className="table table-borderless">
                                  <tbody>
                                    <tr>
                                      <td>Total Credit Amount</td>
                                      <td></td>
                                      <td align="right">
                                        {getValues("currency")}{" "}
                                        {payment.totalCreditAmount}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          className="a-text-color"
                                          onClick={() => {
                                            const obj = {
                                              props: { isOpen: true },
                                              type: getValues("discount")
                                                ? "edit"
                                                : "new",
                                              data: getValues("discount"),
                                            };
                                            dispatch(
                                              setOrderDiscountDialog(obj)
                                            );
                                          }}
                                        >
                                          {getValues("discount")
                                            ? "Edit"
                                            : "Add"}{" "}
                                          Discount
                                        </a>
                                      </td>
                                      <td>
                                        {getValues("discount")
                                          ? getValues("discount").reason
                                            ? getValues("discount").reason
                                            : "----"
                                          : "----"}
                                      </td>
                                      <td align="right">
                                        {getValues("currency")}{" "}
                                        {payment.discountPrice}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Sub Total Credit</td>
                                      <td></td>
                                      <td align="right">
                                        {getValues("currency")}{" "}
                                        {payment.subTotalWithDiscount}{" "}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          className="a-text-color"
                                          onClick={() => {
                                            const obj = {
                                              props: { isOpen: true },
                                              type: getValues("shipping")
                                                ? "edit"
                                                : "new",
                                              data: getValues("shipping"),
                                            };
                                            dispatch(
                                              setShippingDiscountDialog(obj)
                                            );
                                          }}
                                        >
                                          {getValues("shipping")
                                            ? "Edit"
                                            : "Add"}{" "}
                                          Shipment
                                        </a>
                                      </td>
                                      <td>
                                        {getValues("shipping")
                                          ? getValues("shipping").rate_name
                                            ? getValues("shipping").rate_name
                                            : "----"
                                          : "----"}
                                      </td>
                                      <td align="right">
                                        {getValues("currency")}{" "}
                                        {payment.shippingPrice}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Tax</td>
                                      {/* <td>
                                        <a
                                          className="a-text-color"
                                          onClick={() => {
                                            const data = {
                                              props: { isOpen: true },
                                              type: getValues("tax")
                                                ? "edit"
                                                : "new",
                                              data: getValues("tax"),
                                            };

                                            dispatch(setTaxDialog(data));
                                          }}
                                        >
                                          {getValues("tax") ? "Edit" : "Add"}{" "}
                                          Tax
                                        </a>
                                      </td> */}
                                      <td>
                                        {getValues("tax")
                                          ? getValues("tax").reason
                                            ? getValues("tax").reason
                                            : "----"
                                          : "----"}
                                      </td>
                                      <td align="right">
                                        {getValues("currency")}{" "}
                                        {payment.taxPrice}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan="2">Net Total Credit</td>
                                      <td align="right">
                                        {getValues("currency")} {payment.total}
                                      </td>
                                    </tr>
                                    {getValues("is_online") == 1 ? (
                                      <tr>
                                        <td>
                                          <Label className="pl-3">
                                            <Input
                                              type="checkbox"
                                              className=""
                                              checked={makeRefundOnShopify}
                                              onChange={() =>
                                                setMakeRefundOnShopify(
                                                  !makeRefundOnShopify
                                                )
                                              }
                                            />
                                            Make Refund on Shopify
                                          </Label>
                                        </td>
                                      </tr>
                                    ) : null}
                                  </tbody>
                                </table>
                              </Col>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>

                      <OrderDiscount
                        orderSetValue={setValue}
                        orderTrigger={trigger}
                        orderGetValues={getValues}
                      ></OrderDiscount>
                      <OrderShipment
                        draftOrderState={draftOrderState}
                        orderSetValue={setValue}
                        orderTrigger={trigger}
                        orderGetValues={getValues}
                      ></OrderShipment>
                      <OrderTax
                        orderSetValue={setValue}
                        orderTrigger={trigger}
                        orderGetValues={getValues}
                      ></OrderTax>

                      <Card>
                        <CardBody style={{ padding: "24px" }}>
                          <Row>
                            <Col md={12}>
                              <div className="float-right">
                                <ButtonLoader
                                  onClick={handleSubmit(onSubmit)}
                                  color="secondary"
                                  value="1"
                                  btntext="Save"
                                  disabled={isButtonClicked}
                                ></ButtonLoader>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </>
                  )}
                </div>
              )}
            </Form>
          </Container>
        </>
      )}
    </Fragment>
  );
};

export default withReducer([
  {
    SalesOrderReturnItem: salesOrderReturnItemReducer,
  },
])(ReturnForm);
