import React, { Fragment,useState,useEffect } from 'react'
import ReactDOM from 'react-dom';
import './index.scss';
import {firebase_app, auth0} from './data/config';
import {BrowserRouter,Switch,Route,Redirect} from 'react-router-dom'
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
// import { Auth0Provider } from '@auth0/auth0-react'
import store from './store'
import App from './components/app'
import { CSSTransition,TransitionGroup } from 'react-transition-group'
import {routes} from './route';
import ConfigDB  from './data/customizer/config'
import {configureFakeBackend ,authHeader, handleResponse} from './services/fack.backend'

// Signin page
import Signin from './auth/signin'

// Authentication
import Login from "./pages/authentication/login"
import LoginWithBgImage from "./pages/authentication/loginWithBgImage"
import LoginWithBgVideo from "./pages/authentication/loginWithBgVideo"
import LoginWithValidation from "./pages/authentication/loginwithValidation"
import Register from "./pages/authentication/register"
import RegisterWithBgImage from "./pages/authentication/registerWithBgImage"
import RegisterWithBgVideo from "./pages/authentication/registerWithBgVideo"
import UnlockUser from "./pages/authentication/unlockUser"
import Forgetpwd from "./pages/authentication/forgetpwd"
import Resetpwd from "./pages/authentication/resetpwd"



import Callback from './auth/callback'
import { classes } from './data/layouts';
import Auth from "./auth/Auth";
import Routes from "./auth/Routes";
import {ToastContainer} from "react-toastify";


// setup fake backend
configureFakeBackend();


const Root = (props) =>  {




    return(
        <Fragment>
            {/*<Auth0Provider domain={auth0.domain} clientId={auth0.clientId} redirectUri={auth0.redirectUri}>*/}
                <Provider store={store}>
                    <BrowserRouter basename={`/`}>
                        <Auth>
                          <Routes/>
                        </Auth>
                    </BrowserRouter>
                </Provider>
            {/*</Auth0Provider>*/}
            <ToastContainer autoClose={1000}/>
        </Fragment>
    )
}

ReactDOM.render(<Root/>,
    document.getElementById('root')
);
serviceWorker.unregister();
