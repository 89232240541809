import { combineReducers } from "redux";
import shippingSetting from "./shippingSettingSlice";
import returnReasonSetting from "./returnReasonSettingSlice";
import closeReasonSetting from "./closeReasonSettingSlice";
import paymentMethodSetting from "./paymentMethodSettingSlice";
import deliveryPartnerSetting from "./deliveryPartnerSlice";
import shipperSetting from "./shipperSlice";

const orderSettingReducer = combineReducers({
  shippingSetting,
  returnReasonSetting,
  closeReasonSetting,
  paymentMethodSetting,
  deliveryPartnerSetting,
  shipperSetting,
});

export default orderSettingReducer;
