import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import { Col, Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import salesOrderReducer from "../../store";
import withReducer from "../../../../store/withReducer";
import PreLoader from "../../../../share-components/ui/preLoader";
import { Link, useParams } from "react-router-dom";
import ReturnInvoice from "./invoice";
import { useForm } from "react-hook-form";

const defaultValues = {
  id: "",
  sales_order_id: "",
};

const dataMenu = [
  {
    type: 1,
    text: "Sales Order",
    link: "/order/sale",
  },
  {
    type: 0,
    text: "Sales Order Return",
  },
];

const ReurnForm = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  return (
    <Fragment>
      <Breadcrumb title="Order return" data={dataMenu} />
      <Container fluid={true} className="product-wrapper">
        <ReturnInvoice></ReturnInvoice>
      </Container>
    </Fragment>
  );
};

export default withReducer([{ salesOrder: salesOrderReducer }])(ReurnForm);
