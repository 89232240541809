import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  ModalFooter,
  Form,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import PreLoader from "../../../../../share-components/ui/preLoader";
import {
  setSalesOrderBinLocationDialogProps,
  showSalesOrderItemLocation,
  updateSalesOrderItemLocation,
} from "../../../store/salesOrderSlice";
import Select from "react-select";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";

const customStyles = {
  control: (base) => ({
    ...base,
    minHeight: 50,
    width: 250,
    border: "none",
  }),
};

const defaultValues = {
  bin_locations: [],
};
const schema = yup.object().shape({
  bin_locations: yup.array().of(
    yup.object().shape({
      quantity: yup.string().when("is_selected", {
        is: true,
        then: yup
          .string()
          .required("this Field is required")
          .test(
            "greater-than-or-equal",
            "should be less than available",
            function (value) {
              // You can access other field values using this.parent
              return value <= this.parent.available;
            }
          ),
        otherwise: yup.string(),
      }),
    })
  ),
});

const BinLocationModal = (props) => {
  const { orderSetValue, orderTrigger, orderGetValues, onChangeDialog } = props;
  const dispatch = useDispatch();
  const binInputRef = useRef({});
  let inventoryInputRef = useRef([]);
  // const { handleSubmit, setValue, trigger, getValues, reset } = useForm({
  //   mode: "onChange",
  //   defaultValues,
  //   resolver: yupResolver(schema),
  // });
  const {
    handleSubmit,
    formState,
    reset,
    control,
    register,
    setValue,
    handleChange,
    trigger,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { fields, append, remove } = useFieldArray({
    name: "bin_locations",
    control,
  });
  const { errors, isDirty } = formState;
  const [loading, isSetLoading] = useState(false);
  const [locations, setLocations] = useState(false);
  const [locationInventory, setLocationInventory] = useState(null);
  const [checkboxStates, setCheckboxStates] = useState({});
  const [inputValues, setInputValues] = useState({});
  const [bins, setBins] = useState(null);
  const [packSize, setPackSize] = useState(null);
  const [locationId, setLocationId] = useState(0);
  const [locationQuantity, setLocationQuantity] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [isPack, setIsPack] = useState(false);
  const [ratioName, setRatioName] = useState("");
  const authData = useSelector(({ authReducer }) => authReducer);
  const salesDialogProps = useSelector(
    (state) => state.order.salesOrder.salesOrderBinLocationDialogProps
  );
  console.log("salesDialogProps : ", salesDialogProps);
  const lineItemId = salesDialogProps.data.id;

  const toggleModal = () => {
    setBins(null);
    setLocationInventory(null);
    reset(defaultValues);
    closeDialog();
  };

  const closeDialog = () => {
    const obj = { props: { isOpen: false }, type: "new", data: "" };
    dispatch(setSalesOrderBinLocationDialogProps(obj));
  };

  const onChildSubmit = (data) => {
    console.log("Submitted data: ", data);
    console.log("Submitted locationId: ", locationId);

    const binLocation = [];
    data.bin_locations.map(function (item, index) {
      if (item.is_selected) {
        binLocation.push({
          quantity: Number(item.quantity),
          bin_number: item.bin_number,
        });
      }
    });
    const bin_locations = binLocation;
    const selectedLocation = locations.find(
      (location) => location.id === locationInventory
    );

    const selectedLocationId = selectedLocation
      ? selectedLocation.location_id
      : "";

    if (isPack) {
      const selectedPackVariant = packSize ? packSize[activeTab] : null;
      const packVariantId = selectedPackVariant
        ? selectedPackVariant.product_variant_id
        : null;

      dispatch(
        updateSalesOrderItemLocation({
          id: lineItemId,
          location_id: selectedLocationId ? selectedLocationId : locationId,
          bin_locations: bin_locations,
          pack_variant_id: packVariantId,
          is_pack: isPack,
        })
      ).then((res) => {
        if (!res.error) {
          console.log("orderProducts ", bin_locations);
          toggleModal();
        }
      });
    } else {
      dispatch(
        updateSalesOrderItemLocation({
          id: lineItemId,
          location_id: selectedLocationId ? selectedLocationId : locationId,
          bin_locations: bin_locations,
          is_pack: isPack,
        })
      ).then((res) => {
        if (!res.error) {
          console.log("orderProducts ", bin_locations);
          toggleModal();
        }
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (lineItemId) {
        isSetLoading(true);

        const locationID = salesDialogProps.data.location[0];
        setLocationId(locationID?.location_id);

        try {
          const indexRes = await dispatch(
            showSalesOrderItemLocation(lineItemId)
          );

          if (!indexRes.error) {
            setLocations(indexRes.payload.location);

            const selectedLocation = indexRes.payload.location[0];
            const locationInventory = selectedLocation.location_inventory;
            const packVariants = selectedLocation.pack_variants;
            console.log("Selected location ", locationInventory);
            const inventoryData = [];

            setRatioName(selectedLocation.ratio_name);
            setIsPack(selectedLocation.is_pack);

            console.log("packVariants ", packVariants);
            setPackSize(packVariants);
            console.log("locationQuantity ", locationQuantity);
            setBins(locationInventory);

            if (isPack) {
              console.log("isPack T ", isPack);
              const selectedPackVariant = selectedLocation.pack_variants
                ? selectedLocation.pack_variants[activeTab]
                : null;
              console.log("selectedPackVariant T ", selectedPackVariant);
              const defaultPackBinLocations =
                selectedPackVariant.location_inventory.map((item) => ({
                  is_selected: item.quantity > 0,
                  quantity: item.quantity,
                  bin_number: item.bin_number,
                  available: item.available,
                }));

              selectedPackVariant.location_inventory.map(function (
                item,
                index
              ) {
                inventoryData.push({
                  is_selected: item.quantity > 0,
                  bin_number: item.bin_number,
                  product_variant_id: item.product_variant_id,
                  available: item.available,
                  quantity: item.quantity > 0 ? item.quantity : "",
                });
              });
              const data = {
                bin_locations: inventoryData,
              };
              reset(data);

              setLocationQuantity(
                selectedPackVariant ? selectedPackVariant.quantity : null
              );

              const defaultCheckboxStates = {};
              const defaultInputValues = {};

              defaultPackBinLocations.forEach((item, i) => {
                defaultCheckboxStates[i] = item.is_selected;
                defaultInputValues[i] = item.quantity;
              });

              setCheckboxStates(defaultCheckboxStates);
              setInputValues(defaultInputValues);
              reset({
                bin_locations: selectedPackVariant.location_inventory.map(
                  (item, i) => ({
                    is_selected: defaultCheckboxStates[i],
                    quantity: defaultInputValues[i] || "",
                    bin_number: item.bin_number,
                    available: item.available,
                  })
                ),
              });
            } else {
              console.log("isPack F ", isPack);

              locationInventory.map(function (item, index) {
                inventoryData.push({
                  is_selected: item.quantity > 0,
                  bin_number: item.bin_number,
                  product_variant_id: item.product_variant_id,
                  available: item.available,
                  quantity: item.quantity > 0 ? item.quantity : "",
                });
              });
              console.log("inventoryData ", inventoryData);
              const data = {
                bin_locations: inventoryData,
              };
              reset(data);

              setLocationQuantity(selectedLocation.quantity);
              const defaultBinLocations = locationInventory.map((item) => ({
                is_selected: item.quantity > 0,
                quantity: item.quantity,
                bin_number: item.bin_number,
                available: item.available,
              }));

              const defaultCheckboxStates = {};
              const defaultInputValues = {};

              defaultBinLocations.forEach((item, i) => {
                defaultCheckboxStates[i] = item.is_selected;
                defaultInputValues[i] = item.quantity;
              });

              console.log("defaultBinLocations ", defaultBinLocations);
              console.log("bins ", bins);

              setCheckboxStates(defaultCheckboxStates);
              setInputValues(defaultInputValues);

              reset({
                bin_locations: defaultBinLocations.map((item, i) => ({
                  ...item,
                  quantity: defaultInputValues[i] || "",
                })),
              });
            }
            console.log("locationQuantity ", locationQuantity);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          isSetLoading(false);
        }
      }
    };

    fetchData();
  }, [dispatch, lineItemId, reset, activeTab, isPack]);

  const enableButton = () => {
    if (salesDialogProps.props.isOpen) {
      const total = getValues("bin_locations")
        .filter(function (v, i) {
          return v.is_selected;
        })
        .reduce(
          (accumulator, currentValue) =>
            accumulator + Number(currentValue.quantity),
          0
        );

      return total == locationQuantity;
    } else {
      return false;
    }
  };

  return (
    <Modal
      isOpen={salesDialogProps.props.isOpen}
      toggle={toggleModal}
      size={isPack ? "lg" : "md"}
    >
      {loading ? (
        <>
          <PreLoader></PreLoader>
        </>
      ) : (
        <>
          <ModalHeader toggle={toggleModal}>
            <p style={{ fontSize: "20px" }}>Update Bin Number</p>

            <span
              style={{
                fontWeight: "lighter",
                color: "rgb(115, 115, 115)",
                fontSize: "16px",
              }}
            >
              <span
                style={{
                  textTransform: "uppercase",
                }}
              >
                {salesDialogProps.data.sku}
              </span>
            </span>
          </ModalHeader>
          <Form noValidate="" onSubmit={handleSubmit(onChildSubmit)}>
            <ModalBody>
              {salesDialogProps.data && (
                <>
                  <Container fluid={true}>
                    <Row>
                      <Col md={12}>
                        {isPack ? (
                          <>
                            {ratioName && (
                              <div className="pull-left mb-3 pt-3">
                                <span>
                                  <b>Ratio</b> : {ratioName}
                                </span>
                              </div>
                            )}
                          </>
                        ) : null}
                        <div className="pull-right mb-3">
                          {locations.length > 1 && (
                            <Select
                              className="select2-filter border"
                              isClearable
                              cacheOptions
                              styles={customStyles}
                              placeholder="Select Location"
                              defaultValue={
                                locations
                                  ? locations.find(
                                      (loc) => loc.location_id === locationId
                                    )
                                  : null
                              }
                              getOptionLabel={(option) => option.location_name}
                              getOptionValue={(option) => option.location_id}
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                              onChange={(e) => {
                                console.log("Selected location:", e);
                                const selectedLocation = e
                                  ? locations.find(
                                      (location) => location.id === e.id
                                    )
                                  : null;
                                setIsPack(selectedLocation.is_pack);
                                console.log(
                                  "Selected selectedLocation:",
                                  selectedLocation
                                );
                                const inventoryData = [];

                                (isPack
                                  ? selectedLocation.pack_variants[0]
                                      .location_inventory
                                  : selectedLocation?.location_inventory
                                ).map(function (item, index) {
                                  inventoryData.push({
                                    is_selected: item.quantity > 0,
                                    bin_number: item.bin_number,
                                    product_variant_id: item.product_variant_id,
                                    available: item.available,
                                    quantity:
                                      item.quantity > 0 ? item.quantity : "",
                                  });
                                });
                                const data = {
                                  bin_locations: inventoryData,
                                };
                                reset(data);
                                setBins(
                                  selectedLocation
                                    ? selectedLocation.location_inventory
                                    : null
                                );
                                setLocationInventory(
                                  selectedLocation
                                    ? selectedLocation.location_id
                                    : null
                                );
                                setLocationQuantity(
                                  selectedLocation
                                    ? selectedLocation.quantity
                                    : null
                                );
                                setLocationId(e.location_id);

                                console.log("Selected bins:", bins);

                                const defaultCheckboxStates = {};
                                const defaultInputValues = {};

                                if (selectedLocation) {
                                  selectedLocation.bin_location.forEach(
                                    (item, i) => {
                                      defaultCheckboxStates[i] = true;
                                      defaultInputValues[i] = item.quantity;
                                    }
                                  );
                                }

                                setCheckboxStates(defaultCheckboxStates);
                                setInputValues(defaultInputValues);
                              }}
                              options={locations ? locations : null}
                            />
                          )}
                        </div>
                      </Col>
                      <Col md={12}>
                        <div>
                          {isPack ? (
                            <>
                              <Nav tabs>
                                {packSize &&
                                  packSize.map((variant, index) => (
                                    <NavItem key={index}>
                                      <NavLink
                                        className={
                                          activeTab === index ? "active" : ""
                                        }
                                        onClick={() => setActiveTab(index)}
                                      >
                                        Size {variant.size}
                                      </NavLink>
                                    </NavItem>
                                  ))}
                              </Nav>
                              <TabContent activeTab={activeTab}>
                                {packSize &&
                                  packSize.map((variant, index) => (
                                    <TabPane key={index} tabId={index}>
                                      <Row>
                                        <Col sm="12">
                                          {variant.location_inventory ? (
                                            <table className="table">
                                              <tbody>
                                                {fields.map((item, i) => (
                                                  <tr key={i + Date.now()}>
                                                    <td>
                                                      <input
                                                        type={"checkbox"}
                                                        checked={getValues(
                                                          `bin_locations.${i}.is_selected`
                                                        )}
                                                        onChange={(e) => {
                                                          setValue(
                                                            `bin_locations.${i}.is_selected`,
                                                            e.target.checked
                                                          );
                                                          setValue(
                                                            `bin_locations.${i}.quantity`,
                                                            e.target.checked
                                                              ? ""
                                                              : ""
                                                          );
                                                          // inventoryInputRef.current[item.id].disabled=!e.target.checked;
                                                          // inventoryInputRef.current[item.id].value=e.target.checked?1:"";
                                                          trigger(
                                                            `bin_locations.${i}.is_selected`
                                                          );
                                                        }}
                                                      />
                                                    </td>

                                                    <td className="pt-3">
                                                      <span className="pt-1">
                                                        {item.bin_number}{" "}
                                                        (Available{" "}
                                                        {item.available})
                                                      </span>
                                                    </td>

                                                    <td>
                                                      <input
                                                        ref={(ref) => {
                                                          inventoryInputRef.current[
                                                            item.id
                                                          ] = ref;
                                                        }}
                                                        disabled={
                                                          !getValues(
                                                            `bin_locations.${i}.is_selected`
                                                          )
                                                        }
                                                        value={getValues(
                                                          `bin_locations.${i}.quantity`
                                                        )}
                                                        type="text"
                                                        onChange={(e) => {
                                                          setValue(
                                                            `bin_locations.${i}.quantity`,
                                                            e.target.value
                                                          );
                                                          trigger(
                                                            `bin_locations.${i}.quantity`
                                                          );
                                                        }}
                                                        className="form-control"
                                                        style={{ width: 75 }}
                                                      />
                                                      <div
                                                        style={{
                                                          color: "red",
                                                          float: "right",
                                                        }}
                                                      >
                                                        {
                                                          errors
                                                            .bin_locations?.[i]
                                                            ?.quantity?.message
                                                        }
                                                      </div>
                                                    </td>
                                                  </tr>
                                                ))}
                                              </tbody>
                                            </table>
                                          ) : (
                                            <p>
                                              No location inventory available
                                              for this size.
                                            </p>
                                          )}
                                        </Col>
                                      </Row>
                                    </TabPane>
                                  ))}
                              </TabContent>
                            </>
                          ) : (
                            <>
                              {bins && (
                                <table className="table">
                                  <tbody>
                                    {fields.map((item, i) => (
                                      <tr key={i + Date.now()}>
                                        <td>
                                          <input
                                            type={"checkbox"}
                                            checked={getValues(
                                              `bin_locations.${i}.is_selected`
                                            )}
                                            onChange={(e) => {
                                              setValue(
                                                `bin_locations.${i}.is_selected`,
                                                e.target.checked
                                              );
                                              setValue(
                                                `bin_locations.${i}.quantity`,
                                                e.target.checked ? "" : ""
                                              );
                                              // inventoryInputRef.current[item.id].disabled=!e.target.checked;
                                              // inventoryInputRef.current[item.id].value=e.target.checked?1:"";
                                              trigger(
                                                `bin_locations.${i}.is_selected`
                                              );
                                            }}
                                          />
                                        </td>

                                        <td className="pt-3">
                                          <span className="pt-1">
                                            {item.bin_number} (Available{" "}
                                            {item.available})
                                          </span>
                                        </td>

                                        <td>
                                          <input
                                            ref={(ref) => {
                                              inventoryInputRef.current[
                                                item.id
                                              ] = ref;
                                            }}
                                            disabled={
                                              !getValues(
                                                `bin_locations.${i}.is_selected`
                                              )
                                            }
                                            value={getValues(
                                              `bin_locations.${i}.quantity`
                                            )}
                                            type="text"
                                            onChange={(e) => {
                                              setValue(
                                                `bin_locations.${i}.quantity`,
                                                e.target.value
                                              );
                                              trigger(
                                                `bin_locations.${i}.quantity`
                                              );
                                            }}
                                            className="form-control"
                                            style={{ width: 75 }}
                                          />
                                          <div
                                            style={{
                                              color: "red",
                                              float: "right",
                                            }}
                                          >
                                            {
                                              errors.bin_locations?.[i]
                                                ?.quantity?.message
                                            }
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )}
                            </>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </>
              )}
            </ModalBody>
            <ModalFooter>
              {enableButton() && (
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={handleSubmit(onChildSubmit)}
                >
                  Save
                </button>
              )}
            </ModalFooter>
          </Form>
        </>
      )}
    </Modal>
  );
};

export default BinLocationModal;
