import { combineReducers } from "@reduxjs/toolkit";
import user from "./userSlice";
import role from "./roleSlice";
import permission from "../permission/store/permissionSlice";
import agent from "./agentSlice";
import staff from "./staffSlice";

const userReducer = combineReducers({
  user,
  role,
  permission,
  agent,
  staff,
});

export default userReducer;
