import axios from 'axios';
import {Redirect} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {toast} from "react-toastify";
// eslint-disable-next-line import/no-cycle
// import { logoutUser } from '../auth/store/userSlice';

// import { showErrorMessage } from '../store/fuse/messageSlice';


export const host = window.location.hostname == "localhost" ? 'http://wa.localhost/' : `${window.location.origin}/v1/`;
//const baseURL = `${window.location.origin}/v1/api`;
 const baseURL = host + 'api';
//const baseURL = 'http://mp.localhost/api';

export const baseUrlAsync = baseURL;

const API = axios.create({
    baseURL
});

export const {CancelToken} = axios;
const currentExecutingRequests = {};
export const source = CancelToken.source();
API.interceptors.request.use(

    config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
            // config.headers.credentials=true;
        }
        // config.headers.crossOrigin="*";
        return config;
    },
    async error => {
        console.log(error);
        return Promise.reject(error);
    },
    // (req) => {
    //     let originalRequest = req;
    //
    //     if (currentExecutingRequests[req.url]) {
    //         const source = currentExecutingRequests[req.url];
    //         delete currentExecutingRequests[req.url];
    //         source.cancel();
    //     }
    //
    //     const CancelToken = axios.CancelToken;
    //     const source = CancelToken.source();
    //     originalRequest.cancelToken = source.token;
    //     currentExecutingRequests[req.url] = source;
    //
    //
    //     return originalRequest;
    // },
)


API.interceptors.response.use(
    response => {
        // console.log(response);
        return response.data;
    },
    async error => {
        // console.log(error);
        // await showErrorMessage(error.response.data);
        // alert(JSON.stringify(error));
        if (error.response) {
            //  alert(JSON.stringify(error.response));
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
            if (error.response.status === 401) {
                // user is unauthorized, logout

                try {
                    // await logoutUser();
                } catch (e) {
                    return Promise.reject(e);
                }
            }
            if (error.response.status === 422) {
                console.log(error.response);
                toast.error(error.response.data, {position: toast.POSITION.TOP_RIGHT});
                return Promise.reject(error.response.data);
            }
            if (error.response.status === 403) {
                const history = createBrowserHistory({forceRefresh: true});
                history.push('/error/permission');
               // window.location.reload();
                // eslint-disable-next-line no-restricted-globals
            }
            if (error.response.status === 404) {
              //  const history = createBrowserHistory({forceRefresh: true});
                //history.push('/error/not-found');
               // window.location.reload();
                // eslint-disable-next-line no-restricted-globals
            }
            if (error.response.status === 401) {
                toast.error(error.response.data, {position: toast.POSITION.TOP_RIGHT});
                return Promise.reject(error.response.data);
            }
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            // console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            // console.log('Error', error.message);
        }
        return Promise.reject(error);
    }
);

export default API;
