import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import {
  addToCartApi,
  amendQuantityApi,
  checkoutApi,
  getCartApi,
  getCheckoutSettingsApi,
  getShoppingProductApi,
  getShoppingProductByIdApi,
  getShoppingSettingApi,
  removeCartItemApi,
  updateToCartApi,
} from "../../../services/shoppingService";
import { filerValues } from "../../manage-product/product/edit/content/settings/map";

export const getShoppingProduct = createAsyncThunk(
  "shopping/getProducts",
  async (data, { dispatch }) => {
    const response = await getShoppingProductApi(data);
    dispatch(setPagination(response.response.pagination));
    return response.response.data;
  }
);

export const getShoppingSetting = createAsyncThunk(
  "shopping/getSettings",
  async (data, { dispatch }) => {
    const response = await getShoppingSettingApi(data);
    return response.response;
  }
);

export const getCheckoutShoppingSetting = createAsyncThunk(
  "shopping/getChecoutSettings",
  async (data, { dispatch }) => {
    const response = await getCheckoutSettingsApi();
    return response.response;
  }
);
export const getShoppingProductById = createAsyncThunk(
  "shopping/getProductById",
  async (id, { dispatch }) => {
    const response = await getShoppingProductByIdApi(id);
    return response.response;
  }
);

export const addToCart = createAsyncThunk(
  "shopping/addToCart",
  async (data, { dispatch }) => {
    const response = await addToCartApi(data);
    toast.success(`product added successfully`, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response.response;
  }
);

export const getCart = createAsyncThunk(
  "shopping/getCartById",
  async (id, { dispatch }) => {
    const response = await getCartApi(id);
    return response.response;
  }
);

export const updateToCart = createAsyncThunk(
  "shopping/addToCart",
  async (data, { dispatch }) => {
    const response = await updateToCartApi(data.id, data.data);
    toast.success(`product updated successfully`, {
      position: toast.POSITION.TOP_RIGHT,
      // autoClose:1000
    });
    return response.response;
  }
);

export const amendQuantity = createAsyncThunk(
  "shopping/amendQuantity",
  async (data, { dispatch }) => {
    const response = await amendQuantityApi(data);
    toast.success(`quantity updated successfully`, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response.response;
  }
);

export const removeCartITem = createAsyncThunk(
  "shopping/removeCartITem",
  async (data, { dispatch }) => {
    const response = await removeCartItemApi(data);
    toast.success(`removed successfully`, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response.response;
  }
);

export const checkoutCartITem = createAsyncThunk(
  "shopping/checkoutCartItem",
  async (data, { dispatch }) => {
    const response = await checkoutApi(data);
    toast.success(`checkout successfully`, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response.response;
  }
);
const shoppingAdapter = createEntityAdapter({});

export const {
  selectAll: selectShoppingProduct,
  selectById: selectShoppingProductById,
} = shoppingAdapter.getSelectors((state) => state.shopping.shopping);

const shoppingSlice = createSlice({
  name: "shopping",
  initialState: shoppingAdapter.getInitialState({
    pagination: {
      count: 1,
      total: 1,
      page_total: 1,
    },
    productViewDialogProps: {
      data: null,
      props: {
        isOpen: false,
      },
    },
    product: null,
    shoppingIndexSettings: {
      brands: [],
      categories: [],
      tags: [],
      commodities: [],
      countries: [],
      seasons: [],
      styles: [],
      compositions: [],
      tag_settings: [],
      group_product: [],
      types: [],
    },
    filter: filerValues(1),
    settings: {
      brands: [],
      categories: [],
      tags: [],
      commodities: [],
      countries: [],
      seasons: [],
      styles: [],
      compositions: [],
      tag_settings: [],
      group_product: [],
      types: [],
    },
    checkoutSettings: {},
    cartItems: [],
  }),
  reducers: {
    setSettings: (state, action) => {
      state.settings = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setProductFilter: (state, action) => {
      state.filter = action.payload;
    },
    setProductViewDialog: (state, action) => {
      state.productViewDialogProps = action.payload;
    },
    setProductData: (state, action) => {
      state.product = action.payload;
    },
    setCartItemData: (state, action) => {
      state.cartItems = action.payload;
    },
    setCheckoutSettingsData: (state, action) => {
      state.checkoutSettings = action.payload;
    },
    setShoppingIndexSettings: (state, action) => {
      state.shoppingIndexSettings = action.payload;
    },
  },
  extraReducers: {
    [getShoppingProduct.fulfilled]: shoppingAdapter.setAll,
    // [updateStaff.fulfilled]: staffsAdapter.setAll
    // [deleteStaff.fulfilled]: staffsAdapter.removeOne,
  },
});

export const {
  openNewDialog,
  setPagination,
  setProductFilter,
  setSettings,
  setProductViewDialog,
  setProductData,
  setCartItemData,
  setCheckoutSettingsData,
  setShoppingIndexSettings,
} = shoppingSlice.actions;

export default shoppingSlice.reducer;
