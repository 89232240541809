import React, {useState} from 'react';
import {Card, CardBody, Col, Row, Table} from "reactstrap";



function InventoryVariantDetail(props) {
    const {inventoryState}=props;


    return (
        <>
            <Card>
                <CardBody>
                    <Row>
                        <Col md={6}>
                            <img src={inventoryState.variant.image} className="img-fluid"></img>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col md={12}>
                                    <span className="f-w-600">SKU</span>
                                </Col>
                                <Col md={12}>
                                    <span className="f-w-100">{inventoryState.variant.sku}</span>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col md={12}>
                                    <span className="f-w-600">Title</span>
                                </Col>
                                <Col md={12}>
                                    <span className="f-w-100">{inventoryState.variant.product_name}</span>
                                </Col>
                            </Row>
                            {(inventoryState.variant.pack && !inventoryState.settings.is_stock_count_mode) && <Row className="mt-3">
                                <Col md={12}>
                                    <span className="f-w-600">Pack Ratio</span>
                                </Col>
                                <Col md={12}>
                                    <span className="f-w-100">{inventoryState.variant.pack.ratio_name}</span>
                                </Col>
                            </Row>}

                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col md={12}>
                            <Row>
                                <Col md={12}>
                                    <span className="f-w-600">Quantity</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    <Row>
                                        <Col md={12} className="mt-1">
                                            <span className="f-w-100">Available</span>
                                        </Col>
                                        <Col md={12} className="mt-1">
                                            <span className="f-w-100">{inventoryState.variant.available_quantity}</span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={4}>
                                    <Row>
                                        <Col md={12} className="mt-1">
                                            <span className="f-w-100">Committed </span>
                                        </Col>
                                        <Col md={12} className="mt-1">
                                            <span className="f-w-100">{inventoryState.variant.committed_quantity}</span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={4}>
                                    <Row>
                                        <Col md={12} className="mt-1">
                                            <span className="f-w-100">On Hand</span>
                                        </Col>
                                        <Col md={12} className="mt-1">
                                            <span className="f-w-100">{inventoryState.variant.on_hand_quantity}</span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>



                        </Col>

                    </Row>
                    {/*<Row className='mt-5 d-none d-sm-block'>*/}
                    {/*    <Col md={12}>*/}
                    {/*        <div className="table-responsive w-100">*/}
                    {/*            <Table>*/}
                    {/*                <thead className="thead-light">*/}
                    {/*                <tr>*/}
                    {/*                    <th style={{width: "50%"}}>Bin</th>*/}
                    {/*                    <th style={{width: "50%"}}>Quantity</th>*/}
                    {/*                </tr>*/}
                    {/*                </thead>*/}
                    {/*                <tbody>*/}
                    {/*                {inventoryState.variant.inventories.map((item, i) => (<tr key={i}>*/}
                    {/*                    <td>{item.bin_number}</td>*/}
                    {/*                    <td>{item.available}</td>*/}
                    {/*                </tr>))}*/}
                    {/*                </tbody>*/}
                    {/*            </Table>*/}
                    {/*        </div>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                </CardBody>
            </Card>
        </>
);
}

export default InventoryVariantDetail;