import React, { useEffect } from "react";
import { Card, CardBody, Col } from "reactstrap";
import { Row } from "react-bootstrap";

import {
  setOrderDiscountDialog,
  setShippingDiscountDialog,
} from "../../../store/draftOrderSlice";
import { useDispatch } from "react-redux";
import { saleOrderPaymentCalculation } from "../../setting/calculation";
import { setDutyDialog } from "../../../store/salesOrderSlice";

function SaleOrderPayment(props) {
  const dispatch = useDispatch();
  const { setValue, trigger, getValues, currency = "" } = props;
  const payment = saleOrderPaymentCalculation(getValues, currency);
  useEffect(() => {
    const numericTotal = parseFloat(payment.total.replace(/[^\d.]/g, ""));
    const numericDisountTotal = parseFloat(
      payment.itemDiscount.replace(/[^\d.]/g, "")
    );
    const numericTaxTotal = parseFloat(payment.taxPrice.replace(/[^\d.]/g, ""));
    const numericSubTotalTotal = parseFloat(
      payment.subTotalWithDiscount.replace(/[^\d.]/g, "")
    );
    const numericDiscountTotal = parseFloat(
      payment.discountPrice.replace(/[^\d.]/g, "")
    );

    setValue("total_price", numericTotal);
    setValue("total_tax", numericTaxTotal);
    setValue("total_item_discount", numericDisountTotal);
    setValue("total_sub_total", numericSubTotalTotal);
    setValue("total_discount", numericDiscountTotal);
  }, [payment]);

  return (
    <Card>
      <CardBody>
        <Row>
          <Col md="12">
            <h6>Payment</h6>
          </Col>
        </Row>
        <Row className="mt-4 mb-4">
          <Col md="12">
            <table className="table table-borderless">
              <tbody>
                <tr>
                  <td>Total Amount</td>
                  <td></td>
                  <td align="right">{payment.subTotal}</td>
                </tr>
                <tr>
                  <td>Item wise Discount</td>
                  <td></td>
                  <td align="right">{payment.itemDiscount}</td>
                </tr>
                <tr>
                  <td>
                    <a
                      className="a-text-color"
                      onClick={() => {
                        const obj = {
                          props: { isOpen: true },
                          type: getValues("discount") ? "edit" : "new",
                          data: getValues("discount"),
                        };
                        dispatch(setOrderDiscountDialog(obj));
                      }}
                    >
                      {getValues("discount") ? "Edit" : "Add"} Discount
                    </a>
                  </td>
                  <td>
                    {getValues("discount")
                      ? getValues("discount").reason
                        ? getValues("discount").reason
                        : "----"
                      : "----"}
                  </td>
                  <td align="right">{payment.discountPrice}</td>
                </tr>
                <tr>
                  <td>Sub Total</td>
                  <td></td>
                  <td align="right">{payment.subTotalWithDiscount}</td>
                </tr>
                <tr>
                  <td>
                    <a
                      className="a-text-color"
                      onClick={() => {
                        const data = {
                          props: { isOpen: true },
                          type: getValues("duty") ? "edit" : "new",
                          data: getValues("duty"),
                        };

                        dispatch(setDutyDialog(data));
                      }}
                    >
                      {getValues("duty") ? "Edit" : "Add"} Additional Charges
                    </a>
                  </td>
                  <td>
                    {getValues("duty")
                      ? getValues("duty").reason
                        ? getValues("duty").reason
                        : "----"
                      : "----"}
                  </td>
                  <td align="right">{payment.dutyPrice}</td>
                </tr>
                <tr>
                  <td>
                    <a
                      className="a-text-color"
                      onClick={() => {
                        const obj = {
                          props: { isOpen: true },
                          type: getValues("shipping") ? "edit" : "new",
                          data: getValues("shipping"),
                        };
                        dispatch(setShippingDiscountDialog(obj));
                      }}
                    >
                      {getValues("shipping") ? "Edit" : "Add"} Shipment
                    </a>
                  </td>
                  <td>
                    {getValues("shipping")
                      ? getValues("shipping").rate_name
                        ? getValues("shipping").rate_name
                        : "----"
                      : "----"}
                  </td>
                  <td align="right">{payment.shippingPrice}</td>
                </tr>
                {/* <tr>
                  <td colSpan="2"> Tax Total</td>
                  <td align="right">{payment.taxPrice}</td>
                </tr> */}
                <tr>
                  <td>Total</td>
                  <td colSpan="2" align="right">
                    {payment.total}
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default SaleOrderPayment;
