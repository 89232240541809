import React, { Fragment } from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { EmailAddress, Phone } from "../../../../constant";

const ExternalStaffForm = (props) => {
  const { errors, register } = props;

  return (
    <Fragment>
      <Row>
        <Col>
          <FormGroup>
            <Label className="col-form-label">Name</Label>
            <input
              name="name"
              type="text"
              {...register("name")}
              className={`form-control ${errors.name ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.name?.message}</div>
          </FormGroup>

          <FormGroup>
            <Label className="col-form-label">{EmailAddress}</Label>
            <input
              name="email"
              type="email"
              {...register("email")}
              className={`form-control ${errors.email ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.email?.message}</div>
          </FormGroup>

          <FormGroup>
            <Label className="col-form-label">{Phone}</Label>
            <input
              name="phone"
              type="tel"
              {...register("phone")}
              className={`form-control ${errors.phone ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.phone?.message}</div>
          </FormGroup>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ExternalStaffForm;
