import React, { Fragment, useState, useRef, useEffect } from 'react';
import {Col, FormGroup, Input, Label, Media, Row} from "reactstrap";
import Select from 'react-select'
import DatePicker from "react-datepicker";
import {
    checkTitle
} from "../../store/prodcutSlice";
import { useDispatch } from 'react-redux';
import { getCurrencyRate } from '../../store/lookBookSlice';
import PreLoader from '../../../../share-components/ui/preLoader';

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};
const lookBookForm = (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const ref = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dispatch = useDispatch();
    const { errors, control, register,setValue,trigger,roles,getValues } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [show, setShow] = useState(0);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isPreOrder, setIsPreOrder] = useState(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [file, setFile] = useState();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const inputFile = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [dispatchDate,setDispatchDate]=useState(new Date());
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // const defaultValuesLookBook = {
    //     template: {value: '1', label: 'Template 1'},
    // };
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // useEffect(() => {
    //     setValue('template',1);
        
    //   }, [props]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [title, setTitle] = useState('');
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [currencyCode, setCurrencyCode] = useState({value: 'BSD', label: 'BSD'});
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [rrpPriceValue, setRrpPriceValue] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [currencyRate, setCurrencyRate] = useState('');
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isTitle, setIsTitle] = useState('');
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [lookBookData, setLookBookData] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [selectOptionData, setSelectOptionData] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [selectedOptions, setMoreSelect] = useState(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [moreLimit, setMoreLimit] = useState(0);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [priceLimit, setPriceLimit] = useState(0);
     // eslint-disable-next-line react-hooks/rules-of-hooks
     const [isCompositionProduct, setIsCompositionProduct] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loading, isSetLoading] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        const data = {
			title,
            template:getValues('template')
		};
		// setLoading(true);
		dispatch(checkTitle({ ...data })).then(res => {
            // console.log(res);
			setIsTitle(res.payload?res.payload.isTitle:'');
            setLookBookData(res.payload.id);
		});
        
      }, [title]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    //   useEffect(() => {
    //     const data = {
	// 		price: 1,
    //         code: currencyCode?currencyCode.value:'GBP'
	// 	};
	// 	isSetLoading(true);
	// 	dispatch(getCurrencyRate({ ...data })).then(res => {
    //         // console.log(res);
    //         setCurrencyRate(res.payload);
    //         setValue('exchange_rate', res.payload);
    //         isSetLoading(false);
	// 	});
        
    //   }, [currencyCode]);
    
    const imageClick = (event)=>{
        event.preventDefault();
        inputFile.current.click();
    };
    const handleImageClick = (event)=>{
        setFile(URL.createObjectURL(event.target.files[0]));
        setValue('image',event.target.files[0]);        
    };
    const moreOptions = [
        {value: 'product_style', label: 'Style', isdisabled: ''},
        {value: 'season', label: 'Season', isdisabled: ''},
        {value: 'composition_product', label: 'Fabric and composition', isdisabled: ''},
        {value: 'countryofOrigin', label: 'Country of Origin', isdisabled: ''},
        // {value: 'fabrics', label: 'Fabrics'},
    ]
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const handleCurrency = (event)=>{
        // console.log(event);
        setValue('to_currency',event?event.value:'USD');
        setCurrencyCode(event);
        const data = {
			price: 1,
            code: event.value
		};
		isSetLoading(true);
		dispatch(getCurrencyRate({ ...data })).then(res => {
            // console.log(res);
            setCurrencyRate(res.payload);
            setValue('exchange_rate', res.payload);
            isSetLoading(false);
		});
    };
    const setMoreLimitFn = (value)=>{
        setMoreSelect(moreOptions);
        setRrpPriceValue([]);
        setSelectOptionData('');
        setValue('title','');
        setValue('rrp_price','');
        // setValue('to_currency','17');
        setCurrencyCode([]);
        // setValue('rrp_price','');
        // setValue('exchange_rate',1);
        setCurrencyRate(1);
        setValue('more_option','');
        setValue('sub_title','');
        setValue('heading','');
        setValue('email','');
        setValue('name','');
        if (value == 1) {
            setMoreLimit(2);
            setPriceLimit(3);
        }else if(value == 2){
            setMoreLimit(0);
            setPriceLimit(2);
            setMoreSelect([]);
        }else{
            setMoreLimit(1);
            setPriceLimit(3);
            const result = moreOptions.filter((item) => item.value !== 'product_style' && item.value !== 'composition_product');
            setMoreSelect(result);
        }
    };

    const setMoreOption = (e)=>{
        const result = e.find((item,i) => {
            return item.value === 'composition_product';
        });
        console.log(result);

        if(result){
            setIsCompositionProduct(true);
            const filter = e.filter((item) => item.value === 'composition_product');
            setSelectOptionData(filter);
            setValue('more_option',filter);
            
        }else{
            setSelectOptionData(e);
            setIsCompositionProduct(false);
            setValue('more_option',e);
        }
    }

    const templateOptions = [
        {value: '1', label: 'Template 1'},
        // {value: '2', label: 'Template 2'},
        // {value: '3', label: 'Template 3'},
    ]

    const priceOptions = [
        {value: 'rrp', label: 'RRP'},
        {value: 'price', label: 'Price'},
        {value: 'sales_price', label: 'Sales Price'},
        // {value: 'both', label: 'Both'},
    ]

    const currencyOptions = [
        {value: "AED", label: 'AED'},
        {value: "ARS", label: 'ARS'},
        {value: "AUD", label: 'AUD'},
        {value: "BGN", label: 'BGN'},
        {value: "BRL", label: 'BRL'},
        {value: "BSD", label: 'BSD'},
        {value: "CAD", label: 'CAD'},
        {value: "CHF", label: 'CHF'},
        {value: "CLP", label: 'CLP'},
        {value: "CNY", label: 'CNY'},
        {value: "COP", label: 'COP'},
        {value: "CZK", label: 'CZK'},
        {value: "DKK", label: 'DKK'},
        {value: "DOP", label: 'DOP'},
        {value: "EGP", label: 'EGP'},
        {value: "EUR", label: 'EUR'},
        {value: "FJD", label: 'FJD'},
        {value: "GBP", label: 'GBP'},
        {value: "GTQ", label: 'GTQ'},
        {value: "HKD", label: 'HKD'},
        {value: "HRK", label: 'HRK'},
        {value: "HUF", label: 'HUF'},
        {value: "IDR", label: 'IDR'},
        {value: "ILS", label: 'ILS'},
        {value: "INR", label: 'INR'},
        {value: "ISK", label: 'ISK'},
        {value: "JPY", label: 'JPY'},
        {value: "KRW", label: 'KRW'},
        {value: "KZT", label: 'KZT'},
        {value: "MXN", label: 'MXN'},
        {value: "MYR", label: 'MYR'},
        {value: "NOK", label: 'NOK'},
        {value: "NZD", label: 'NZD'},
        {value: "PAB", label: 'PAB'},
        {value: "PEN", label: 'PEN'},
        {value: "PHP", label: 'PHP'},
        {value: "PKR", label: 'PKR'},
        {value: "PLN", label: 'PLN'},
        {value: "PYG", label: 'PYG'},
        {value: "RON", label: 'RON'},
        {value: "RUB", label: 'RUB'},
        {value: "SAR", label: 'SAR'},
        {value: "SEK", label: 'SEK'},
        {value: "SGD", label: 'SGD'},
        {value: "THB", label: 'THB'},
        {value: "TRY", label: 'TRY'},
        {value: "TWD", label: 'TWD'},
        {value: "UAH", label: 'UAH'},
        {value: "USD", label: 'USD'},
        {value: "UYU", label: 'UYU'},
        {value: "VND", label: 'VND'},
        {value: "ZAR", label: 'ZAR'},
    ]
    return (
        <Fragment>
            <FormGroup>
                <Select
                    placeholder={<div>Select a Template</div>}
                    className="select2-filter border"
                    isClearable
                    cacheOptions
                    styles={customStyles}
                    options={templateOptions}
                    {...register("template")}
                    onChange={(e) => {
                        setValue('template',e?e.value:0);
                        setShow(e?e.value:0);
                        setMoreLimitFn(e?e.value:0);

                    }}

                />
            </FormGroup>
            {show != 0 && <div>
                <FormGroup>
                    <Label className="col-form-label">Title</Label>
                    {isTitle == 1 && <a href={`product/look-book/product-look-book/${lookBookData}`} style={{float:'right',paddingTop:'12px', fontSize:'12px',color:'#1886e7'}} target="_blank">Already exists Template</a>}
                    <input name="title" type="text" {...register('title')} onChange={event => setTitle(event.target.value)} className={`form-control`} required/>
                    {/* <div className="invalid-feedback">{errors.title?.message}</div> */}
                    {isTitle == 1 && <div className="invalid-feedback" style={{display: 'block'}}>Title Already exists for this Template. it will override the products for the same title.</div>}
                </FormGroup>

                <FormGroup>
                    <Label className="col-form-label">Price Value</Label>
                        {/* <Select 
                            styles={{ menuPortal: base => ({ ...base, zIndex: 100 }) }}
                            placeholder={<div>Select RRP/Price</div>}
                            {...register("rrp_price")}
                            onChange={(e) => {
                                setValue('rrp_price',e?e.value:'rrp');
                                setRrpPriceValue(e);
                            }}
                            value={rrpPriceValue}
                            className={` ${errors.rrp_price ? 'is-invalid' : ''}`}
                            options={priceOptions}
                            isClearable
                            isSearchable={false}  
                            required                         
                            /> */}
                            {/* <Select    getOptionLabel ={(option)=>option.label}
                                isMulti
                                getOptionValue ={(option)=>option.value} {...register("rrp_price")}    onChange={(e)=>{
                                    setValue('rrp_price',e);
                                    setRrpPriceValue(e);
                                    trigger('rrp_price');
                            }} className={` ${errors.rrp_price ? 'is-invalid' : ''}`} options={priceOptions} /> */}
                            {rrpPriceValue.length == priceLimit && <div className="invalid-feedback" style={{position: 'absolute', marginTop: '-28px', marginLeft: '100px', fontSize:'13px',color:'#1886e7',display: 'block'}}>Limit reached!</div>}

                                <Select 
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 100 }) }}
                                    isMulti
                                    onChange={(e) => {
                                       
                                        setValue('rrp_price',e);
                                        setRrpPriceValue(e);
                                        trigger('rrp_price');
                                        console.log(priceLimit);
                                    }}
                                    value={rrpPriceValue}
                                    className={` ${errors.rrp_price ? 'is-invalid' : ''}`}
                                    options={priceOptions}
                                    isOptionDisabled={() => rrpPriceValue.length >= priceLimit}
                                    />

                        <div className="invalid-feedback">{errors.rrp_price?.message}</div>

                </FormGroup>
                <Label className="col-form-label">Currency</Label>
                <div className="form-row">
                    <Col md="3">
                        <FormGroup>
                            <br/>
                            
                            <Label className="col-form-label" style={{marginTop:'5px'}}><b>USD</b> </Label> 
                            <Label className="col-form-label" style={{marginLeft:'20px',marginTop:'5px'}}> to </Label> 
                        </FormGroup>
                        
                    </Col>
                    <Col md="5">
                        <FormGroup>
                            <br/>
                            <Select
                                placeholder={<div>Select Currency</div>}
                                className={`select2-filter border ${errors.to_currency ? 'is-invalid' : ''}`}
                                isClearable
                                cacheOptions
                                // defaultValue={currencyCode}
                                value={currencyCode}
                                styles={customStyles}
                                options={currencyOptions}
                                {...register("to_currency")}
                                onChange={(e)=>{handleCurrency(e);
                                    trigger('to_currency');
                                }}

                            />
                            <div className="invalid-feedback">{errors.to_currency?.message}</div>
                        </FormGroup>
                    </Col>
                    <Col md="4">
                       
                        {loading?<div className="loader-box" style={{height:"auto",justifyContent:"normal",marginTop:'20px', marginLeft:'10px'}}>
                                        <div className="loader-18"></div>
                                    </div>:
                                    <FormGroup>
                                    <br/>
                                    <input style={{height:'50px'}} name="exchange_rate" type="text"
                                        defaultValue={currencyRate} 
                                        // value={currencyRate?currencyRate:''}
                                        onChange={(event) => setValue('exchange_rate', event.target.value)}
                                    {...register('exchange_rate')} placeholder="Exchange Rate:"
                                    className={`form-control ${errors.exchange_rate ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.exchange_rate?.message}</div>
                                </FormGroup>
                        }
                    </Col>

                    {show != 2 && <Col md="12">
                        <FormGroup>
                        
                            <Label className="col-form-label">More Details </Label>
                            {selectOptionData.length == moreLimit && <div className="invalid-feedback" style={{position: 'absolute', marginTop: '-28px', marginLeft: '100px', fontSize:'13px',color:'#1886e7',display: 'block'}}>Limit reached!</div>}
                            <Select 
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 100 }) }}
                                    isMulti
                                    onChange={(e) => {
                                        // setValue('more_option',e);
                                        // console.log(e);
                                        // setSelectOptionData(e);
                                       
                                        setMoreOption(e);
                                    }}
                                    value={selectOptionData}
                                    className={` ${errors.more_option ? 'is-invalid' : ''}`}
                                    options={selectedOptions}
                                    isOptionDisabled={() => selectOptionData.length >= moreLimit || isCompositionProduct}
                                    />

                            <div className="invalid-feedback">{errors.more_option?.message}</div>
                        </FormGroup>
                    </Col>}
                    
                </div>
            </div>}

            {show != 1 && show != 0 && <div>
                <div className="form-row">
                    <Col md="6">
                        <FormGroup>
                            <Label className="col-form-label">Image 
                            {show == 2 &&<span data-toggle="tooltip" style={{marginLeft:'5px'}} data-placement="top" title="Image Resolution: 740x400px / Format:jpeg,png,gif / Size: Max 2MB." className="text-black-50"><i className="fa fa-exclamation-circle"></i></span>}
                            {show == 3 &&<span data-toggle="tooltip" style={{marginLeft:'5px'}} data-placement="top" title="Image Resolution: 550x800px / Format:jpeg,png,gif / Size: Max 2MB." className="text-black-50"><i className="fa fa-exclamation-circle"></i></span>}
                            </Label>                         
                            <div className="col-auto">
                                <Media onClick={imageClick} style={{width:'120px'}} className="" alt="user-image" src={file?file:require("../../../../assets/images/default-medium-product.jpeg")}   />
                                <input type='file' id='file' ref={inputFile} onChange={handleImageClick} style={{display: 'none'}}/>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label className="col-form-label">Sub Title</Label>
                            <input name="sub_title" type="text" {...register('sub_title')} className={`form-control ${errors.sub_title ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.sub_title?.message}</div>
                        </FormGroup>
                        <FormGroup>
                            <Label className="col-form-label">Heading</Label>
                            <input name="heading" type="text" {...register('heading')} className={`form-control ${errors.heading ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.heading?.message}</div>
                        </FormGroup>
                    </Col>
                </div>
                <div className="form-row">
                    <Col md="6">
                        <FormGroup>
                            <Label className="col-form-label">Contact Name</Label>
                            <input name="name" type="text" {...register('name')} className={`form-control ${errors.name ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.name?.message}</div>
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label className="col-form-label">Contact Email</Label>
                            <input name="email" type="text" {...register('email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.email?.message}</div>
                        </FormGroup>
                    </Col>
                </div>
                
            </div>}           
        </Fragment>
    );
}

export default lookBookForm;