import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  NavItem,
  NavLink,
  Nav,
  Badge,
} from "reactstrap";
import Select from "react-select";
import { useDispatch } from "react-redux";
import {
  getShoppingSetting,
  setSettings,
  setProductFilter,
} from "../store/shoppingSlice";
import { Target } from "react-feather";
import ScrollArea from "react-scrollbar";
import {
  getFilterValues,
  moreFilterFields,
} from "../../manage-product/product/edit/content/settings/map";

const customStyles = {
  control: (base) => ({
    ...base,
    minHeight: 50,
    border: "none",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 100 }),
};
const ShoppingProductFilter = (props) => {
  const dispatch = useDispatch();
  const [timer, setTimer] = useState(null);
  const { shoppingState } = props;
  const filterState = shoppingState.filter;
  const shoppingSettings = shoppingState.shoppingIndexSettings;

  useEffect(() => {
    getSettings(0);
  }, [dispatch]);
  const getSettings = (type) => {
    dispatch(getShoppingSetting({ type: type })).then((res) => {
      const payload = res.payload;
      if (!res.error) {
        dispatch(setSettings({ ...shoppingState.settings, ...payload }));
      }
    });
  };

  document.addEventListener("mousedown", (event) => {
    const concernedElement = document.querySelector(".customizer-contain");
    if (concernedElement) {
      if (!concernedElement.contains(event.target)) {
        document.querySelector(".customizer-contain").classList.remove("open");
      }
    }
  });

  const clearFilter = () => {
    let productField = { ...shoppingState.filter };
    moreFilterFields().map(function (item, key) {
      productField[item.key] = "";
    });
    dispatch(setProductFilter(productField));
  };

  return (
    <div className="product-wrapper">
      <div className="product-grid">
        <div className="feature-products">
          {shoppingState.settings.types && (
            <Row>
              <Col md="8" className="project-list">
                <Nav tabs className="border-tab">
                  <NavItem>
                    <NavLink
                      className={
                        shoppingState.filter.type === "" ? "active" : ""
                      }
                      onClick={() => {
                        dispatch(
                          setProductFilter({
                            ...shoppingState.filter,
                            type: "",
                            currentPage: 0,
                          })
                        );
                      }}
                    >
                      <Target /> ALL
                    </NavLink>
                  </NavItem>
                  {shoppingState.settings.types.map((item, i) => (
                    <NavItem key={i}>
                      <NavLink
                        className={
                          shoppingState.filter.type === item.id ? "active" : ""
                        }
                        onClick={() => {
                          dispatch(
                            setProductFilter({
                              ...shoppingState.filter,
                              type: item,
                              currentPage: 0,
                            })
                          );
                        }}
                      >
                        <Target /> {item.name}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              </Col>
            </Row>
          )}
          <Row className="mt-3">
            <Col md={3}>
              <Select
                getOptionLabel={(option) => option.name}
                className="select2-filter"
                menuPortalTarget={document.body}
                styles={{
                  ...customStyles,
                  menuPortal: (base) => ({ ...base, zIndex: 100 }),
                }}
                value={shoppingState.filter.brand}
                getOptionValue={(option) => option.id}
                onChange={(e) => {
                  dispatch(
                    setProductFilter({
                      ...shoppingState.filter,
                      brand: e,
                      currentPage: 0,
                    })
                  );
                }}
                isClearable={true}
                placeholder="Select Brand"
                // styles={customStyles}
                components={{
                  IndicatorSeparator: () => null,
                }}
                options={shoppingState.settings.brands}
              />
            </Col>
            <Col md="3">
              {shoppingState.settings && (
                <Select
                  getOptionLabel={(option) => option.name}
                  className="select2-filter"
                  menuPortalTarget={document.body}
                  styles={{
                    ...customStyles,
                    menuPortal: (base) => ({ ...base, zIndex: 100 }),
                  }}
                  value={shoppingState.filter.category}
                  getOptionValue={(option) => option.id}
                  onChange={(e) => {
                    dispatch(
                      setProductFilter({
                        ...shoppingState.filter,
                        category: e,
                        subCategory: "",
                        styles: "",
                        currentPage: 0,
                      })
                    );
                  }}
                  isClearable={true}
                  placeholder="Select Categories"
                  // styles={customStyles}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  options={shoppingState.settings.categories.filter(function (
                    value,
                    index
                  ) {
                    return value.parent_id == 0;
                  })}
                />
              )}
            </Col>
            <Col md={4}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <FormGroup className="m-0">
                  <Input
                    className="form-control"
                    type="text"
                    defaultValue={shoppingState.filter.name}
                    placeholder="Search Product Name / Style"
                    onChange={(e) => {
                      clearTimeout(timer);

                      const newTimer = setTimeout(() => {
                        dispatch(
                          setProductFilter({
                            ...shoppingState.filter,
                            name: e.target.value,
                            currentPage: 0,
                          })
                        );
                      }, 500);

                      setTimer(newTimer);
                    }}
                  />
                  <i className="fa fa-search"></i>
                </FormGroup>
              </Form>
            </Col>
            <Col md={2}>
              <Button
                style={{ height: 50 }}
                color="white"
                type="button"
                onClick={() => {
                  document
                    .querySelector(".customizer-contain")
                    .classList.add("open");
                  if (!shoppingState.settings.countries) {
                    getSettings(2);
                  }
                }}
                className="btn btn-outline-dark btn-block"
              >
                Filter
              </Button>
            </Col>
          </Row>
          <Row>
            <div className="customizer-contain">
              <div className="customizer-header">
                <a
                  onClick={() => {
                    document
                      .querySelector(".customizer-contain")
                      .classList.remove("open");
                  }}
                >
                  <i className="icon-close"></i>
                </a>
                <h5>
                  {"Filters      "}{" "}
                  <a className="a-text-color ml-2">
                    {" "}
                    <i
                      className="fa fa-refresh"
                      title="clear filters"
                      onClick={() => {
                        clearFilter();
                      }}
                    ></i>
                  </a>
                </h5>
                <>
                  <div style={{ height: "1000" }}>
                    <ScrollArea horizontal={false} vertical={true}>
                      <Row>
                        <Col md="12">
                          <Nav tabs className="border-tab">
                            <NavItem>
                              <NavLink
                                className={
                                  shoppingState.filter.variant_type === ""
                                    ? "active"
                                    : ""
                                }
                                onClick={() => {
                                  dispatch(
                                    setProductFilter({
                                      ...shoppingState.filter,
                                      variant_type: "",
                                    })
                                  );
                                  clearFilter();
                                }}
                              >
                                {" "}
                                ALL
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={
                                  shoppingState.filter.variant_type?.id === 1
                                    ? "active"
                                    : ""
                                }
                                onClick={() => {
                                  dispatch(
                                    setProductFilter({
                                      ...shoppingState.filter,
                                      variant_type: { id: 1, name: "Pack" },
                                    })
                                  );
                                  clearFilter();
                                }}
                              >
                                {" "}
                                Pack{" "}
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={
                                  shoppingState.filter.variant_type?.id === 2
                                    ? "active"
                                    : ""
                                }
                                onClick={() => {
                                  dispatch(
                                    setProductFilter({
                                      ...shoppingState.filter,
                                      variant_type: { id: 2, name: "Single" },
                                    })
                                  );
                                  clearFilter();
                                }}
                              >
                                {" "}
                                Single{" "}
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </Col>
                      </Row>

                      {shoppingState.filter.category && (
                        <Row className="mt-3">
                          <Col md="12">
                            <Select
                              getOptionLabel={(option) => option.name}
                              className="select2-filter"
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 100,
                                }),
                              }}
                              value={shoppingState.filter.subCategory}
                              getOptionValue={(option) => option.id}
                              onChange={(e) => {
                                dispatch(
                                  setProductFilter({
                                    ...shoppingState.filter,
                                    subCategory: e,
                                    styles: "",
                                    currentPage: 0,
                                  })
                                );
                              }}
                              isClearable={true}
                              placeholder="Select Sub Categories"
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                              options={shoppingState.settings.categories.filter(
                                function (value, index) {
                                  return (
                                    value.parent_id ==
                                    shoppingState.filter.category?.id
                                  );
                                }
                              )}
                            />
                          </Col>
                        </Row>
                      )}
                      <Row className="mt-3">
                        <Col md="12">
                          <Select
                            getOptionLabel={(option) => option.name}
                            className="select2-filter"
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 100 }),
                            }}
                            value={shoppingState.filter.type}
                            getOptionValue={(option) => option.id}
                            onChange={(e) => {
                              dispatch(
                                setProductFilter({
                                  ...shoppingState.filter,
                                  type: e,
                                  currentPage: 0,
                                })
                              );
                            }}
                            isClearable={true}
                            placeholder="Select Type"
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            options={shoppingState.settings.types}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col md="12">
                          <Select
                            getOptionLabel={(option) => option.name}
                            className="select2-filter"
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 100 }),
                            }}
                            value={shoppingState.filter.tag_type}
                            getOptionValue={(option) => option.id}
                            onChange={(e) => {
                              dispatch(
                                setProductFilter({
                                  ...shoppingState.filter,
                                  tag_type: e,
                                  currentPage: 0,
                                })
                              );
                            }}
                            isClearable={true}
                            placeholder="Select Tag Type"
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            options={shoppingState.settings.tag_settings}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col md="12">
                          <Select
                            getOptionLabel={(option) => option.name}
                            isMulti={true}
                            className="select2-filter"
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 100 }),
                            }}
                            value={shoppingState.filter.tags}
                            getOptionValue={(option) => option.id}
                            onChange={(e) => {
                              dispatch(
                                setProductFilter({
                                  ...shoppingState.filter,
                                  tags: e,
                                  currentPage: 0,
                                })
                              );
                            }}
                            isClearable={true}
                            isOptionDisabled={() =>
                              shoppingState.filter.tags &&
                              shoppingState.filter.tags.length > 4
                            }
                            placeholder="Select Tags"
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            options={shoppingState.settings.tags}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col md="12">
                          <Select
                            getOptionLabel={(option) => option.name}
                            className="select2-filter"
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 100 }),
                            }}
                            value={shoppingState.filter.season}
                            getOptionValue={(option) => option.id}
                            onChange={(e) => {
                              dispatch(
                                setProductFilter({
                                  ...shoppingState.filter,
                                  season: e,
                                  currentPage: 0,
                                })
                              );
                            }}
                            isClearable={true}
                            placeholder="Select Season"
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            options={shoppingState.settings.seasons}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} className="mt-3">
                          <Select
                            getOptionLabel={(option) => option.name}
                            className="select2-filter"
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 100 }),
                            }}
                            value={shoppingState.filter.country}
                            getOptionValue={(option) => option.id}
                            onChange={(e) => {
                              dispatch(
                                setProductFilter({
                                  ...shoppingState.filter,
                                  country: e,
                                  currentPage: 0,
                                })
                              );
                            }}
                            isClearable={true}
                            placeholder="Select Country"
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            options={shoppingState.settings.countries}
                          />
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        <Col md="12">
                          <Select
                            getOptionLabel={(option) => option.name}
                            isMulti={true}
                            className="select2-filter"
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 100 }),
                            }}
                            value={shoppingState.filter.compositions}
                            getOptionValue={(option) => option.id}
                            onChange={(e) => {
                              dispatch(
                                setProductFilter({
                                  ...shoppingState.filter,
                                  compositions: e,
                                  currentPage: 0,
                                })
                              );
                            }}
                            isClearable={true}
                            isOptionDisabled={() =>
                              shoppingState.filter.compositions &&
                              shoppingState.filter.compositions.length > 4
                            }
                            placeholder="Select Compositions"
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            options={shoppingState.settings.compositions}
                          />
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        <Col md="12">
                          <Select
                            getOptionLabel={(option) => option.name}
                            className="select2-filter"
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 100 }),
                            }}
                            value={shoppingState.filter.commodity}
                            getOptionValue={(option) => option.id}
                            onChange={(e) => {
                              dispatch(
                                setProductFilter({
                                  ...shoppingState.filter,
                                  commodity: e,
                                  currentPage: 0,
                                })
                              );
                            }}
                            isClearable={true}
                            placeholder="Select Commodity"
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            options={shoppingState.settings.commodities}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col md="12">
                          <Select
                            getOptionLabel={(option) => option.name}
                            className="select2-filter"
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 100 }),
                            }}
                            value={shoppingState.filter.colour}
                            getOptionValue={(option) => option.id}
                            onChange={(e) => {
                              dispatch(
                                setProductFilter({
                                  ...shoppingState.filter,
                                  colour: e,
                                  currentPage: 0,
                                })
                              );
                            }}
                            isClearable={true}
                            placeholder="Select Colour"
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            options={shoppingState.settings.colours}
                          />
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        <Col md="12">
                          <Select
                            getOptionLabel={(option) => option.name}
                            className="select2-filter"
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 100 }),
                            }}
                            value={shoppingState.filter.product_group}
                            getOptionValue={(option) => option.id}
                            onChange={(e) => {
                              dispatch(
                                setProductFilter({
                                  ...shoppingState.filter,
                                  product_group: e,
                                  currentPage: 0,
                                })
                              );
                            }}
                            isClearable={true}
                            placeholder="Select Group Product"
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            options={shoppingState.settings.group_products}
                          />
                        </Col>
                      </Row>
                    </ScrollArea>
                  </div>
                </>
              </div>
            </div>
          </Row>
          <Row>
            <Col md={12}>
              {moreFilterFields().map(
                (item, i) =>
                  filterState[item.key] && (
                    <Badge key={i} color="light">
                      {item.name}{" "}
                      {getFilterValues(item.type, filterState[item.key])}
                      <a
                        className="btn-xs btn-default"
                        onClick={() => {
                          let obj = { ...shoppingState.filter };
                          obj[item.key] = "";
                          dispatch(setProductFilter(obj));
                          clearFilter();
                        }}
                      >
                        <i className="fa fa-close"></i>
                      </a>
                    </Badge>
                  )
              )}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default ShoppingProductFilter;
