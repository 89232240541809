import React, {Fragment, useEffect, useState} from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import {Container, Row, Col, Card, CardHeader, CardBody, Table, Label, FormGroup, Form} from 'reactstrap';
import withReducer from "../../../store/withReducer";
import shoppingReducer from "../store";
import {useDispatch, useSelector} from "react-redux";
import {
    amendQuantity,
    checkoutCartITem,
    getCart, getCheckoutShoppingSetting,
    removeCartITem,
    setCartItemData, setCheckoutSettingsData,
    setProductViewDialog
} from "../store/shoppingSlice";
import InputPlus from "../../../share-components/ui/inputPlus";
import {setProductFilter} from "../../manage-product/store/prodcutSlice";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {Textarea} from "../../../constant";
import ButtonLoader from "../../../share-components/ui/buttonLoader";
import {useHistory} from "react-router-dom";
import {getCheckoutSettingsApi} from "../../../services/shoppingService";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment/moment";

const dataMenu = [
    {
        type: 1,
        text: 'shopping',
        link: '/shopping-product'
    },
    {
        type: 0,
        text: "checkout"
    }
];

const defaultValues = {
    id: '',
    name: '',
    email: '',
    company_name: '',
    edd: '',
    point_of_contact: ''
};


/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    name: yup.string().required('You must enter a name'),
    email: yup.string().required('You must enter an email'),
    company_name: yup.string().required('You must enter company name'),
   // edd: yup.string().required('You must enter Expected Delivery Date For Pre Book'),
  //  point_of_contact: yup.object().shape({id: yup.string(),}).nullable().required('You must select a  point of persons.'),
    // role: yup.object().required('You must select a role').typeError('You must select a role')
    // role: yup.object().required('You must select a role')
});
const ShoppingCheckout = (props) => {
    const dispatch = useDispatch();
    const cardId = localStorage.getItem('cart-id');
    const [timer, setTimer] = useState(null);
    const [buttonDisable, setButtonDisable] = useState(false);
    let history = useHistory();
    const shoppingState = useSelector(({shopping}) => shopping.shopping);
    const {handleSubmit, formState, reset, control, register, setValue, handleChange, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });
    let [cartKey,setCartKey]=useState(0);

    const {errors} = formState


    useEffect(() => {
        if (shoppingState.productViewDialogProps.props.isOpen) {
            dispatch(setProductViewDialog({props: {isOpen: false}, data: null}));
        }
        dispatch(getCheckoutShoppingSetting()).then(res => {
            dispatch(setCheckoutSettingsData(res.payload));
        })
        dispatch(getCart(cardId)).then(res => {
            setValue('id', cardId);
            dispatch(setCartItemData(res.payload.items));
        })
    }, [dispatch])
    const changeOrderQuantity = (quantity, cardItemId) => {
        clearTimeout(timer)

        const newTimer = setTimeout(() => {
            dispatch(amendQuantity({id: cardItemId, quantity: quantity}))
        }, 500)

        setTimer(newTimer)
    }
    const handelCartItem = (id) => {
        dispatch(removeCartITem({cart_item_id: id, id: cardId})).then(res => {
            setCartKey(cartKey+1);
            dispatch(setCartItemData(res.payload.items));
        })
    }
    const onSubmit = (data) => {
        setButtonDisable(true);
        data.point_of_contact = data.point_of_contact?.id;
        dispatch(checkoutCartITem(data)).then(res => {
            localStorage.removeItem('cart-id');
            reset(defaultValues);
            dispatch(setCartItemData([]));
            setButtonDisable(false);
            history.push('/shopping-product')
        });
    }
    console.log(errors);
    return (
        <Fragment>
            <Breadcrumb data={dataMenu} title="Checkout"/>
            <Container fluid={true}>
                {shoppingState.cartItems && <Row>
                    <Col sm="8">
                        <Card>
                            <CardBody>
                                <Row className="mb-3">
                                    <Col md={12}>
                                        <p style={{color: 'red'}}>*** Please note that this order is not confirmed until your sales person reach out for further discussion to finalize the order. The actual cost and delivery date wil be confirmed by your sales person once your order is finalized.</p>
                                    </Col>
                                </Row>
                                <div className="table-responsive w-100">
                                    <Table>
                                        <thead className="thead-light">
                                        <tr>

                                            <th style={{width: '50%'}}>Product</th>
                                            <th style={{width: '25%'}}>Order Qty</th>
                                            <th style={{width: '25%'}}></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {shoppingState.cartItems.map((item, i) => (<tr key={i+"-"+cartKey}>
                                            <td>
                                                <Row>
                                                    <Col sm={2}>
                                                        <img width="40" className="img-fluid" src={item.product.image}/>
                                                    </Col>
                                                    <Col sm={10}>
                                                        <div>{item.product.name}</div>
                                                        <div>{item.product.sku}</div>
                                                    </Col>
                                                </Row>
                                            </td>
                                            <td>
                                                <InputPlus
                                                    key={"input-" + i+"-"+cartKey}
                                                    cardId={item.id}
                                                    defaultValue={item.quantity}
                                                    changeOrderQuantity={changeOrderQuantity}
                                                />
                                            </td>
                                            <td><a onClick={() => {
                                                handelCartItem(item.id)
                                            }}><i style={{color: "red"}} className="fa fa-trash fa-2x"></i></a></td>
                                        </tr>))}


                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm={4}>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md={12}>
                                        <h6>Customer Details</h6>
                                    </Col>
                                </Row>
                                <Form
                                    className="needs-validation"
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <Row className="mt-3">
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label className="col-form-label">Name</Label>
                                                <input name="name" type="text" {...register('name')}
                                                       className={`form-control ${errors.name ? 'is-invalid' : ''}`}/>
                                                <div className="invalid-feedback">{errors.name?.message}</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="mt-1">
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label className="col-form-label">Company Name</Label>
                                                <input name="name" type="text" {...register('company_name')}
                                                       className={`form-control ${errors.company_name ? 'is-invalid' : ''}`}/>
                                                <div className="invalid-feedback">{errors.company_name?.message}</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="mt-1">
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label className="col-form-label">Email</Label>
                                                <input name="name" type="text" {...register('email')}
                                                       className={`form-control ${errors.email ? 'is-invalid' : ''}`}/>
                                                <div className="invalid-feedback">{errors.email?.message}</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="mt-1">
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label className="col-form-label">Expected Delivery Date For Pre
                                                    Book</Label>
                                                <DatePicker name="dispatchDate"
                                                            className={`form-control digits ${errors.edd ? 'is-invalid' : ''}`}
                                                            selected={getValues('edd')}
                                                            onChange={(date) => {
                                                                setValue('edd', date);
                                                                trigger('edd')
                                                            }}
                                                            minDate={moment().toDate()}
                                                            style={{minHeight: 'auto'}}
                                                            dateFormat="dd-MM-yyyy"/>
                                                <div style={{color:'red'}} className="invalid-feedack">{errors.edd?.message}</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="mt-1">
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label className="col-form-label">Point Of Contact</Label>
                                                {shoppingState.checkoutSettings &&
                                                    <Select getOptionLabel={(option) => option.name}

                                                            getOptionValue={(option) => option.id}
                                                            onChange={(e) => {
                                                                setValue('point_of_contact', e);
                                                                trigger('point_of_contact');
                                                            }}
                                                            className={` ${errors.point_of_contact ? 'is-invalid' : ''}`}
                                                            options={shoppingState.checkoutSettings.sales_persons}/>}

                                                <div
                                                    className="invalid-feedback">{errors.point_of_contact?.message}</div>

                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="mt-1">
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label className="col-form-label">Comments</Label>
                                                <textarea rows={3}
                                                          className='form-control' {...register('comments')}></textarea>
                                                <div className="invalid-feedback">{errors.comments?.message}</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="mt-1">
                                        <Col md={12}>
                                            <ButtonLoader disabled={buttonDisable} color="primary" className='btn-block'
                                                          value="1"
                                                          type="submit"
                                                          btntext="Request Order"></ButtonLoader>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>}
            </Container>
        </Fragment>
    );
}

export default withReducer([{
    shopping: shoppingReducer
}])(ShoppingCheckout);
