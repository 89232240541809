import React, { Fragment, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { setOrderItemDiscountDialog } from "../../../store/draftOrderSlice";
import { ModalFooter } from "react-bootstrap";

const defaultValues = {
  discount_type: 1,
  value: "",
  reason: "",
};

const schema = yup.object().shape({
  discount_type: yup.string().required("You must select a type"),
  value: yup
    .number()
    .typeError("Must be a number")
    .required("This field is required")
    .min(1, "Should be greater than 0"),
});

const OrderItemDiscount = (props) => {
  const { orderSetValue, orderTrigger, orderGetValues, onChangeDialog } = props;
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState,
    reset,
    control,
    register,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const orderState = useSelector(
    ({ order }) => order.draftOrder.itemDiscountDialogProps
  );
  const { errors } = formState;

  const closeDialog = () => {
    dispatch(setOrderItemDiscountDialog({ props: { isOpen: false } }));
  };

  useEffect(() => {
    if (orderState.props.isOpen) {
      const discountData = orderState.data.item_discount;
      const data = {
        discount_type: defaultValues.discount_type,
        value: "",
      };
      if (discountData) {
        data.discount_type = discountData.discount_type;
        data.value = discountData.value;
      }

      reset(data);
    }
  }, [orderState.props.isOpen]);

  const onChildSubmit = (data) => {
    const itemDiscount = {
      discount_type: data.discount_type,
      value: data.value,
      reason: data.reason,
    };

    orderSetValue("item_discount", itemDiscount);
    orderTrigger("item_discount");

    const orderProducts = orderGetValues("ordered_products");
    const orderIndex = orderProducts.findIndex(
      (x) => x.variant === orderState.data.variant_id
    );
    const orderData = orderProducts[orderIndex];

    let discountedValue = parseFloat(data.value);
    if (data.discount_type === "2") {
      discountedValue = (parseFloat(data.value) / 100) * orderData.total_price;
    }

    orderProducts[orderIndex] = {
      ...orderData,
      item_discount: itemDiscount,
      discount: discountedValue,
      gross_amount: orderData.total_price - discountedValue,
    };

    orderSetValue("ordered_products", orderProducts);
    orderTrigger("ordered_products");
    onChangeDialog(true);
    closeDialog();
  };

  const handleRemoveDiscount = () => {
    orderSetValue("item_discount", null);
    orderTrigger("item_discount");

    const orderProducts = orderGetValues("ordered_products");
    const orderIndex = orderProducts.findIndex(
      (x) => x.variant === orderState.data.variant_id
    );
    const orderData = orderProducts[orderIndex];

    orderProducts[orderIndex] = {
      ...orderData,
      item_discount: null,
      discount: 0,
      gross_amount: orderData.gross_amount + orderData.discount,
    };

    orderSetValue("ordered_products", orderProducts);
    orderTrigger("ordered_products");

    onChangeDialog(true);
    closeDialog();
  };

  return (
    <Fragment>
      <div className="form-row mb-3">
        <Modal size="md" {...orderState.props} toggle={closeDialog}>
          <ModalHeader toggle={closeDialog}>{orderState.data?.sku}</ModalHeader>
          <Form noValidate onSubmit={handleSubmit(onChildSubmit)}>
            <ModalBody>
              {orderState.props.isOpen && (
                <>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label className="col-form-label">Discount Type</Label>
                        <Input
                          type="select"
                          defaultValue={getValues("discount_type")}
                          onChange={(e) => {
                            setValue("discount_type", e.target.value);
                          }}
                          className={`form-control ${
                            errors.discount_type ? "is-invalid" : ""
                          }`}
                        >
                          <option value="1">Amount</option>
                          <option value="2">Percentage</option>
                        </Input>
                        <div className="invalid-feedback">
                          {errors.discount_type?.message}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label className="col-form-label">Value</Label>
                        <input
                          name="value"
                          type="text"
                          {...register("value")}
                          className={`form-control ${
                            errors.value ? "is-invalid" : ""
                          }`}
                        />
                        <div className="invalid-feedback">
                          {errors.value?.message}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  {orderState.data?.item_discount && (
                    <Row>
                      <Col md={12}>
                        <button
                          type="button"
                          className="btn btn-danger btn-sm float-right"
                          onClick={handleRemoveDiscount}
                        >
                          Remove <i className="fa fa-trash"></i>
                        </button>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </ModalBody>
            <ModalFooter>
              {orderState.props.isOpen && (
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={handleSubmit(onChildSubmit)}
                >
                  Save
                </button>
              )}
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    </Fragment>
  );
};

function areEqual(prevProps, nextProps) {
  return (
    JSON.stringify(prevProps.getValues) == JSON.stringify(nextProps.getValues)
  );
}

export default OrderItemDiscount;
