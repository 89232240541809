import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Popover,
  PopoverBody,
  Row,
} from "reactstrap";
import { useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import DatePicker from "../../../share-components/ui/DateRangePicker";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import {
  salesOrderResponseToTable,
  mapSearchCustomerAutoSuggestion,
} from "../../../utils/mapper";
import { customerAutoSuggestionApi } from "../../../services/order";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "../../../store/withReducer";
import Slider from "react-slick";

import salesOrderReducer from "../store";
import {
  getSalesOrders,
  selectSalesOrder,
  setSettings,
  getSalesOrderFilters,
  downloadSalesOrderPickingTicketPdf,
  setSalesOrderPendingPickingDialogProps,
  setSalesOrderPendingPackingDialogProps,
  getSalesOrderStatuses,
} from "../store/salesOrderSlice";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import PreLoader from "../../../share-components/ui/preLoader";
import { DateRangePicker } from "rsuite";
import { NormalCarouseNavigation } from "../../../utils/common";
import { setSalesFulfillmentDialog } from "../store/salesOrderStatusSlice";
import PendingPickingModal from "./content/pendingPickingModal";
import PendingPackingModal from "./content/pendingPackingModal";
import SalesFulfillmentModal from "./status/content/salesFulfillmentModal";

const { afterToday } = DateRangePicker;

const PopoverBasicItem = (props) => {
  const { id, btntext, Popoverbody } = props;
  const [popover, setPopover] = useState(false);
  const Toggle = () => setPopover(!popover);
  return (
    <>
      <p
        style={{ cursor: "pointer" }}
        className="example-popover"
        id={"Popover-" + id}
      >
        {btntext} <i className="fa fa-info-circle"></i>
      </p>
      <Popover
        placement="top"
        isOpen={popover}
        target={"Popover-" + id}
        toggle={Toggle}
        trigger="hover"
      >
        <PopoverBody>{Popoverbody}</PopoverBody>
      </Popover>
    </>
  );
};

const customStyles = {
  control: (base) => ({
    ...base,
    minHeight: 50,
    border: "none",
  }),
};

const statusSettings = {
  dots: false,
  infinite: true,
  slidesToShow: 6,
  slidesToScroll: 1,
  nextArrow: <NormalCarouseNavigation icon="fa-chevron-circle-right" />,
  prevArrow: <NormalCarouseNavigation icon="fa-chevron-circle-left" />,
  variableWidth: true,
};

const defaultValues = {
  id: "",
};

const SalesOrder = (props) => {
  const [timer, setTimer] = useState(null);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [brand, setBrand] = useState(null);
  const [type, setType] = useState(null);
  const [status, setStatus] = useState(null);
  const [statusList, setStatusList] = useState([]);
  const [sku, setSku] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [search, setSearch] = useState(null);
  const [loading, isSetLoading] = useState(true);
  const salesOrders = useSelector(selectSalesOrder);
  let history = useHistory();
  const sellerState = useSelector(({ seller }) => seller.seller);
  const salesOrderState = useSelector(({ order }) => order.salesOrder);
  const userState = useSelector(({ authReducer }) => authReducer.data);
  const currentUser = useSelector(({ authReducer }) => authReducer.data.user);
  const TodayDate = new Date();
  const sevenDays = new Date().setDate(TodayDate.getDate() - 90);
  const [dateRange, setDateRange] = useState([sevenDays, TodayDate]);
  const [startDate, endDate] = dateRange;
  const authData = useSelector(({ authReducer }) => authReducer);
  const saleOrderState = useSelector(({ order }) => order.salesOrder);
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [activeStatus, setActiveStatus] = useState(-1);
  const { setValue, trigger, getValues } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const toggleDropdown = (rowId) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId],
    }));
  };

  const dataMenu = [
    {
      type: 0,
      text: "Sales Order",
    },
  ];

  const tableColumns = [
    {
      name: "Order No.",
      selector: (row) => row.order_no,
      sortable: true,
      center: false,
    },
    // {
    //   name: "Shopify Order ID",
    //   selector: (row) =>
    //     row.shopify_order_no ? row.shopify_order_no : "-----",
    //   sortable: true,
    //   center: false,
    // },
    {
      name: "Customer",
      selector: (row) => row.customer,
      sortable: true,
      center: false,
    },
    {
      name: "Order Type",
      selector: (row) => row.type,
      sortable: true,
      center: false,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      center: false,
    },
    {
      name: "Country",
      selector: (row) => row.country,
      sortable: true,
      center: false,
    },
    {
      name: "Amount",
      selector: (row) => <>{row.total}</>,
      sortable: true,
      center: false,
    },
    {
      name: "Payment Method",
      selector: (row) => row.payment_method,
      sortable: true,
      center: false,
    },
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
      center: false,
    },
    // closedReasonColumn && {
    //   name: "Closed Reason",
    //   selector: (row) => row.closed_reason,
    //   sortable: true,
    //   center: false,
    // },
    {
      name: "Action",
      width: "15%",
      selector: (row) => (
        <div>
          {userState.user &&
            (userState.user.permission.indexOf("View Seller") !== -1 ? (
              <Link
                to={`${process.env.PUBLIC_URL}/order/sale/${row.id}/view`}
                className="mr-1"
              >
                <i
                  className="fa fa-eye"
                  style={{
                    fontSize: 16,
                    color: "rgb(51, 153, 255)",
                  }}
                ></i>
              </Link>
            ) : (
              ""
            ))}
          {!row.is_closed && (
            <>
              {!row.is_online && (
                <Link
                  to={`${process.env.PUBLIC_URL}/order/sale/${row.id}/edit`}
                  className="mr-1"
                >
                  <i
                    style={{
                      fontSize: 16,
                      color: "rgb(40, 167, 69)",
                    }}
                    className="icon-pencil"
                  ></i>
                </Link>
              )}
              {row.status_id !== 0 ? (
                <>
                  {userState.user &&
                    (userState.user.permission.indexOf("Update Seller") !==
                    -1 ? (
                      <Link
                        to={`${process.env.PUBLIC_URL}/order/sale/${row.id}/billing/invoice`}
                      >
                        <i
                          className="fa fa-file-text-o"
                          style={{
                            fontSize: 16,
                            color: "rgb(255, 187, 51)",
                          }}
                        ></i>
                      </Link>
                    ) : (
                      ""
                    ))}
                </>
              ) : (
                ""
              )}
            </>
          )}
          {row.status_id == 0 ? (
            ""
          ) : (
            <button
              onClick={() => downloadPickingTicket(row)}
              className="mr-1"
              style={{ background: "none" }}
              title="Download Picking Ticket"
            >
              <i
                className="fa fa-ticket"
                style={{
                  fontSize: 16,
                  color: "rgb(0, 230, 77)",
                }}
              ></i>
            </button>
          )}
          {row.status_id == 2 ? (
            <>
              <button
                onClick={() => uploadPickingTicket(row)}
                className="mr-1"
                style={{ background: "none" }}
                title="Upload Picking Ticket"
              >
                <i
                  className="fa fa-upload"
                  style={{
                    fontSize: 16,
                    color: "rgb(196, 77, 255)",
                  }}
                ></i>
              </button>
            </>
          ) : (
            ""
          )}
          {row.status_id == 3 ? (
            <>
              <button
                onClick={() => viewPickingTicket(row)}
                className="mr-1"
                style={{ background: "none" }}
                title="View Picking Ticket"
              >
                <i
                  className="fa fa-file-text"
                  style={{
                    fontSize: 16,
                    color: "rgb(0, 230, 230)",
                  }}
                ></i>
              </button>
            </>
          ) : (
            ""
          )}
          {row.status_id == 4 ? (
            <>
              <button
                onClick={() => downloadPackingInvoice(row)}
                className="mr-1"
                style={{ background: "none" }}
                title="Downloading Invoice"
              >
                <i
                  className="fa fa-cloud-download"
                  style={{
                    fontSize: 16,
                    color: "rgb(102, 0, 255)",
                  }}
                ></i>
              </button>
              <button
                onClick={() => viewPickingTicket(row)}
                className="mr-1"
                style={{ background: "none" }}
                title="View Picking Ticket"
              >
                <i
                  className="fa fa-file-text"
                  style={{
                    fontSize: 16,
                    color: "rgb(0, 230, 230)",
                  }}
                ></i>
              </button>
            </>
          ) : (
            ""
          )}
          {row.status_id == 5 ? (
            <>
              <button
                onClick={() => createShippingFulfillment(row)}
                className="mr-1"
                style={{ background: "none" }}
                title="Create Fulfillment"
              >
                <i
                  className="fa fa-truck"
                  style={{
                    fontSize: 16,
                    color: "rgb(255, 204, 102)",
                  }}
                ></i>
              </button>
              <button
                onClick={() => viewPickingTicket(row)}
                className="mr-1"
                style={{ background: "none" }}
                title="View Picking Ticket"
              >
                <i
                  className="fa fa-file-text"
                  style={{
                    fontSize: 16,
                    color: "rgb(0, 230, 230)",
                  }}
                ></i>
              </button>
            </>
          ) : (
            ""
          )}
          {row.status_id == 6 ? (
            <>
              <button
                onClick={() => viewPickingTicket(row)}
                className="mr-1"
                style={{ background: "none" }}
                title="View Picking Ticket"
              >
                <i
                  className="fa fa-file-text"
                  style={{
                    fontSize: 16,
                    color: "rgb(0, 230, 230)",
                  }}
                ></i>
              </button>
            </>
          ) : (
            ""
          )}
        </div>
      ),
      sortable: false,
      center: true,
    },
  ];

  const allStatusClick = () => {
    handleStatusClick(1);
    setActiveStatus(-1);
  };

  const handleStatusClick = (selectedStatus) => {
    setStatus(selectedStatus.id);
    setActiveStatus(selectedStatus.id);
  };

  useEffect(() => {
    getData();
  }, [
    page,
    brand,
    search,
    dateRange,
    startDate,
    endDate,
    type,
    status,
    sku,
    customer,
  ]);

  const getData = () => {
    const data = {
      page: page,
      pageSize: 10,
      brand: brand,
      search: search,
      type: type,
      status: status,
      sku: sku,
      customer: customer,
      dateRange: dateRange,
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
    };
    dispatch(getSalesOrders(data)).then((res) => {
      // if (isClosed === "1") {
      //   setClosedReasonColumn(true);
      // } else {
      //   setClosedReasonColumn(false);
      // }
      if (!res.error) {
        isSetLoading(false);
      }
    });
  };

  useEffect(() => {
    dispatch(getSalesOrderFilters()).then((res) => {
      if (!res.error) {
        dispatch(setSettings(res.payload));
      }
    });
    dispatch(getSalesOrderStatuses()).then((res) => {
      if (!res.error) {
        setStatusList(res.payload);
      }
    });
  }, []);

  const onInvoiceEdit = (row) => {
    history.push("sale/" + row.id + "/billing/invoice");
  };
  const onEdit = (row) => {
    history.push("sale/" + row.id + "/edit");
  };
  const onView = (row) => {
    history.push("sale/" + row.id + "/view");
  };
  const onAdd = () => {
    history.push("sale/create");
  };

  const downloadPickingTicket = (row) => {
    processPDFDownload("Picking Ticket", row.id, row.order_no);
  };

  const uploadPickingTicket = (row) => {
    const data = {
      props: { isOpen: true },
      type: "edit",
      data: row,
      isInputShow: true,
    };

    dispatch(setSalesOrderPendingPickingDialogProps(data));
  };

  const viewPickingTicket = (row) => {
    const data = {
      props: { isOpen: true },
      type: "edit",
      data: row,
      isInputShow: false,
    };

    dispatch(setSalesOrderPendingPickingDialogProps(data));
  };

  const downloadPackingInvoice = (row) => {
    const data = {
      props: { isOpen: true },
      type: "edit",
      data: row,
    };

    dispatch(setSalesOrderPendingPackingDialogProps(data));
  };

  const createShippingFulfillment = (row) => {
    const data = {
      props: { isOpen: true },
      type: "edit",
      data: row,
    };

    dispatch(setSalesFulfillmentDialog(data));
  };

  const processPDFDownload = (name, id, orderNumber) => {
    const orderId = id;
    const orderNo = orderNumber;
    const data = {
      id: orderId,
      params: {
        name: name,
        seller: authData.defaultSeller?.value,
      },
    };
    dispatch(downloadSalesOrderPickingTicketPdf(data)).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${orderNo}_${name.replace(/\s+/g, "-")}_${new Date()
          .toJSON()
          .slice(0, 10)}.pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const typePromiseOption = (inputValue) =>
    new Promise((resolve) => {
      const data = {
        value: inputValue,
      };
      setTimeout(() => {
        resolve(
          mapSearchCustomerAutoSuggestion(customerAutoSuggestionApi(data))
        );
      });
    });

  const handleCustomerChange = (selectedOption) => {
    setCustomer(selectedOption ? selectedOption.value : null);
  };

  return (
    <Fragment>
      {loading ? (
        <>
          <PreLoader></PreLoader>
        </>
      ) : (
        <>
          <Breadcrumb title="Sales Order" data={dataMenu} />

          <Container fluid={true} className="product-wrapper">
            <div className="product-grid">
              <div className="feature-products">
                {currentUser.permission.includes("Create Seller") ? (
                  <Card>
                    {userState.user &&
                      (userState.user.permission.indexOf("Add Seller") !==
                      -1 ? (
                        <Row>
                          <Col md={3}>
                            <Form>
                              <FormGroup className="row pt-2">
                                <Col
                                  sm="12"
                                  style={{
                                    border: "1px solid #ddd",
                                    marginLeft: 42,
                                    borderRadius: 6,
                                  }}
                                >
                                  <DatePicker
                                    dateRange={dateRange}
                                    dateRangeChange={(update) => {
                                      setDateRange(update);
                                    }}
                                    disabledDate={afterToday()}
                                    defaultValue={[TodayDate, sevenDays]}
                                  />
                                </Col>
                              </FormGroup>
                            </Form>
                          </Col>
                          <Col md={9} className="pt-1">
                            <div className="float-sm-right p-3">
                              <a
                                onClick={onAdd}
                                className="btn btn-sm btn-primary"
                              >
                                {" "}
                                <i className="fa fa-plus"></i> Add Sales Order
                              </a>
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        ""
                      ))}
                  </Card>
                ) : (
                  ""
                )}

                <Row className="pb-2">
                  <Col md={12} className="slick-arrow">
                    {statusList && statusList && (
                      <Slider {...statusSettings}>
                        <div
                          className={
                            activeStatus == -1
                              ? "active-status p-1 mx-1"
                              : "p-1 mx-1"
                          }
                          style={{ width: "auto" }}
                        >
                          <div className="slider-item" data-index={0}>
                            <div>
                              <button
                                style={{ background: "none" }}
                                onClick={allStatusClick}
                              >
                                <span>
                                  <i
                                    className="fa fa-bars"
                                    style={{
                                      fontSize: 25,
                                      color: "rgb(102, 179, 255)",
                                    }}
                                  ></i>
                                  <span
                                    style={{
                                      padding: 10,
                                      fontSize: 18,
                                      marginLeft: 14,
                                    }}
                                  >
                                    All
                                  </span>
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                        {statusList.map((item, i) => (
                          <div
                            className={
                              activeStatus == item.id
                                ? "active-status p-1 mx-1"
                                : "p-1 mx-1"
                            }
                            key={i}
                            style={{ width: "auto" }}
                          >
                            <div>
                              <button
                                style={{ background: "none" }}
                                onClick={() => handleStatusClick(item)}
                              >
                                <span>
                                  <i
                                    className={item.icon}
                                    style={{
                                      fontSize: 25,
                                      color: item.color,
                                    }}
                                  ></i>
                                  <span
                                    style={{
                                      padding: 10,
                                      fontSize: 18,
                                      marginLeft: 14,
                                    }}
                                  >
                                    {item.name} ({item.count})
                                  </span>
                                </span>
                              </button>
                            </div>
                          </div>
                        ))}
                      </Slider>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col sm="3">
                    <Select
                      className="select2-filter border"
                      isClearable
                      cacheOptions
                      styles={customStyles}
                      placeholder="Select Type"
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(e) => {
                        setType(e ? e.id : null);
                        setPage(1);
                      }}
                      options={
                        salesOrderState.settings
                          ? salesOrderState.settings.types
                          : null
                      }
                    />
                  </Col>

                  {/* <Col sm="2">
                    <Select
                      className="select2-filter border"
                      isClearable
                      cacheOptions
                      styles={customStyles}
                      placeholder="Select Status"
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      onChange={(e) => {
                        setStatus(e ? e.id : null);
                        setPage(1);
                      }}
                      options={
                        salesOrderState.settings
                          ? salesOrderState.settings.statues
                          : null
                      }
                    />
                  </Col> */}

                  {/* <Col sm="2">
                    <Select
                      className="select2-filter border"
                      isClearable
                      cacheOptions
                      styles={customStyles}
                      placeholder="Select Customer"
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      onChange={(e) => {
                        setCustomer(e ? e.id : null);
                        setPage(1);
                      }}
                      options={
                        salesOrderState.settings
                          ? salesOrderState.settings.customers
                          : null
                      }
                    />
                  </Col> */}

                  <Col sm="3">
                    <AsyncSelect
                      isClearable
                      cacheOptions
                      defaultOptions
                      styles={customStyles}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      loadOptions={typePromiseOption}
                      onChange={handleCustomerChange}
                      placeholder="Select Customer"
                    />
                  </Col>

                  <Col sm="3">
                    <Form>
                      <FormGroup className="m-0">
                        <Input
                          type="text"
                          className="form-control"
                          onChange={(e) => {
                            clearTimeout(timer);

                            const newTimer = setTimeout(() => {
                              setSku(e.target.value);
                            }, 500);

                            setTimer(newTimer);
                          }}
                          placeholder="Search by SKU."
                        />
                        <i className="fa fa-search"></i>
                      </FormGroup>
                    </Form>
                  </Col>

                  <Col sm="3">
                    <Form>
                      <FormGroup className="m-0">
                        <Input
                          type="text"
                          className="form-control"
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                          placeholder="Search by Order/Shopify Order No."
                        />
                        <i className="fa fa-search"></i>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
              </div>
            </div>
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <div className="table-responsive product-table">
                      <CommonDataTable
                        headerColumns={tableColumns}
                        gridData={salesOrderResponseToTable(salesOrders)}
                        onEdit={
                          currentUser.permission.includes("Update Seller")
                            ? onEdit
                            : ""
                        }
                        onView={
                          currentUser.permission.includes("View Seller")
                            ? onView
                            : ""
                        }
                        noAction
                        paginationServer
                        pagination
                        paginationTotalRows={saleOrderState.noOfTableRows}
                        paginationComponentOptions={{
                          noRowsPerPage: true,
                        }}
                        paginationRowsPerPageOptions={[10, 25, 50, 100]}
                        paginationPerPage={10}
                        onChangePage={(page) => setPage(page)}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <PendingPickingModal
                orderSetValue={setValue}
                orderTrigger={trigger}
                orderGetValues={getValues}
              ></PendingPickingModal>

              <PendingPackingModal
                orderSetValue={setValue}
                orderTrigger={trigger}
                orderGetValues={getValues}
              ></PendingPackingModal>

              <SalesFulfillmentModal
                orderSetValue={setValue}
                orderTrigger={trigger}
                orderGetValues={getValues}
              ></SalesFulfillmentModal>
            </Row>
          </Container>
        </>
      )}
    </Fragment>
  );
};

export default withReducer([
  {
    salesOrders: salesOrderReducer,
  },
])(SalesOrder);
