import React, { Fragment, useEffect, useState } from "react";
import { getDefaultSeller } from "../../utils/common";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import Select from "react-select";
import { Clock } from "react-feather";
import moment from "moment";
import { CardGreeting } from "./content/cardGreeting";
import { GoogleAnalytic } from "./content/googleAnalytic";
import { CardChart } from "./content/cardChart";
import PreLoader from "../../share-components/ui/preLoader";
import { retrieveData } from "./store/dashboardSlice";
import { connect, useDispatch, useSelector } from "react-redux";
import withReducer from "../../store/withReducer";
import dashboardReducer from "./store";
import { mapSearchAutoSuggestion } from "../../utils/mapper";
import { sellerAutoSuggestionApi } from "../../services/manage-product/productService";
import AsyncSelect from "react-select/async";
import authReducer from "../../redux/index";
import { CardRecentOrders } from "./content/cardRecentOrders";
import { useHistory } from "react-router-dom";
import { CardRecentSellers } from "./content/cardRecentSellers";
import { CardTopSoldProducts } from "./content/cardTopSoldProducts";
import { CardOutOfStockProducts } from "./content/cardOutOfStockProducts";
import "./style/custom.css";
import ProductView from "../manage-product/product/content/productView";
import DatePicker from "../../share-components/ui/DateRangePicker";
import { DateRangePicker } from "rsuite";

const { afterToday } = DateRangePicker;

const customStyles = {
  control: (base) => ({
    ...base,
    minHeight: 50,
    border: "none",
  }),
};

const customDatePickerStyles = {
  control: (base) => ({
    ...base,
    minHeight: 100,
    border: "none",
  }),
};

const Dashboard = (props) => {
  const dataMenu = [
    {
      type: 0,
      text: "Dashboard",
    },
  ];

  const dispatch = useDispatch();
  let history = useHistory();
  const TodayDate = new Date();
  const sevenDays = new Date().setDate(TodayDate.getDate() - 7);
  const [loading, isSetLoading] = useState(true);
  const [minLoading, isSetMinLoading] = useState(false);
  const browserDom = React.createRef();

  const [dateRange, setDateRange] = useState([sevenDays, TodayDate]);
  const [startDate, endDate] = dateRange;
  const [seller, setSeller] = useState();

  const dashboardState = useSelector(({ dashboard }) => dashboard.dashboard);
  const userState = useSelector(({ authReducer }) => authReducer.data);
  const authData = useSelector(({ authReducer }) => authReducer);

  useEffect(() => {
    setSeller(authData.defaultSeller);
  }, [authData.defaultSeller]);

  useEffect(() => {
    queryData();
  }, [startDate, endDate, seller]);

  const queryData = () => {
    if (startDate !== null && endDate !== null) {
      isSetMinLoading(true);
      const data = {
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
        //    seller:seller ? seller.value : null,
        seller: seller && seller.value ? seller.value : "",
      };
      dispatch(retrieveData(data)).then((res) => {
        if (!res.error) {
          isSetLoading(false);
          isSetMinLoading(false);
        }
      });
    }
  };

  const sellerPromiseOption = (inputValue) =>
    new Promise((resolve) => {
      const data = {
        value: inputValue,
      };
      setTimeout(() => {
        resolve(mapSearchAutoSuggestion(sellerAutoSuggestionApi(data)));
      });
    });

  //   const defaultSeller ={ label: "Goddiva - Goddiva Ltd", value: "20" };
  //   useEffect(() => {
  //     setSeller(defaultSeller);
  //   }, []);

  return (
    <Fragment>
      {loading ? (
        <>
          <PreLoader></PreLoader>
        </>
      ) : (
        <>
          <Breadcrumb title="Dashboard" data={dataMenu} />
          <Container fluid={true} className="product-wrapper">
            <div className="product-grid">
              <div className="feature-products">
                <Row>
                  {userState.user.role.name !== "Seller" && (
                    <Col sm="12">
                      <AsyncSelect
                        className="select2-filter"
                        isClearable={true}
                        defaultOptions
                        styles={customStyles}
                        placeholder="Select Seller"
                        loadOptions={sellerPromiseOption}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        onClear={(e) => {}}
                        value={seller}
                        onChange={(e) => {
                          //  setSeller(e?e.value:null);
                          setSeller(e ? e : null);
                        }}
                      />
                    </Col>
                  )}
                </Row>
              </div>
            </div>

            {/* {userState.user.modules.includes("analytic") && (
              <GoogleAnalytic
                dashboardState={dashboardState}
                startDate={startDate}
                endDate={endDate}
              ></GoogleAnalytic>
            )} */}
            <Row className="mt-4">
              <CardGreeting
                dashboardState={dashboardState}
                userData={userState}
              />
              {(userState.user.role.name === "Super Admin" ||
                userState.user.role.name === "Admin") && (
                <CardChart
                  dashboardState={dashboardState}
                  startDate={startDate}
                  endDate={endDate}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  moment={moment}
                  minLoading={minLoading}
                />
              )}
            </Row>
            <Row>
              <CardTopSoldProducts
                seller={seller}
                dashboardState={dashboardState}
                history={history}
                startDate={startDate}
                endDate={endDate}
              />
              <CardOutOfStockProducts
                seller={seller}
                dashboardState={dashboardState}
                history={history}
              />
            </Row>
            <Row>
              <CardRecentOrders
                seller={seller}
                dashboardState={dashboardState}
                history={history}
              />
              {/* {userState.user.role.name !== "Seller" && (
                <CardRecentSellers
                  seller={seller}
                  dashboardState={dashboardState}
                  history={history}
                />
              )} */}
            </Row>
            <ProductView></ProductView>
          </Container>
        </>
      )}
    </Fragment>
  );
};

export default withReducer([{ dashboard: dashboardReducer }])(Dashboard);
