import React, {useState} from 'react';
import {Card, CardBody, Col, Row, Table} from "reactstrap";
import AsyncSelect from "react-select/async";



function AdjustmentAdd(props) {
    const {append,fields,getValues,errors,setValue,trigger,register,binTypePromiseOption,getPackCalculation,remove,isPackCalculation=true,binKey=0}=props;


    return (
        <Card>
            <CardBody>
                {/*<Row>*/}
                {/*    <Col md={12}>*/}
                {/*        <div className="float-right">*/}
                {/*            <a className='btn btn-primary rs-btn-xs ' onClick={() => {*/}
                {/*                append({bin: '', quantity: '',old_quantity:0});*/}
                {/*            }}><i*/}
                {/*                className="fa fa-plus "></i></a>*/}
                {/*        </div>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                <Row>

                    {fields.map((item, i) => (
                        <Col md={4} className="mt-3" key={i}>
                            <div style={{display: "inline-flex"}}>
                                {/*<div style={{width: '100%'}}>*/}
                                {/*    <AsyncSelect*/}
                                {/*        key={binKey}*/}
                                {/*        cacheOptions={false}*/}
                                {/*        defaultOptions*/}
                                {/*        placeholder="Bin"*/}
                                {/*        value={getValues(`inventories.${i}.bin`)}*/}
                                {/*        className={`select2-filter ${errors.inventories?.[i]?.bin ? 'is-invalid' : ''}`}*/}
                                {/*        components={{*/}
                                {/*            IndicatorSeparator: () => null*/}
                                {/*        }}*/}
                                {/*        loadOptions={binTypePromiseOption}*/}
                                {/*        onChange={(e) => {*/}
                                {/*            setValue(`inventories.${i}.bin`, e);*/}
                                {/*            trigger(`inventories.${i}.bin`);*/}
                                {/*            // trigger(`inventories`);*/}
                                {/*        }}*/}

                                {/*    />*/}
                                {/*    <div className="invalid-feedback"*/}
                                {/*    >{errors.inventories?.[i]?.bin?.message}</div>*/}
                                {/*</div>*/}
                                <div className='w-50'>
                                    <input
                                        {...register(`inventories.${i}.quantity`)}
                                        onChange={(e)=>{
                                            if(isPackCalculation) {
                                                setValue(`inventories.${i}.quantity`, e.target.value);
                                                getPackCalculation();
                                            }
                                        }}
                                        key={binKey}
                                        className={`form-control  ${errors.inventories?.[i]?.quantity ? 'is-invalid' : ''}`}
                                        placeholder='qty'/>
                                    <div className="invalid-feedback"
                                    >{errors.inventories?.[i]?.quantity?.message}</div>
                                </div>
                                {fields.length > 1 && <div><a onClick={() => {
                                    remove(i);
                                }} className='btn btn-danger btn-xs' style={{width: 30}}><i
                                    className="mt-2 fa fa fa-times mb-2" style={{fontSize: 15}}></i></a>
                                </div>}
                            </div>
                        </Col>
                    ))}
                </Row>

            </CardBody>
        </Card>
    );
}

export default AdjustmentAdd;