import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import {
  addShipperApi,
  deleteShipperApi,
  getShippersApi,
  updateShipperApi,
} from "../../../../services/manage-order/setting/shipperService";
import { toast } from "react-toastify";

export const getOrderShipperSettings = createAsyncThunk(
  "orderSettings/getOrderShipperSettingData",
  async (_, { dispatch }) => {
    const response = await getShippersApi();
    return response.response;
  }
);
export const storeShipper = createAsyncThunk(
  "orderSettings/storeShipperData",
  async (data, { dispatch }) => {
    const response = await addShipperApi(data);
    const res = response.response;
    dispatch(getOrderShipperSettings());
    toast.success("Added Shipper successfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
    return res;
  }
);

export const updateShipper = createAsyncThunk(
  "orderSettings/updateShipperData",
  async (shipper, { dispatch, getStatus }) => {
    try {
      const response = await updateShipperApi(shipper, shipper.id);
      const data = await response.response;
      dispatch(getOrderShipperSettings());
      toast.success("Shipper updated successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return data;
    } catch (e) {
      console.log("Error updating: " + e.message);
      return Promise.reject(e);
    }
  }
);

export const deleteShipper = createAsyncThunk(
  "orderSettings/deleteShipperData",
  async (id, { dispatch }) => {
    const response = await deleteShipperApi(id);
    const res = response.response;
    dispatch(getOrderShipperSettings());
    toast.success("Shipper Method successfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
    return res;
  }
);

const shipperSettingAdapter = createEntityAdapter();

const shipperSettingSlice = createSlice({
  name: "shipperSetting",
  initialState: shipperSettingAdapter.getInitialState({
    addDialog: {
      component: "shipperSetting",
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    openNewDialog: (state, action) => {
      state.addDialog = {
        component: "shipperSetting",
        type: "new",
        props: {
          isOpen: true,
        },
        data: null,
      };
    },
    openEditDialog: (state, action) => {
      state.addDialog = {
        type: "edit",
        props: {
          isOpen: true,
        },
        data: action.payload,
      };
    },
    closeEditDialog: (state, action) => {
      state.addDialog = {
        type: "edit",
        props: {
          isOpen: false,
        },
        data: null,
      };
    },
  },
  extraReducers: {
    [getOrderShipperSettings.fulfilled]: shipperSettingAdapter.setAll,
  },
});
export const { openNewDialog, openEditDialog, closeEditDialog } =
  shipperSettingSlice.actions;
export default shipperSettingSlice.reducer;
