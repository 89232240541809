import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, Form, FormGroup, Nav, Row} from "reactstrap";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import {mapColourSettingToTable, productSizeResponseToTable} from "../../../../utils/mapper";
import withReducer from "../../../../store/withReducer";
import productReducer from "../../store";
import {useDispatch, useSelector} from "react-redux";
import {
    addProductSize,
    closeEditDialog,
    deleteProductSize,
    getAllProductSizes,
    openEditDialog,
    openNewDialog,
    selectProductSize,
    updateProductSize
} from "../../store/productSizeSlice";
import ModalFormSubmit from "../../../../share-components/form/modalFormSubmit";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import ProductSizeForm from "./productSizeForm";
import PreLoader from "../../../../share-components/ui/preLoader";

const defaultValues = {
    id: '',
    name: '',
    // position: '',
    publish_at: '',
    netsuite_id: '',
};

const dataMenu = [
    {
        type: 0,
        text: 'Products',
        link: '/product'
    },
    {
        type: 1,
        text: 'Product Setting',
        link: ''
    },
    {
        type: 2,
        text: 'Product Size',
        link: ''
    }
];

const schema = yup.object().shape({
    name: yup.string().required('You must enter a product type name'),
    // netsuite_id: yup.string().required('You must enter a netsuite id'),
    // tageName: yup.string().required('You must enter a tag name'),
});

const ProductSize = () => {

    const dispatch = useDispatch();
    const [publishVal, setPublishVal] = useState([]);
    const [name, setName] = useState([]);
    const [position, setPosition] = useState([]);
    const [loading, setLoading] = useState(true);
    const productSize = useSelector(selectProductSize);
    const [buttonDisable, setButtonDisable] = useState(false);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);

    useEffect(() => {

        const data = {
            name,
            position
        };

        dispatch(getAllProductSizes({...data})).then(res => {
            if (!res.error)
            setLoading(false);
        });
    }, [dispatch, name, position]);

    const {handleSubmit, formState, reset, control, register, setValue, handleChange, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const {errors} = formState;

    const tableColumns = [
        {
            name: 'Size Name',
            selector: row => row.name,
            sortable: true,
            left: true,
        },
        // {
        //     name: 'Netsuite ID',
        //     selector: row => row.netsuite_id,
        //     sortable: true,
        //     left: true,
        // },
        // {
        //     name: 'Position',
        //     selector: row => row.position,
        //     sortable: true,
        //     left: true,
        // }
    ];

    const onSubmitForm = (dialog, data) => {
        setButtonDisable(true);
        if (dialog.type === "new") {
            dispatch(addProductSize({...data})).then(res => {
                setButtonDisable(false);
                if (!res.error) closeDialog(dialog.type)
            });
        } else {
            dispatch(updateProductSize({...data})).then(res => {
                setButtonDisable(false);
                if (!res.error) closeDialog(dialog.type)
            });
        }
    }

    const onEdit = (row) => {
        dispatch(openEditDialog(row));
    }

    const onDelete = (row) => {
        dispatch(deleteProductSize(row.id))
    }

    const closeDialog = () => {
        dispatch(closeEditDialog());
    }

    let btnText = '';
    let publishValue = 0;
    let saveValue = 0;
    if (getValues) {
        if (getValues('publish_at') == 1) {
            btnText = 'Save & Unpublish';
            publishValue = 0;
            saveValue = 1;
        } else {
            btnText = 'Save & Publish';
            publishValue = 1;
            saveValue = 0;
        }
    }

    return (
        <Fragment>

            <Container fluid={true} className="product-wrapper">
                {loading ? <><PreLoader></PreLoader></> : <>
                    <Breadcrumb data={dataMenu} title="Product Size"/>
                    <div className="product-grid">
                        <div className="feature-products">
                            <Row>
                                <Col sm="12">
                                    {(currentUser.permission).includes('Create Product Sizes') ?
                                    <Card>
                                        <Row>
                                            <Col sm="6">
                                                <Nav tabs className="border-tab">

                                                </Nav>
                                            </Col>
                                            <Col sm="6">
                                                <div className='float-sm-right p-3'>
                                                    <a className="btn btn-sm btn-primary"
                                                       onClick={() => {
                                                           dispatch(openNewDialog())
                                                       }}
                                                    > <i className="fa fa-plus"></i> Add Product Size</a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                        :''}
                                    <Row>
                                        <Col xl="12" sm="12">
                                            <Form>
                                                <FormGroup className="m-0">
                                                    <input onChange={(e) => setName(e.target.value)} name="name"
                                                           type="text" className="form-control border"
                                                           placeholder="Search By Size Name"/>
                                                    <i className="fa fa-search"></i>
                                                </FormGroup>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <Card>
                                        <CardBody>
                            <div className="table-responsive product-table mt-4">
                                <CommonDataTable
                                    headerColumns={tableColumns}
                                    gridData={mapColourSettingToTable(productSize)}
                                    pagination
                                    onEdit={(currentUser.permission).includes('Update Product Sizes') ? onEdit:''}
                                    onDelete={(currentUser.permission).includes('Delete Product Sizes') ? onDelete:''}
                                />
                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                        </div>
                    </div> </>
                }
            </Container>
            <ModalFormSubmit
                defaultValues={defaultValues}
                schema={schema}
                onSubmitForm={onSubmitForm}
                reset={reset}
                formState={formState}
                handleSubmit={handleSubmit}
                title='Product Size'
                selector='product'
                dialog={({product}) => product.productSize.addDialog}
                closeDialog={closeDialog}
                setValue={setValue}
                getValues={getValues}
                publish
                btnText={btnText}
                publishValue={publishValue}
                saveValue={saveValue}
                buttonDisable={buttonDisable}

            >
                <ProductSizeForm
                    control={control} errors={errors} register={register} setValue={setValue} trigger={trigger}
                    getValues={getValues}
                />
            </ModalFormSubmit>
        </Fragment>
    );
}

export default withReducer([{productSize: productReducer}])(ProductSize)