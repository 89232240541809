import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import {
  addProductApi,
  addProductImageApi,
  deleteProductApi,
  downloadInventoryPdfApi,
  getExportApi,
  getInventoryAdjustDataApi,
  getInventorySettingsApi,
  getProductApi,
  getProductCreateSetting,
  getProductsApi,
  getProductSetting,
  getSubCategoryByCategoryId,
  getVariantBinLocationApi,
  getVariantDataAPi,
  getVariantSettingsAPi,
  inventoryUpdateAdjustmentApi,
  sellerBrandSuggestionApi,
  sortProductImageApi,
  updateInventoryBulkApi,
  updateInventoryBulkBySkuApi,
  updateProductApi,
} from "../../../services/manage-product/productService";
import { toast } from "react-toastify";
import {
  getInventoryProductsApi,
  getInventoryProductVariantsApi,
  updateInventoryApi,
} from "../../../services/manage-product/inventoryService";
import { setInventoryData } from "./inventoryAdjustmentsSlice";
import { getInventorySettings } from "./prodcutSlice";

export const getInventoryProducts = createAsyncThunk(
  "inventory/getProducts",
  async (data, { dispatch }) => {
    const response = await getInventoryProductsApi(data);
    dispatch(setInventoryProductPagination(response.response.pagination));
    return response.response.data;
  }
);

export const updateInventory = createAsyncThunk(
  "inventory/update",
  async (product, { dispatch, getState }) => {
    try {
      const response = await updateInventoryApi(product.variation, product.id);
      const data = await response.response;
      toast.success("Update inventory successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const getBrands = createAsyncThunk(
  "product/brand-auto-suggestion",
  async (data, { dispatch }) => {
    const response = await sellerBrandSuggestionApi(data);
    return response.response;
  }
);

export const getExportExcel = createAsyncThunk(
  "inventory/export-stockprice",
  async (data, { dispatch }) => {
    const response = await getExportApi(data);
    return response.response;
  }
);

export const downloadInvetoryPdf = createAsyncThunk(
  "inventory/export-inventory",
  async (data, { dispatch }) => {
    const response = await downloadInventoryPdfApi(data.params);
    return response.response;
  }
);

export const updateInventoryBulk = createAsyncThunk(
  "inventory/update-bulk",
  async (product, { dispatch, getState }) => {
    try {
      console.log(product);
      const formData = new FormData();
      formData.append("file", product.file);
      const response = await updateInventoryBulkApi(formData);
      const data = await response.response;

      toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);
export const updateInventoryBulkBuSku = createAsyncThunk(
  "inventory/update-bulk-by-sku",
  async (product, { dispatch, getState }) => {
    try {
      console.log(product);
      const formData = new FormData();
      formData.append("file", product.file);
      const response = await updateInventoryBulkBySkuApi(formData);
      const data = await response.response;

      toast.success("updated successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const getSkuDetails = createAsyncThunk(
  "product/getSkuDetails",
  async (data, { dispatch }) => {
    const response = await getVariantDataAPi(data);
    return response.response;
  }
);

export const getSettings = createAsyncThunk(
  "product/getInventorySettings",
  async (data, { dispatch }) => {
    const response = await getVariantSettingsAPi(data);
    return response.response;
  }
);

export const getVariantBinLocations = createAsyncThunk(
  "product/getInventoryAdjust",
  async (data, { dispatch }) => {
    const response = await getVariantBinLocationApi(data);
    return response.response;
  }
);
export const updateInventoryAdjustment = createAsyncThunk(
  "inventory/update-bin",
  async (product, { dispatch, getState }) => {
    try {
      const response = await inventoryUpdateAdjustmentApi(product);
      const data = await response.response;
      toast.success("Update inventory successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);
const inventoryAdapter = createEntityAdapter({});

export const {
  selectAll: selectInventory,
  selectById: selectInventoryProductId,
} = inventoryAdapter.getSelectors((state) => state.product.inventory);

const inventorySlice = createSlice({
  name: "inventories",
  initialState: inventoryAdapter.getInitialState({
    searchText: "",
    pagination: {
      count: 1,
      total: 1,
      page_total: 1,
    },
    addDialog: {
      component: "Bulk Upload",
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    binTransferDialogProps: {
      data: null,
      props: {
        isOpen: false,
      },
    },
    productVariants: [],
    variant: null,
    settings: null,
  }),
  reducers: {
    setInventoryProductPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setProductVariants: (state, action) => {
      state.productVariants = action.payload;
    },
    openDialog: (state, action) => {
      state.addDialog = {
        component: "Bulk Upload",
        type: "new",
        props: {
          isOpen: true,
        },
        data: null,
        option: action.payload.option,
      };
    },
    handelBinTransferDialog: (state, action) => {
      state.binTransferDialogProps = action.payload;
    },
    closeDialog: (state, action) => {
      state.addDialog = {
        type: "new",
        props: {
          isOpen: false,
        },
        data: null,
      };
    },
    setVariantData: (state, action) => {
      state.variant = action.payload;
    },
    setInventorySettings: (state, action) => {
      state.settings = action.payload;
    },
  },
  extraReducers: {
    [getInventoryProducts.fulfilled]: inventoryAdapter.setAll,
    // [updateStaff.fulfilled]: staffsAdapter.setAll
    // [deleteStaff.fulfilled]: staffsAdapter.removeOne,
  },
});

export const {
  setInventoryProductPagination,
  setProductVariants,
  openDialog,
  closeDialog,
  setVariantData,
  setInventorySettings,
  handelBinTransferDialog,
} = inventorySlice.actions;

export default inventorySlice.reducer;
