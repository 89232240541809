import API from "../../utils/api";

// INTERNAL SALES-PERSON
export const getInternalStaffApi = async (filters) => {
  const response = await API.get("user/internal-staff", { params: filters });
  return { response };
};

export const addInternalStaffApi = async (location) => {
  const response = await API.post("user/internal-staff", location);
  return { response };
};

export const updateInternalStaffApi = async (location, id) => {
  const response = await API.put(`user/internal-staff/${id}`, location);
  return { response };
};

export const deleteInternalStaffApi = async (id) => {
  const response = await API.delete(`user/internal-staff/${id}`);
  return { response };
};

export const getStaffSettingsApi = async () => {
  const response = await API.get("user/internal-staff/get-settings");
  return { response };
};

// EXTERNAL SALES-PERSON
export const getExternalStaffApi = async (filters) => {
  const response = await API.get("user/external-staff", { params: filters });
  return { response };
};

export const addExternalStaffApi = async (location) => {
  const response = await API.post("user/external-staff", location);
  return { response };
};

export const updateExternalStaffApi = async (location, id) => {
  const response = await API.put(`user/external-staff/${id}`, location);
  return { response };
};

export const deleteExternalStaffApi = async (id) => {
  const response = await API.delete(`user/external-staff/${id}`);
  return { response };
};

// ADDITIONAL APIS
export const staffAutoSuggestionApi = async (text) => {
  const response = await API.get("user/internal-staff/staff-auto-suggestion", {
    params: text,
  });
  return response;
};

export const externalStaffAutoSuggestionApi = async (text, id) => {
  const response = await API.get(
    `user/internal-staff/external-staff-auto-suggestion/${id}`,
    {
      params: text,
    }
  );
  return response;
};
