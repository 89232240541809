import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  addProductGroupApi,
  deleteProductGroupApi,
  getProductGroupsApi,
  updateProductGroupApi,
} from "../../../services/manage-product/productGroupService";

export const getAllProductGroups = createAsyncThunk(
  "product-group/getProductGroups",
  async (filters, { dispatch }) => {
    const response = await getProductGroupsApi(filters);
    return response.response.data;
  }
);

export const addProductGroup = createAsyncThunk(
  "product-group/addProductGroup",
  async (productGroup, { dispatch, getState }) => {
    try {
      const response = await addProductGroupApi(productGroup);
      const data = await response.response;
      dispatch(getAllProductGroups());
      toast.success("Product Group Added Successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const updateProductGroup = createAsyncThunk(
  "product-group/updateProductGroup",
  async (productGroup, { dispatch, getStatus }) => {
    try {
      const response = await updateProductGroupApi(
        productGroup,
        productGroup.id
      );
      const data = await response.response;
      dispatch(getAllProductGroups());
      toast.success("Updated Product Group Successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const deleteProductGroup = createAsyncThunk(
  "product-group/deleteProductGroup",
  async (id, { dispatch, getStatus }) => {
    try {
      const response = await deleteProductGroupApi(id);
      const data = await response.response;
      dispatch(getAllProductGroups());
      toast.success("Deleted Product Group Successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

const productGroupAdapter = createEntityAdapter({});

export const {
  selectAll: selectProductGroup,
  selectById: selectProductGroupById,
} = productGroupAdapter.getSelectors((state) => state.product.productGroup);

console.log(productGroupAdapter);

const productGroupSlice = createSlice({
  name: "productGroup",
  initialState: productGroupAdapter.getInitialState({
    searchText: "",
    addDialog: {
      component: "ProductGroup",
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setProductGroupSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewDialog: (state, action) => {
      state.addDialog = {
        component: "ProductGroup",
        type: "new",
        props: {
          isOpen: true,
        },
        data: null,
      };
    },
    openEditDialog: (state, action) => {
      state.addDialog = {
        type: "edit",
        props: {
          isOpen: true,
        },
        data: action.payload,
      };
    },
    closeEditDialog: (state, action) => {
      state.addDialog = {
        type: "edit",
        props: {
          isOpen: false,
        },
        data: null,
      };
    },
  },
  extraReducers: {
    [getAllProductGroups.fulfilled]: productGroupAdapter.setAll,
  },
});

export const {
  setProductGroupSearchText,
  openNewDialog,
  closeNewDialog,
  openEditDialog,
  closeEditDialog,
} = productGroupSlice.actions;

export default productGroupSlice.reducer;
