import API from "../../utils/api";

export const getProductsApi = async (data) => {
  const response = await API.get(`product`, {
    params: data,
  });
  return { response };
};
export const getProductSetting = async (data) => {
  const response = await API.get(`product/get-product-settings`, {
    params: data,
  });
  return { response };
};

export const getProductCreateSetting = async (data) => {
  const response = await API.get(`product/get-product-create-settings`, {
    params: data,
  });
  return { response };
};

export const addProductApi = async (data) => {
  const response = await API.post("product", data);
  return { response };
};

export const getProductApi = async (id) => {
  const response = await API.get(`product/` + id + "/edit");
  return { response };
};
export const getProductViewApi = async (id) => {
  const response = await API.get(`product/` + id);
  return { response };
};
export const updateProductApi = async (data, id) => {
  const response = await API.put(`product/${id}`, data);
  return { response };
};

export const getSubCategoryByCategoryId = async (id) => {
  const response = await API.get(`product/get-sub-category/` + id);
  return { response };
};
export const addProductImageApi = async (id, data) => {
  const response = await API.post("product/upload-image/" + id, data, {
    "content-type": "multipart/form-data",
  });
  return { response };
};

export const deleteProductApi = async (id) => {
  const response = await API.delete(`product/delete-image/${id}`);
  return { response };
};

export const sortProductImageApi = async (id, data) => {
  const response = await API.post("product/sort-image/" + id, data);
  return { response };
};

export const bulkProductApi = async (data) => {
  const response = await API.post("product/bulk-product-action", data);
  return { response };
};
export const createDuplicateProductApi = async (data) => {
  const response = await API.post("product/create-duplicate-product", data);
  return { response };
};

export const bulkSalesOfferApi = async (data) => {
  const response = await API.post("product/bulk-sales-offer-update", data);
  return { response };
};

export const updateProductImageAltTextApi = async (data) => {
  const response = await API.post("product/update-image-alt-text", data);
  return { response };
};
export const sellerAutoSuggestionApi = async (text) => {
  const response = await API.get("product/seller-auto-suggestion", {
    params: text,
  });
  return { response };
};

export const sellerBrandSuggestionApi = async (text) => {
  const response = await API.get("product/brand-auto-suggestion", {
    params: text,
  });
  return { response };
};
export const taxonomySuggestionApi = async (text) => {
  const response = await API.get("product/taxonomy-auto-suggestion", {
    params: text,
  });
  return { response };
};

export const addProductLookBookApi = async (data) => {
  const response = await API.post(
    "product/look-book/store-product-look-book",
    data
  );
  return { response };
};

export const downloadLookBookApi = async (id) => {
  const response = await API.get(`product/look-book/product-look-book/${id}`, {
    "content-type": "application/pdf",
    responseType: "blob",
  });
  return { response };
};

export const checkLookBookTitleApi = async (data) => {
  const response = await API.get("product/look-book/check-look-book_title", {
    params: data,
  });
  return { response };
};

export const getLookBookApi = async (data) => {
  const response = await API.get(`product/look-book/look-book-view`, {
    params: data,
  });
  return { response };
};

export const getCurrencyRateApi = async (price, code) => {
  const response = await API.get(
    `product/look-book/currency-convert/${price}/${code}`
  );
  return { response };
};

export const getExportApi = async (data) => {
  const response = await API.get(
    `product/export-stockprice`,
    {
      params: data,
    },
    { "content-type": "application/vnd.ms-excel", responseType: "blob" }
  );
  return { response };
};

export const downloadInventoryPdfApi = async (data) => {
  const response = await API.get(`product/export-stockprice/pdf`, {
    "content-type": "application/pdf",
    responseType: "blob",
    params: data,
  });
  return { response };
};

export const updateInventoryBulkApi = async (data) => {
  const response = await API.post("product/import-stockprice", data, {
    "content-type": "multipart/form-data",
  });
  return { response };
};
export const updateInventoryBulkBySkuApi = async (data) => {
  const response = await API.post("product/inventory/import-by-sku", data, {
    "content-type": "multipart/form-data",
    responseType: "json",
  });
  return { response };
};

export const addProductHolidayOfferApi = async (data) => {
  const response = await API.post("product/holiday-mode", data);
  return { response };
};

export const downloadSampleCsvApi = async () => {
  const response = await API.get("product/download-sample-csv", {
    "content-type": "application/vnd.ms-excel",
    responseType: "blob",
  });
  return { response };
};

export const importProductCsvApi = async (data) => {
  const response = await API.post(`product/import-products`, data);
  return { response };
};

export const createSentImageApi = async (data) => {
  const response = await API.post("product/send-image", data);
  return { response };
};

export const updateAddtionalApi = async (data, id) => {
  const response = await API.post(`product/additional-details/${id}`, data, {
    "content-type": "multipart/form-data",
  });
  return { response };
};

export const getAdditionalSettingsApi = async (id) => {
  const response = await API.get(`product/additional-details/${id}`);
  return { response };
};

export const getProductInfoApi = async (id) => {
  const response = await API.get(`product/get-draft-product/${id}`);
  return { response };
};

export const exportProductsApi = async (data) => {
  const response = await API.get(
    "product/get-all-product-csv",
    { params: data },
    { "content-type": "application/vnd.ms-excel", responseType: "blob" }
  );
  return { response };
};

export const exportProductsToTBOneApi = async (data) => {
  const response = await API.get(
    "tbone/product-export",
    { params: data },
    { "content-type": "application/vnd.ms-excel", responseType: "blob" }
  );
  return { response };
};

export const inventoryAdjustmentApi = async (data) => {
  const response = await API.post(`product/inventory-save`, data, {
    "content-type": "multipart/form-data",
  });
  return { response };
};

export const getInventorySettingsApi = async () => {
  const response = await API.get("product/inventory-details");
  return { response };
};

export const inventorySkuApi = async (query) => {
  const response = await API.get("product/sku-auto-suggestion", {
    params: { query: query },
  });
  return { response };
};

export const skuLocationsApi = async (query) => {
  const response = await API.get("product/sku-locations", {
    params: { query: query },
  });
  return { response };
};

export const skuDetailsApi = async (query) => {
  console.log("AJAYYYYYYYYY", query);
  const response = await API.get("product/sku-details", {
    params: query,
  });
  return { response };
};

export const inventoryBinApi = async (text) => {
  const response = await API.get("product/bin-auto-suggestion", {
    params: text,
  });
  return { response };
};

export const getInventoryAdjustApi = async (data) => {
  const response = await API.get("product/get-all-inventory-data", {
    params: data,
  });
  return { response };
};

export const exportInventoryAdjustmentDetailsApi = async (data) => {
  const response = await API.get(
    "product/export-inventory-adjustment-report",
    { params: data },
    { "content-type": "application/vnd.ms-excel", responseType: "blob" }
  );
  return { response };
};

export const exportInventoryAdjustmentGainLoseApi = async (data) => {
  const response = await API.get(
    "compare-products",
    { params: data },
    { "content-type": "application/vnd.ms-excel", responseType: "blob" }
  );
  return { response };
};

export const getInventoryAdjustDataApi = async (data) => {
  const response = await API.get("product/get-all-inventory", { params: data });
  return { response };
};

export const exportConditionApi = async (data) => {
  const response = await API.get("product/inventory-download-excel", {
    params: data,
  });
  return { response };
};

export const getVariantDataAPi = async (data) => {
  const response = await API.get(`product/inventory/variant`, { params: data });
  return { response };
};
export const getVariantSettingsAPi = async (data) => {
  const response = await API.get(`product/inventory/settings`, {
    params: data,
  });
  return { response };
};

export const inventoryAdjustmentDataApi = async (data) => {
  const response = await API.post(`product/inventory-adjustment-save`, data, {
    "content-type": "multipart/form-data",
  });
  return { response };
};

export const exportBarcodeDetailsApi = async (data) => {
  const response = await API.get(
    "product/export-barcode-missing-report",
    { params: data },
    { "content-type": "application/vnd.ms-excel", responseType: "blob" }
  );
  return { response };
};

export const exportInventoryDetailsApi = async (data) => {
  const response = await API.get(
    "product/export-inventory-updates-report",
    { params: data },
    { "content-type": "application/vnd.ms-excel", responseType: "blob" }
  );
  return { response };
};

export const exportPlasticDetailsApi = async (data) => {
  const response = await API.get(
    "product/export-plastic-bag-damaged-report",
    { params: data },
    { "content-type": "application/vnd.ms-excel", responseType: "blob" }
  );
  return { response };
};

export const exportProductConditionDetailsApi = async (data) => {
  const response = await API.get("product/export-product-condition-report", {
    params: data,
  });
  return { response };
};

export const exportRibbonDetailsApi = async (data) => {
  const response = await API.get(
    "product/export-ribbon-missing-report",
    { params: data },
    { "content-type": "application/vnd.ms-excel", responseType: "blob" }
  );
  return { response };
};

export const binAutoSuggestionApi = async (text) => {
  const response = await API.get("product/bin-auto-suggestion", {
    params: text,
  });
  return { response };
};

export const inventoryUpdateAdjustmentApi = async (data) => {
  const response = await API.post("product/inventory/adjustment", data);
  return { response };
};
export const getVariantBinLocationApi = async (data) => {
  const response = await API.get("product/variant-bin-location", {
    params: data,
  });
  return { response };
};
