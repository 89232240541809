import React, { Fragment, useEffect } from "react";
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { ModalFooter } from "react-bootstrap";
import * as yup from "yup";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { setOrderInvoiceItemDiscountDialog } from "../../../store/draftOrderSlice";

const defaultValues = {
  discount_type: 1,
  value: "",
  reason: "",
};
const schema = yup.object().shape({
  discount_type: yup.string().required("You must select type"),
  value: yup
    .number()
    .typeError("must be a number")
    .required("this field is required")
    .min(1, "should be grater than 0"),
});

const OrderInvoiceItemDiscount = (props) => {
  const {
    orderSetValue,
    orderTrigger,
    orderGetValues,
    onChangeDialog,
    rowdata,
    orderedProducts,
    isDiscountValueChange,
  } = props;
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState,
    reset,
    control,
    register,
    setValue,
    handleChange,
    trigger,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const orderState = useSelector(
    ({ order }) => order.draftOrder.itemInvoiceDiscountDialogProps
  );
  const { errors, isDirty } = formState;

  const closDialog = () => {
    dispatch(setOrderInvoiceItemDiscountDialog({ props: { isOpen: false } }));
  };

  useEffect(() => {
    if (orderState.props.isOpen) {
      const discountData = [];
      console.log("item_discount data ", orderState.data);
      const data = {
        discount: [],
      };
      reset(data);
      setValue("discount_type", defaultValues.discount_type);
    }
  }, [orderState.props.isOpen]);

  // const onChildSubmit = (data) => {
  //   const itemDiscount = [];
  //   console.log("Data from modal: ", data);

  //   // data.map(function (item, index) {
  //   // if (data.is_selected) {
  //   itemDiscount.push({
  //     discount_type: data.discount_type,
  //     value: data.value,
  //     reason: data.reason,
  //   });
  //   // }
  //   // });

  //   console.log("Item Discounts to be submitted: ", itemDiscount);
  //   orderSetValue("item_discount", itemDiscount);
  //   orderTrigger("item_discount");
  //   const orderProducts = orderGetValues("ordered_products");
  //   const orderIndex = orderProducts.findIndex(
  //     (x) => x.variant == orderState.data.variant_id
  //   );
  //   const orderData = orderProducts.find(
  //     (obj) => obj.variant === orderState.data.variant_id
  //   );
  //   console.log("Item orderData: ", orderData);
  //   orderProducts[orderIndex] = {
  //     ...orderData,
  //     item_discount: itemDiscount,
  //   };
  //   orderSetValue("ordered_products", orderProducts);
  //   orderTrigger("ordered_products");
  //   console.log("Final orderProducts ", orderProducts);
  //   onChangeDialog(true);
  //   closDialog();
  // };

  const onChildSubmit = (data) => {
    const itemDiscount = [
      {
        discount_type: data.discount_type,
        value: data.value,
        reason: data.reason,
      },
    ];

    orderSetValue("item_discount", itemDiscount);
    orderTrigger("item_discount");

    const orderProducts = orderGetValues("ordered_products");

    console.log("orderProducts ", orderProducts);
    const orderIndex = orderProducts.findIndex(
      (x) => x.variant === rowdata.product.variant
    );
    const orderData = orderProducts.find(
      (obj) => obj.variant === rowdata.product.variant
    );
    console.log("Item orderData: ", orderData);

    orderProducts[orderIndex] = {
      ...orderData,
      item_discount: itemDiscount,
    };

    orderSetValue("ordered_products", orderProducts);
    orderTrigger("ordered_products");
    console.log("Final orderProducts ", orderProducts);
    onChangeDialog(true);
    // isDiscountValueChange == !isDiscountValueChange;
    // setIsDiscountValueChange(!isDiscountValueChange);
    closDialog();
  };

  const enableButton = () => {
    if (orderState.props.isOpen) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Fragment>
      <div className="form-row mb-3">
        <Modal
          size="md"
          {...orderState.props}
          toggle={() => {
            closDialog();
          }}
        >
          <ModalHeader
            toggle={() => {
              closDialog();
            }}
          >
            {orderState.data?.sku}
          </ModalHeader>
          <Form noValidate="" onSubmit={handleSubmit(onChildSubmit)}>
            <ModalBody>
              {orderState.props.isOpen && (
                <>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label className="col-form-label">Discount Type</Label>
                        <Input
                          type="select"
                          name="select"
                          onChange={(e) => {
                            setValue("discount_type", e.target.value);
                          }}
                          className={`form-control ${
                            errors.discount_type ? "is-invalid" : ""
                          }`}
                        >
                          <option value="1">Amount</option>
                          <option value="2">Percentage</option>
                        </Input>

                        <div className="invalid-feedback">
                          {errors.discount_type?.message}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label className="col-form-label">Value</Label>
                        <input
                          name="value"
                          type="text"
                          {...register("value")}
                          className={`form-control ${
                            errors.value ? "is-invalid" : ""
                          }`}
                        />
                        <div className="invalid-feedback">
                          {errors.value?.message}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* {orderGetValues("item_discount") && (
                    <Row>
                      <Col md={12}>
                        <button
                          className="btn btn-danger btn-sm float-right"
                          onClick={() => {
                            orderSetValue("item_discount", "");
                            orderTrigger("item_discount");
                          }}
                        >
                          Remove <i className="fa fa-trash"></i>
                        </button>
                      </Col>
                    </Row>
                  )} */}
                </>
              )}
            </ModalBody>
            <ModalFooter>
              {enableButton() && (
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={handleSubmit(onChildSubmit)}
                >
                  Save
                </button>
              )}
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    </Fragment>
  );
};

function areEqual(prevProps, nextProps) {
  return (
    JSON.stringify(prevProps.getValues) == JSON.stringify(nextProps.getValues)
  );
}

export default OrderInvoiceItemDiscount;
// export default React.memo(OrderInvoiceItemDiscount, areEqual);
