import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import { Card, CardBody, Col, Container, Nav, Row } from "reactstrap";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import productReducer from "../../store";
import { productGroupResponseToTable } from "../../../../utils/mapper";
import withReducer from "../../../../store/withReducer";
import ProductGroupForm from "./productGroupForm";
import ModalFormSubmit from "../../../../share-components/form/modalFormSubmit";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import PreLoader from "../../../../share-components/ui/preLoader";
import {
  addProductGroup,
  closeEditDialog,
  deleteProductGroup,
  getAllProductGroups,
  openEditDialog,
  openNewDialog,
  selectProductGroup,
  updateProductGroup,
} from "../../store/productGroupSlice";

const defaultValues = {
  id: "",
  name: "",
};

const dataMenu = [
  {
    type: 0,
    text: "Products",
  },
  {
    type: 1,
    text: "Product Setting",
    link: "",
  },
  {
    type: 2,
    text: "Product Groups",
    link: "",
  },
];

const schema = yup.object().shape({
  name: yup.string().required("You must enter a Product Group name"),
});

const ProductGroup = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState([]);
  const [loading, setLoading] = useState(true);
  const productGroups = useSelector(selectProductGroup);
  const [buttonDisable, setButtonDisable] = useState(false);
  const currentUser = useSelector(({ authReducer }) => authReducer.data.user);

  useEffect(() => {
    const data = {
      name,
    };
    dispatch(getAllProductGroups({ ...data }, "season")).then((res) => {
      if (!res.error) setLoading(false);
    });
  }, [dispatch, name]);

  const {
    handleSubmit,
    formState,
    reset,
    control,
    register,
    setValue,
    trigger,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  const onSubmitForm = (dialog, data) => {
    setButtonDisable(true);
    if (dialog.type === "new") {
      dispatch(addProductGroup({ ...data })).then((res) => {
        setButtonDisable(false);
        console.log(res);
        if (!res.error) closeDialog(dialog.type);
      });
    } else {
      dispatch(updateProductGroup({ ...data })).then((res) => {
        setButtonDisable(false);
        if (!res.error) closeDialog(dialog.type);
      });
    }
  };

  const closeDialog = () => {
    dispatch(closeEditDialog());
  };

  const onEdit = (row) => {
    dispatch(openEditDialog(row));
  };

  const onDelete = (row) => {
    dispatch(deleteProductGroup(row.id));
  };

  let btnText = "";
  let saveValue = 0;

  const tableColumns = [
    {
      name: "Product Group",
      selector: (row) => row.name,
      sortable: true,
      left: true,
    },
  ];

  return (
    <Fragment>
      <Container fluid={true} className="product-wrapper">
        {loading ? (
          <>
            <PreLoader></PreLoader>
          </>
        ) : (
          <>
            <Breadcrumb data={dataMenu} title="Product Group" />
            <div className="product-grid">
              <div className="feature-products">
                <Row>
                  <Col sm="12">
                    {currentUser.permission.includes("Create Product Types") ? (
                      <Card>
                        <Row>
                          <Col sm="6">
                            <Nav tabs className="border-tab"></Nav>
                          </Col>
                          <Col sm="6">
                            <div className="float-sm-right p-3">
                              <a
                                className="btn btn-sm btn-primary"
                                onClick={() => {
                                  dispatch(openNewDialog());
                                }}
                              >
                                {" "}
                                <i className="fa fa-plus"></i> Add Product Group
                              </a>
                            </div>
                          </Col>
                        </Row>
                      </Card>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </div>
            </div>

            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <div className="table-responsive product-table mt-4">
                      <CommonDataTable
                        headerColumns={tableColumns}
                        gridData={productGroupResponseToTable(productGroups)}
                        pagination
                        onEdit={
                          currentUser.permission.includes(
                            "Update Product Types"
                          )
                            ? onEdit
                            : ""
                        }
                        onDelete={
                          currentUser.permission.includes(
                            "Delete Product Types"
                          )
                            ? onDelete
                            : ""
                        }
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </Container>

      <ModalFormSubmit
        defaultValues={defaultValues}
        schema={schema}
        onSubmitForm={onSubmitForm}
        reset={reset}
        formState={formState}
        handleSubmit={handleSubmit}
        title="Product Group"
        selector="product"
        dialog={({ product }) => product.productGroup.addDialog}
        closeDialog={closeDialog}
        setValue={setValue}
        getValues={getValues}
        btnText={btnText}
        saveValue={saveValue}
        buttonDisable={buttonDisable}
      >
        <ProductGroupForm
          control={control}
          errors={errors}
          register={register}
          setValue={setValue}
          trigger={trigger}
          getValues={getValues}
        />
      </ModalFormSubmit>
    </Fragment>
  );
};

export default withReducer([
  {
    productGroup: productReducer,
  },
])(ProductGroup);
