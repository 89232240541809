import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Col,
  Card,
  CardBody,
  Row,
  Label,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import SaleOrderDiscussion from "../view/content/saleOrderDiscussion";
import PreLoader from "../../../../share-components/ui/preLoader";
import {
  setSalesOrderPendingPickingDialogProps,
  showSalesOrderPendingPicking,
} from "../../store/salesOrderSlice";

const defaultValues = {
  id: "",
};

const PendingPickingModal = () => {
  const dispatch = useDispatch();
  const [statusName, setStatusName] = useState("");
  const [loading, isSetLoading] = useState(false);
  const salesDialogProps = useSelector(
    (state) => state.order.salesOrder.salesOrderPendingPickingDialogProps
  );
  const order_id = salesDialogProps.data.id;
  const [discussionSection, setDiscussionSection] = useState(false);
  const [comments, setComments] = useState([]);

  const toggleModal = () => {
    closeDialog();
  };

  const closeDialog = () => {
    const obj = { props: { isOpen: false }, type: "new", data: "" };
    dispatch(setSalesOrderPendingPickingDialogProps(obj));
  };

  useEffect(() => {
    const fetchData = async () => {
      if (order_id) {
        isSetLoading(true);

        try {
          const indexRes = await dispatch(
            showSalesOrderPendingPicking(order_id)
          );

          if (!indexRes.error) {
            console.log("Success Data:", indexRes);
            setStatusName(indexRes.payload.status || "");
            setComments(indexRes.payload.comments || []);
            setDiscussionSection(true);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          isSetLoading(false);
        }
      }
    };

    fetchData();
  }, [dispatch, order_id]);

  return (
    <Modal
      isOpen={salesDialogProps.props.isOpen}
      toggle={toggleModal}
      size="xl"
    >
      {loading ? (
        <>
          <PreLoader></PreLoader>
        </>
      ) : (
        <>
          <ModalHeader toggle={toggleModal}>
            <p
              style={{ fontSize: "20px" }}
            >{`Sales Order #${salesDialogProps.data.order_no}`}</p>
            <span
              style={{
                fontWeight: "lighter",
                color: "rgb(115, 115, 115)",
                fontSize: "16px",
              }}
            >
              Status :{" "}
              <span
                style={{
                  textTransform: "uppercase",
                }}
              >
                {statusName}
              </span>
            </span>
          </ModalHeader>
          <ModalBody>
            {salesDialogProps.data && (
              <>
                <Container fluid={true}>
                  <Col sm="12" className="p-2">
                    {discussionSection && (
                      <Card
                        style={{
                          border: "1px solid rgb(230, 230, 230)",
                          borderRadius: "8px",
                        }}
                      >
                        <CardBody
                          style={{ paddingTop: "24px", paddingBottom: "24px" }}
                        >
                          <Row>
                            <Col sm="6">
                              <Label
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: "16px",
                                  paddingBottom: "10px",
                                }}
                              >
                                Discussion
                              </Label>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm="12">
                              <SaleOrderDiscussion
                                salesOrderId={salesDialogProps.data.id}
                                salesOrderComments={comments}
                                isInputShow={salesDialogProps.isInputShow}
                              />
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    )}
                  </Col>
                </Container>
              </>
            )}
          </ModalBody>
        </>
      )}
    </Modal>
  );
};

export default PendingPickingModal;
