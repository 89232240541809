import {Card, CardBody, CardHeader, Col, FormGroup, Label, Row} from "reactstrap";
import React, {Fragment} from "react";


export const ViewPrimaryDetails = (props) => {
    const {sellerDetails}=props;
    return (
        <Fragment>
            <Col sm="12">
                <Card className="ribbon-wrapper">
                    <CardBody>
                        <div className="ribbon ribbon-clip ribbon-primary">Primary Details</div>
                        <Row>
                            <Col md={3}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Name</Label>
                                    <p>{sellerDetails.name}</p>
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Shop Name</Label>
                                    <p>{sellerDetails.shopName}</p>
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Email</Label>
                                    <p>{sellerDetails.email}</p>
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Mobile/Telephone</Label>
                                    <p>{sellerDetails.number}</p>
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">City</Label>
                                    <p>{sellerDetails.pdCity}</p>
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Country</Label>
                                    <p>{sellerDetails.pdCountry.code && <i className={"mr-1 flag-icon flag-icon-"+sellerDetails.pdCountry.code.toLowerCase()}></i>}{sellerDetails.pdCountry.name}</p>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    );
};