import React, { Fragment, useEffect } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import withReducer from "../../store/withReducer";
import shoppingReducer from "./store";
import { useDispatch, useSelector } from "react-redux";
import {
  getCart,
  getShoppingProduct,
  selectShoppingProduct,
  setCartItemData,
  setProductFilter,
} from "./store/shoppingSlice";
import ShoppingProductCard from "./content/shoppingProductCard";
import ShoppingProductFilter from "./content/shoppigProductFilter";
import { arrayToStringWithComma } from "../manage-product/product/edit/content/settings/map";

const Shopping = (props) => {
  const dispatch = useDispatch();
  const shoppingState = useSelector(({ shopping }) => shopping.shopping);
  const cardId = localStorage.getItem("cart-id");
  useEffect(() => {
    if (cardId) {
      dispatch(getCart(cardId)).then((res) => {
        dispatch(setCartItemData(res.payload.items));
      });
    }
  }, [dispatch]);
  useEffect(() => {
    const filter = shoppingState.filter;
    dispatch(
      getShoppingProduct({
        page: filter.currentPage + 1,
        sort: filter.sort,
        page_size: filter.pageSize,
        brand: filter.brand?.id,
        category: filter.category?.id,
        sub_category: filter.subCategory?.id,
        season: filter.season?.id,
        colour: filter.colour?.id,
        commodity: filter.commodity?.id,
        style: filter.product_style?.id,
        tag_type: filter.tag_type?.id,
        tag: filter.tags?.id,
        tags: filter.tags ? arrayToStringWithComma(filter.tags).id : "",
        country: filter.country?.id,
        composition: filter.composition?.id,
        compositions: filter.compositions
          ? arrayToStringWithComma(filter.compositions).id
          : "",
        product_group: filter.product_group?.id,
        stock: filter.stock ? filter.stock.min + "," + filter.stock.max : "",
        price: filter.price ? filter.price.min + "," + filter.price.max : "",
        offer_type: filter.offer_type?.id,
        status: 3,
        name: filter.name,
        type: filter.type?.id,
        variant_type: filter.variant_type?.id,
      })
    );
  }, [shoppingState.filter]);
  const product = useSelector(selectShoppingProduct);
  const onPageSizeChange = (e) => {
    dispatch(
      setProductFilter({
        ...shoppingState.filter,
        currentPage: 0,
        pageSize: e.target.value,
      })
    );
  };
  const pageChange = (event) => {
    dispatch(
      setProductFilter({ ...shoppingState.filter, currentPage: event.selected })
    );
  };
  return (
    <Fragment>
      <Breadcrumb parent="Starter Kit" title="Shopping" />
      <Container fluid={true}>
        <Row>
          <Col sm={12}>
            {shoppingState.settings && (
              <ShoppingProductFilter shoppingState={shoppingState} />
            )}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm="12">
            <Card>
              <CardBody>
                <ShoppingProductCard
                  products={product}
                  totalCount={shoppingState.pagination.count}
                  shoppingState={shoppingState}
                  onPageSizeChange={onPageSizeChange}
                  pageChange={pageChange}
                ></ShoppingProductCard>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default withReducer([{ shopping: shoppingReducer }])(Shopping);
