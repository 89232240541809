import API from "../utils/api";

export const getShoppingProductApi = async (data) => {
    const response = await API.get(`shopping/product`, { params: data });
    return { response };
};
export const getShoppingSettingApi = async (data) => {
    const response = await API.get(`shopping/setting`, { params: data });
    return { response };
};

export const getShoppingProductByIdApi = async (id) => {
    const response = await API.get(`shopping/product/${id}`);
    return { response };
};

export const addToCartApi = async (data) => {
    const response = await API.post(`shopping/cart`,data);
    return { response };
};

export const getCartApi = async (id) => {
    const response = await API.get(`shopping/cart/${id}`);
    return { response };
};

export const updateToCartApi = async (id,data) => {
    const response = await API.put(`shopping/cart/${id}`,data);
    return { response };
};
export const amendQuantityApi = async (data) => {
    const response = await API.post(`shopping/cart/amend-quantity`,data);
    return { response };
};

export const removeCartItemApi = async (data) => {
    const response = await API.post(`shopping/cart/remove-cart-item`,data);
    return { response };
};

export const checkoutApi = async (data) => {
    const response = await API.post(`shopping/cart/checkout`,data);
    return { response };
};

export const getCheckoutSettingsApi = async (data) => {
    const response = await API.get(`shopping/cart/checkout-settings`,data);
    return { response };
};
