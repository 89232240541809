import React, { Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Input,
} from "reactstrap";
import { NotFoundData } from "../../../constant";
import { getProductRibbonColor } from "../../../utils/common";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { handelBinTransferDialog } from "../../manage-product/store/inventorySlice";
import { useDispatch } from "react-redux";
import { setProductViewDialog } from "../store/shoppingSlice";
import ShoppingProductView from "./shoppingProductView";
import { ShoppingCart } from "react-feather";

const ShoppingProductCard = (props) => {
  const { products, pageChange, totalCount, onPageSizeChange, shoppingState } =
    props;
  const isProductOnCart = (product) => {
    return (
      shoppingState.cartItems &&
      shoppingState.cartItems.filter(function (item, index) {
        return item.product_id == product.id;
      }).length
    );
  };
  const dispatch = useDispatch();
  return (
    <>
      {products.length < 1 ? (
        <div className="search-not-found text-center">
          <div>
            <img
              className="img-fluid second-search"
              src={require("../../../assets/images/search-not-found.png")}
              alt=""
            />
            <p>{NotFoundData}</p>
          </div>
        </div>
      ) : (
        <Row className="gridRow">
          {products.map((item, i) => (
            <div className={`col-sm-3 col-grid-box`} key={i}>
              <Card>
                <div className="search-not-found text-center">
                  <div>
                    <img className="img-fluid second-search" alt="" />
                    <p></p>
                  </div>
                </div>
                <div className="product-box">
                  <div className="product-img">
                    <span
                      className={`ribbon  ${getProductRibbonColor(
                        item.status
                      )}`}
                    >
                      {item.brand}
                    </span>
                    <div className={`image-crop-center card-height-3`}>
                      {isProductOnCart(item) > 0 && (
                        <div
                          className="notification-box"
                          style={{
                            position: "absolute",
                            left: 0,
                            top: 0,
                            zIndex: 5,
                          }}
                        >
                          <i
                            className="fa fa-cart-plus fa-4x"
                            style={{ color: "green" }}
                          />
                          <span className="badge badge-pill badge-secondary">
                            {isProductOnCart(item)}
                          </span>
                        </div>
                      )}
                      <img
                        className="img-fluid scale-image-8"
                        src={item.url}
                        alt=""
                      />
                    </div>
                    <div className="product-hover">
                      <ul>
                        <li
                          onClick={() => {
                            dispatch(
                              setProductViewDialog({
                                props: { isOpen: true },
                                data: { id: item.id },
                              })
                            );
                          }}
                        >
                          <Button color="default" data-toggle="modal">
                            <i className="icon-eye"></i>
                          </Button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="product-details">
                    <Row>
                      <Col md={12}>
                        {item.colours.map((cItem, CIndex) => (
                          <span
                            key={CIndex}
                            style={{ background: cItem, height: 15, width: 15 }}
                            className="badge rounded-pill badge  rounded-pill"
                          >
                            {" "}
                          </span>
                        ))}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <h4
                          onClick={() => {}}
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            width: "100%",
                            whiteSpace: "nowrap",
                          }}
                          className="font-primary"
                        >
                          <a
                            style={{ textDecoration: "none", color: "#3E4A56" }}
                            href={item.product_url}
                            target="_blank"
                          >
                            {item.name}{" "}
                          </a>
                        </h4>
                      </Col>
                    </Row>
                    <Row className="mt-0">
                      <Col md={8}>
                        <p
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            width: "100%",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {item.code}
                        </p>
                      </Col>
                      <Col md={4} className="text-right"></Col>
                    </Row>

                    {/*<Row className="mt-1">*/}
                    {/*    <Col md={ 12}>*/}
                    {/*        <div className="product-price text-left">*/}
                    {/*            <strong>Cost :   </strong>*/}
                    {/*            {item.sale_price ? <>*/}
                    {/*                <del> {item.price ? '$' + item.price : ""}</del>*/}
                    {/*                <span*/}
                    {/*                    className="ml-1">  {item.sale_price ? '$' + item.sale_price : ""}</span>*/}
                    {/*            </> : <> {item.price ? '$' + item.price : ""}</>}*/}

                    {/*        </div>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                  </div>
                </div>
              </Card>
            </div>
          ))}
        </Row>
      )}
      <Row>
        <Col md={12}>
          <div style={{ display: "inline-flex", float: "right" }}>
            <div className="mr-1">
              <Input
                type="select"
                name="select"
                onChange={onPageSizeChange}
                className="form-control digits"
                defaultValue={shoppingState.filter.pageSize}
              >
                <option value="12">12 </option>
                <option value="24">24</option>
                <option value="48">48</option>
                <option value="96">96</option>
              </Input>
            </div>
            <div>
              <ReactPaginate
                className="pagination"
                activeClassName="pagination-active"
                breakLabel="..."
                nextLabel="next >"
                onPageChange={pageChange}
                pageRangeDisplayed={5}
                pageCount={totalCount}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </Col>
      </Row>
      <>
        <ShoppingProductView shoppingState={shoppingState} />
      </>
    </>
  );
};

export default ShoppingProductCard;
