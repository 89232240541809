import React, { Fragment, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap";
import {
  numberWithCommasWithoutPounds,
  numberWithCurrency,
} from "../../../../../utils/common";
import { saleOrderPaymentCalculation } from "../../setting/calculation";
import PickUpDetail from "./content/pickUpDetail";

const SalesOrderInvoiceView = (props) => {
  const {
    invoiceData,
    currency,
    isEditable,
    getValues,
    setValue,
    trigger,
    id,
    register,
    errors,
  } = props;
  const dispatch = useDispatch();
  const payment = saleOrderPaymentCalculation(invoiceData, currency);

  const invoiceName = (invoices) => {
    let name = "";
    invoices.map(function (item, index) {
      name += item.label + ",";
    });
    return name.substring(0, name.length - 1);
  };

  return (
    <Row>
      <Col md={12}>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col md={3}>
                    <span className="f-w-600">Invoice ID : </span>
                    <span className="f-w-100 ">
                      {invoiceData("invoice_no")}
                    </span>
                  </Col>
                  <Col md={3}>
                    <span className="f-w-600">Invoice Date : </span>
                    <span className="f-w-100 ">{invoiceData("date")}</span>
                  </Col>
                  <Col md={3}>
                    <span className="f-w-600">Due Date : </span>
                    <span className="f-w-100 ">{invoiceData("due_date")}</span>
                  </Col>
                  <Col md={3}>
                    <span className="f-w-600">Terms : </span>
                    <span className="f-w-100 ">
                      {invoiceData("terms")?.name}
                    </span>
                  </Col>
                </Row>
                <Row className="mt-2 pt-2">
                  <Col md={3}>
                    <span className="f-w-600">Payment Method : </span>
                    <span className="f-w-100 ">
                      {invoiceData("payment_method")?.name ??
                        invoiceData("payment_method")?.label ??
                        "N/A"}
                    </span>
                  </Col>
                  {/* <Col md={3}>
                    <span className="f-w-600">Bank : </span>
                    <span className="f-w-100 ">
                      {invoiceData("bank")?.name}
                    </span>
                  </Col> */}
                  {/*<Col md={3}>*/}
                  {/*  <span className="f-w-600">Trade Option : </span>*/}
                  {/*  <span className="f-w-100 ">*/}
                  {/*    {invoiceData("trade_option")?.name}*/}
                  {/*  </span>*/}
                  {/*</Col>*/}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col md={12}>
                    <div className="table-responsive w-100">
                      <Table>
                        <thead className="thead-light">
                          <tr>
                            <th style={{ width: "40%" }}>Product</th>
                            <th style={{ textAlign: "right", width: "10%" }}>
                              Order Qty
                            </th>
                            <th style={{ textAlign: "right", width: "10%" }}>
                              Invoice Qty
                            </th>
                            <th style={{ textAlign: "right", width: "10%" }}>
                              Rate
                            </th>
                            <th style={{ textAlign: "right", width: "10%" }}>
                              Sub Total
                            </th>
                            <th style={{ textAlign: "right", width: "10%" }}>
                              Tax Amount
                            </th>
                            <th style={{ textAlign: "right", width: "10%" }}>
                              Total
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoiceData("ordered_products")
                            .filter(function (value, index) {
                              return value.is_selected;
                            })
                            .map((item, i) => (
                              <tr key={i}>
                                <td>
                                  <div className="row">
                                    <div className="col-md-3">
                                      <img
                                        className="img-fluid"
                                        src={item.product.image}
                                        width="40"
                                        alt=""
                                      />
                                    </div>
                                    <div className="col-md-9">
                                      <div className="row">
                                        <p className={"font-primary"}>
                                          {item.product.name}
                                        </p>
                                      </div>
                                      <div className="row">
                                        <p>{item.product.option}</p>
                                      </div>
                                      <div className="row">
                                        <p>{item.product.sku}</p>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  <div style={{ marginTop: "1rem" }}>
                                    {item.order_quantity}
                                  </div>
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  <div style={{ marginTop: "1rem" }}>
                                    {item.quantity}
                                  </div>
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  <div style={{ marginTop: "1rem" }}>
                                    {numberWithCurrency(item.rate, currency)}{" "}
                                  </div>
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  <div style={{ marginTop: "1rem" }}>
                                    {numberWithCurrency(
                                      item.total_price,
                                      currency
                                    )}
                                  </div>
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  <div style={{ marginTop: "1rem" }}>
                                    {numberWithCurrency(
                                      item.tax_amount,
                                      currency
                                    )}
                                  </div>
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  <div style={{ marginTop: "1rem" }}>
                                    {numberWithCurrency(
                                      item.gross_price,
                                      currency
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            {isEditable ? (
              <PickUpDetail
                setValue={setValue}
                getValues={getValues}
                trigger={trigger}
                errors={errors}
                id={id}
                register={register}
              ></PickUpDetail>
            ) : (
              <Card>
                <CardBody>
                  <Row className="mb-3 mt-5">
                    <Col md="12">
                      <h6>Pickup Details</h6>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={12}>
                      <span className="f-w-600">Total No Of Boxes</span>
                      <span className="f-w-100 pull-right">
                        {invoiceData("is_weight_added")
                          ? invoiceData("total_no_of_boxes")
                          : ""}
                      </span>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={12}>
                      <span className="f-w-600">Total Net Weight</span>
                      {invoiceData("is_weight_added") && (
                        <span className="f-w-100 pull-right">
                          {invoiceData("total_net_weight")}{" "}
                          {invoiceData("net_unit_type") == "2" ? "Grams" : "Kg"}
                        </span>
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={12}>
                      <span className="f-w-600">Total Gross Weight</span>
                      {invoiceData("is_weight_added") && (
                        <span className="f-w-100 pull-right">
                          {invoiceData("total_gross_weight")}{" "}
                          {invoiceData("gross_unit_type") == "2"
                            ? "Grams"
                            : "Kg"}
                        </span>
                      )}
                    </Col>
                  </Row>
                  {invoiceData("is_merge") && (
                    <Row className="mt-3 mb-5">
                      <Col md={12}>
                        <span className="f-w-600">Merged Invoice</span>
                        <span className="f-w-100 pull-right">
                          {invoiceName(invoiceData("merge_invoices"))}
                        </span>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            )}
          </Col>
          <Col md={8}>
            <Card>
              <CardBody>
                <Row className="mb-3">
                  <Col md="12">
                    <h6>Payment</h6>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    {" "}
                    <span className="f-w-600">Total Amount</span>
                  </Col>
                  <Col md={6} className="text-center">
                    <span className="text-center">
                      ..............................................
                    </span>
                  </Col>
                  <Col md={3}>
                    {" "}
                    <span className="f-w-100 pull-right">
                      {payment.subTotal}
                    </span>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3}>
                    {" "}
                    <span className="f-w-600">Item wise Discount</span>
                  </Col>
                  <Col md={6} className="text-center">
                    <span className="text-center">
                      ..............................................
                    </span>
                  </Col>
                  <Col md={3}>
                    {" "}
                    <span className="f-w-100 pull-right">
                      {payment.itemDiscount}
                    </span>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3}>
                    <span className="f-w-600">Discount</span>
                  </Col>
                  <Col md={6} className="text-center">
                    <span className="text-center">
                      {invoiceData("discount")
                        ? invoiceData("discount").reason
                        : ".............................................."}
                    </span>
                  </Col>
                  <Col md={3}>
                    <span className="f-w-100 pull-right">
                      {payment.discountPrice}
                    </span>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3}>
                    {" "}
                    <span className="f-w-600">Sub Total</span>
                  </Col>
                  <Col md={6} className="text-center">
                    <span className="text-center">
                      ..............................................
                    </span>
                  </Col>
                  <Col md={3}>
                    {" "}
                    <span className="f-w-100 pull-right">
                      {payment.subTotalWithDiscount}
                    </span>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3}>
                    {" "}
                    <span className="f-w-600">Additional Charges</span>
                  </Col>
                  <Col md={6} className="text-center">
                    <span className="text-center">
                      {invoiceData("duty")
                        ? invoiceData("duty").reason
                        : ".............................................."}
                    </span>
                  </Col>
                  <Col md={3}>
                    {" "}
                    <span className="f-w-100 pull-right">
                      {payment.dutyPrice}
                    </span>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3}>
                    {" "}
                    <span className="f-w-600">Shipment</span>
                  </Col>
                  <Col md={6} className="text-center">
                    <span className="text-center">
                      {invoiceData("shipping")
                        ? invoiceData("shipping").rate_name
                        : ".............................................."}
                    </span>
                  </Col>
                  <Col md={3}>
                    {" "}
                    <span className="f-w-100 pull-right">
                      {payment.shippingPrice}
                    </span>
                  </Col>
                </Row>
                {/* <Row className="mt-2">
                  <Col md={3}>
                    {" "}
                    <span className="f-w-600">Tax</span>
                  </Col>
                  <Col md={6} className="text-center">
                    <span className="text-center">
                      ..............................................
                    </span>
                  </Col>
                  <Col md={3}>
                    {" "}
                    <span className="f-w-100 pull-right">
                      {payment.taxPrice}
                    </span>
                  </Col>
                </Row> */}
                <Row className="mt-2">
                  <Col md={3}>
                    {" "}
                    <span className="f-w-600">Net Total</span>
                  </Col>
                  <Col md={6} className="text-center">
                    <span className="text-center">
                      ..............................................
                    </span>
                  </Col>
                  <Col md={3}>
                    {" "}
                    <span className="f-w-100 pull-right">{payment.total}</span>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SalesOrderInvoiceView;
