import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  addExternalStaffApi,
  addInternalStaffApi,
  deleteExternalStaffApi,
  deleteInternalStaffApi,
  externalStaffAutoSuggestionApi,
  getExternalStaffApi,
  getInternalStaffApi,
  getStaffSettingsApi,
  updateExternalStaffApi,
  updateInternalStaffApi,
} from "../../../services/manage-user/staffService";

export const getInternalStaff = createAsyncThunk(
  "users/getInternalStaffData",
  async (_, { dispatch }) => {
    const response = await getInternalStaffApi();
    return response.response;
  }
);

export const getExternalStaff = createAsyncThunk(
  "users/getExternalStaffData",
  async (_, { dispatch }) => {
    const response = await getExternalStaffApi();
    return response.response;
  }
);

export const storeInternalStaff = createAsyncThunk(
  "users/storeInternalStaffData",
  async (data, { dispatch }) => {
    const response = await addInternalStaffApi(data);
    const res = response.response;
    dispatch(getInternalStaff());
    toast.success("Added Internal Staff successfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
    return res;
  }
);

export const storeExternalStaff = createAsyncThunk(
  "users/storeExternalStaffData",
  async (data, { dispatch }) => {
    const response = await addExternalStaffApi(data);
    const res = response.response;
    dispatch(getExternalStaff());
    toast.success("Added External Staff successfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
    return res;
  }
);

export const updateInternalStaff = createAsyncThunk(
  "users/updateInternalStaffData",
  async (internalStaff, { dispatch, getStatus }) => {
    try {
      const response = await updateInternalStaffApi(
        internalStaff,
        internalStaff.id
      );
      const data = await response.response;
      dispatch(getInternalStaff());
      toast.success("Internal Staff updated successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const updateExternalStaff = createAsyncThunk(
  "users/updateExternalStaffData",
  async (internalStaff, { dispatch, getStatus }) => {
    try {
      const response = await updateExternalStaffApi(
        internalStaff,
        internalStaff.id
      );
      const data = await response.response;
      dispatch(getExternalStaff());
      toast.success("External Staff updated successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const deleteInternalStaff = createAsyncThunk(
  "users/deleteInternalStaffData",
  async (id, { dispatch }) => {
    const response = await deleteInternalStaffApi(id);
    const res = response.response;
    dispatch(getInternalStaff());
    toast.success("Deleted Internal Staff successfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
    return res;
  }
);

export const deleteExternalStaff = createAsyncThunk(
  "users/deleteExternalStaffData",
  async (id, { dispatch }) => {
    const response = await deleteExternalStaffApi(id);
    const res = response.response;
    dispatch(getExternalStaff());
    toast.success("Deleted External Staff successfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
    return res;
  }
);

export const getStaffSettings = createAsyncThunk(
  "users/getStaffSettings",
  async () => {
    const response = await getStaffSettingsApi();
    return response.response;
  }
);

export const fetchExternalSalesPersons = createAsyncThunk(
  "users/fetchExternalSalesPersons",
  async (internalStaffId, { rejectWithValue }) => {
    try {
      const response = await externalStaffAutoSuggestionApi(
        {},
        internalStaffId
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const staffAdapter = createEntityAdapter();

const staffSlice = createSlice({
  name: "staff",
  initialState: staffAdapter.getInitialState({
    addDialog: {
      component: "staff",
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    openNewInDialog: (state, action) => {
      state.addDialog = {
        component: "staff",
        type: "new",
        props: {
          isOpen: true,
        },
        data: null,
      };
    },
    openEditInDialog: (state, action) => {
      state.addDialog = {
        type: "edit",
        props: {
          isOpen: true,
        },
        data: action.payload,
      };
    },
    closeEditInDialog: (state, action) => {
      state.addDialog = {
        type: "edit",
        props: {
          isOpen: false,
        },
        data: null,
      };
    },
    openNewExDialog: (state, action) => {
      state.addDialog = {
        component: "staff",
        type: "new",
        props: {
          isOpen: true,
        },
        data: null,
      };
    },
    openEditExDialog: (state, action) => {
      state.addDialog = {
        type: "edit",
        props: {
          isOpen: true,
        },
        data: action.payload,
      };
    },
    closeEditExDialog: (state, action) => {
      state.addDialog = {
        type: "edit",
        props: {
          isOpen: false,
        },
        data: null,
      };
    },
    setSettings: (state, action) => {
      state.settings = action.payload;
    },
  },
  extraReducers: {
    [getInternalStaff.fulfilled]: staffAdapter.setAll,
    [getExternalStaff.fulfilled]: staffAdapter.setAll,
  },
});
export const {
  openNewInDialog,
  openEditInDialog,
  closeEditInDialog,
  openNewExDialog,
  openEditExDialog,
  closeEditExDialog,
  setSettings,
} = staffSlice.actions;

export default staffSlice.reducer;
