import React, { Fragment } from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { EmailAddress, Phone } from "../../../../constant";
import Select from "react-select";

const InternalStaffForm = (props) => {
  const { errors, register, staffState, setValue, trigger, getValues } = props;

  return (
    <Fragment>
      <Row>
        <Col>
          <FormGroup>
            <Label className="col-form-label">Name</Label>
            <input
              name="name"
              type="text"
              {...register("name")}
              className={`form-control ${errors.name ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.name?.message}</div>
          </FormGroup>

          <FormGroup>
            <Label className="col-form-label">{EmailAddress}</Label>
            <input
              name="email"
              type="email"
              {...register("email")}
              className={`form-control ${errors.email ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.email?.message}</div>
          </FormGroup>

          <FormGroup>
            <Label className="col-form-label">{Phone}</Label>
            <input
              name="phone"
              type="tel"
              {...register("mobile_number")}
              className={`form-control ${
                errors.mobile_number ? "is-invalid" : ""
              }`}
            />
            <div className="invalid-feedback">
              {errors.mobile_number?.message}
            </div>
          </FormGroup>

          <FormGroup>
            <Label htmlFor="col-form-label">External Sales Person</Label>
            <Select
              getOptionLabel={(option) => option.name}
              defaultValue={getValues("external_staff")}
              getOptionValue={(option) => option.id}
              {...register("external_staff")}
              isMulti
              onChange={(e) => {
                setValue("external_staff", e);
                trigger("external_staff");
              }}
              className={` ${errors.role ? "is-invalid" : ""}`}
              options={staffState.settings.external_sales_persons}
            />

            <div className="invalid-feedback">{errors.role?.message}</div>
          </FormGroup>
        </Col>
      </Row>
    </Fragment>
  );
};

export default InternalStaffForm;
