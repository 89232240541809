import React, { Fragment, useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Col,
  Card,
  CardBody,
  Row,
  Label,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  setSalesInvoiceDialog,
  getSalesOrderStatus,
} from "../../../store/salesOrderStatusSlice";
import { downloadSalesOrderXeroInvoicePdf } from "../../../store/saleOrderInvoiceSlice";
import { downloadSalesOrderInvoicePdf } from "../../../store/salesOrderSlice";

const SalesInvoiceModal = () => {
  const dispatch = useDispatch();
  const [dropdownStatusOpenArray, setDropdownStatusOpenArray] = useState([]);
  const [statusName, setStatusName] = useState("");
  const [loading, isSetLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const authData = useSelector(({ authReducer }) => authReducer);
  const salesInvoiceDialogProps = useSelector(
    (state) => state.order.saleOrderStatus.salesInvoiceDialogProps
  );

  const processPDFDownload = (name, isCommercial, id, invoice_no) => {
    const data = {
      id: id,
      params: {
        is_commercial: isCommercial,
      },
    };
    dispatch(downloadSalesOrderInvoicePdf(data)).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${invoice_no}_${name.replace(/\s+/g, "-")}_${new Date()
          .toJSON()
          .slice(0, 10)}.pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const processXeroPDFDownload = (name, order_id, id, invoice_no) => {
    const orderId = order_id;
    console.log("Processing ", id);
    const data = {
      id: id,
      params: {
        name: name,
        seller: authData.defaultSeller?.value,
      },
    };
    console.log(data);
    dispatch(downloadSalesOrderXeroInvoicePdf(data)).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${invoice_no}_${name.replace(/\s+/g, "-")}_${new Date()
          .toJSON()
          .slice(0, 10)}.pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const toggleModal = () => {
    closeDialog();
    const data = {
      search: search,
    };
    dispatch(getSalesOrderStatus(data)).then((res) => {
      if (!res.error) isSetLoading(false);
    });
  };

  const toggleStatusDropdown = (index) => {
    const newDropdownStatusOpenArray = [...dropdownStatusOpenArray];
    newDropdownStatusOpenArray[index] = !newDropdownStatusOpenArray[index];
    setDropdownStatusOpenArray(newDropdownStatusOpenArray);
  };

  const downloadInvoice = (name, isCommercial, id, invoice_no) => {
    processPDFDownload(name, isCommercial, id, invoice_no);
  };

  const downloadXeroInvoice = (order_id, id, invoice_no) => {
    processXeroPDFDownload("Xero Invoice", order_id, id, invoice_no);
  };

  const closeDialog = () => {
    const obj = { props: { isOpen: false }, type: "new", data: "" };
    dispatch(setSalesInvoiceDialog(obj));
  };

  useEffect(() => {
    if (salesInvoiceDialogProps.data) {
      setStatusName(salesInvoiceDialogProps.data.status || "");
    }
  }, [salesInvoiceDialogProps.data]);

  return (
    <Modal
      isOpen={salesInvoiceDialogProps.props.isOpen}
      toggle={toggleModal}
      size="xl"
    >
      <ModalHeader toggle={toggleModal}>
        <p
          style={{ fontSize: "20px" }}
        >{`Sales Order #${salesInvoiceDialogProps.data.order_no}`}</p>
        <span
          style={{
            fontWeight: "lighter",
            color: "rgb(115, 115, 115)",
            fontSize: "16px",
          }}
        >
          Status :{" "}
          <span
            style={{
              textTransform: "uppercase",
            }}
          >
            {statusName}
          </span>
        </span>
      </ModalHeader>
      <ModalBody>
        {salesInvoiceDialogProps.data && (
          <>
            <Container fluid={true}>
              <div className="pb-2">
                <span style={{ fontSize: "18px", fontWeight: "600" }}>
                  Download Invoices
                </span>
              </div>
              <div className="d-flex mb-2 px-2">
                {salesInvoiceDialogProps.data.invoices && (
                  <>
                    {salesInvoiceDialogProps.data.invoices.map(
                      (invoice, index) => (
                        <Dropdown
                          key={invoice.id}
                          isOpen={dropdownStatusOpenArray[index] || false}
                          toggle={() => toggleStatusDropdown(index)}
                        >
                          <DropdownToggle
                            color="success"
                            className="btn btn-sm btn-success"
                            style={{ height: 38, marginRight: 8 }}
                          >
                            {invoice.invoice_no + "  "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={() => {
                                downloadInvoice(
                                  "Invoice",
                                  0,
                                  invoice.id,
                                  invoice.invoice_no
                                );
                              }}
                            >
                              Local Invoice
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                downloadInvoice(
                                  "Commercial Invoice",
                                  1,
                                  invoice.id,
                                  invoice.invoice_no
                                );
                              }}
                            >
                              Commercial Invoice
                            </DropdownItem>
                            {invoice.xero_id && (
                              <DropdownItem
                                onClick={() => {
                                  downloadXeroInvoice(
                                    salesInvoiceDialogProps.data.id,
                                    invoice.id,
                                    invoice.invoice_no
                                  );
                                }}
                              >
                                Xero Invoice
                              </DropdownItem>
                            )}
                          </DropdownMenu>
                        </Dropdown>
                      )
                    )}
                  </>
                )}
              </div>
            </Container>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default SalesInvoiceModal;
