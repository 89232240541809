import React, { Fragment, useEffect, useRef, useState } from "react";
import * as ReactDOM from "react-dom";
import {
  Badge,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  Row,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";

import {
  productSearch,
  setProducts,
  setSearchProductDialog,
} from "../../../store/draftOrderSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import { ModalFooter } from "react-bootstrap";
import { openNewDialog } from "../../../../manage-product/store/prodcutSlice";
import PreLoader from "../../../../../share-components/ui/preLoader";
import moment from "moment";

const SearchProductModal = (props) => {
  const {
    setValue,
    trigger,
    getValues,
    imageSelectOnClick,
    isAllowOutOfStock = false,
    onChangeDialog = null,
  } = props;
  const dispatch = useDispatch();
  const orderState = useSelector(({ order }) => order.draftOrder);
  let variantRef = useRef([]);
  let infiniteScrollRef = useRef();
  const [productData, setProductData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  const productSearchFunction = (isConcat, page) => {
    const parentValue = orderState.searchProductDialogProps.data.searchValue;
    if (!searchValue) {
      setSearchValue(orderState.searchProductDialogProps.data.searchValue);
    }
    if (searchValue || !parentValue) {
      dispatch(productSearch({ name: searchValue, page: page })).then((res) => {
        if (!res.error) {
          dispatch(setProducts(res.payload));
          if (isConcat) {
            setProductData((prevArray) => [...prevArray, ...res.payload.data]);
          } else {
            setProductData(res.payload.data);
          }
        }
      });
    }
  };
  useEffect(() => {
    if (orderState.searchProductDialogProps.props.isOpen) {
      if (infiniteScrollRef.current) {
        infiniteScrollRef.current.actionTriggered = false;
      }

      productSearchFunction(false, 1);
    }
  }, [orderState.searchProductDialogProps.props, searchValue]);
  useEffect(() => {
    if (infiniteScrollRef.current) {
      console.log(infiniteScrollRef.current);
      // infiniteScrollRef.current.lastScrollTop=0;
    }

    if (orderState.searchProductDialogProps.props.isOpen) {
      productSearchFunction(true, currentPage);
    }
  }, [currentPage]);

  const closDialog = () => {
    setSearchValue("");
    dispatch(setSearchProductDialog({ props: { isOpen: false } }));
  };

  const productOnChange = (e, product, variants) => {
    let orderProducts = getValues("ordered_products");
    variants.map((item, i) => {
      if (!item.is_out_of_stock || isAllowOutOfStock) {
        variantRef.current[item.id].checked = e.target.checked;
        orderProducts = productObjectUpdate(
          orderProducts,
          e.target.checked,
          product,
          item
        );
      }
    });

    setValue("ordered_products", orderProducts);
  };
  const variantOnChange = (e, product, variant) => {
    let orderProducts = getValues("ordered_products");
    orderProducts = productObjectUpdate(
      orderProducts,
      e.target.checked,
      product,
      variant
    );
    setValue("ordered_products", orderProducts);
  };
  const productObjectUpdate = (orderProducts, isCheck, product, variant) => {
    const orderedProductIndex = orderProducts.findIndex(
      (x) => x.variant === variant.variant_id
    );

    const isVat = getValues("customer") ? getValues("customer").is_vat : 0;
    const taxAmount = isVat == 1 ? variant.price * 0.02 : 0;

    if (orderedProductIndex != "-1") {
      if (!isCheck) {
        orderProducts.splice(orderedProductIndex, 1);
      }
    } else {
      const exchangeRate = getValues("currency_rate")
        ? Number(getValues("currency_rate"))
        : 1;
      orderProducts.push({
        product_id: product.id,
        commodity: product.commodity,
        variant_id: variant.id,
        variant: variant.variant_id,
        product_name: product.name,
        image: product.url,
        original_price: variant.price,
        price: variant.price * exchangeRate,
        sku: variant.sku,
        is_tax: isVat,
        variant_name: variant.variant_name,
        quantity: !variant.is_out_of_stock ? 1 : 0,
        total_price: !variant.is_out_of_stock
          ? variant.price * exchangeRate
          : 0,
        stock: !variant.is_out_of_stock ? variant.stock : 0,
        back_order_quantity: variant.is_out_of_stock ? 1 : 0,
        tax_amount: !variant.is_out_of_stock ? taxAmount * exchangeRate : 0,
        gross_amount: !variant.is_out_of_stock
          ? (taxAmount + variant.price) * exchangeRate
          : 0,
        inventories: variant.inventories,
        inventory_location:
          variant.inventories.length > 0
            ? [{ ...variant.inventories[0], quantity: 1 }]
            : [],
      });
    }
    return orderProducts;
  };

  const fetchProductData = () => {
    setCurrentPage(orderState.products.pagination.next_page);
  };

  return (
    <Fragment>
      <div className="form-row mb-3">
        <Modal
          size="lg"
          {...orderState.searchProductDialogProps.props}
          toggle={() => {
            closDialog();
          }}
        >
          <ModalHeader
            toggle={() => {
              closDialog();
            }}
          >
            All Products
          </ModalHeader>
          <ModalBody>
            <div className="form-row mb-3">
              <Col md="12 input-with-icon pr-1">
                <div>
                  <FormGroup className="m-0">
                    <i className="fa fa-search"></i>
                    <input
                      name="code"
                      type="text"
                      placeholder="Search Products"
                      defaultValue={searchValue}
                      onChange={(e) => {
                        setSearchValue(e.target.value ? e.target.value : "");
                      }}
                      className={`form-control`}
                    />
                  </FormGroup>
                </div>
              </Col>
            </div>
            <div className="form-row mb-3">
              <InfiniteScroll
                key={Date.now() + "scroll"}
                ref={(scroll) => {
                  infiniteScrollRef.current = scroll;
                }}
                dataLength={productData.length}
                next={fetchProductData}
                hasMore={orderState.products.pagination.has_more}
                refreshFunction={() => {
                  alert("Test");
                }}
                pullDownToRefresh={true}
                height={600}
                loader={<p>Loading...</p>}
              >
                <table
                  className="table"
                  style={{ width: "100%", height: 400, overflowY: "scroll" }}
                >
                  <tbody>
                    {productData.map((item, i) => (
                      <Fragment key={i + Date.now()}>
                        <tr>
                          <td style={{ width: "5%", height: 1 }}>
                            <input
                              type={"checkbox"}
                              defaultChecked={
                                getValues("ordered_products").findIndex(
                                  (x) => x.product_id === item.id
                                ) != -1
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                productOnChange(e, item, item.variants);
                              }}
                            />
                          </td>
                          <td style={{ width: "15%", textAlign: "center" }}>
                            <img width={20} src={item.url} />
                          </td>
                          <td style={{ textAlign: "left" }}>{item.name}</td>
                        </tr>
                        <tr>
                          <td
                            colSpan={3}
                            style={{
                              paddingTop: 0,
                              paddingRight: 25,
                              paddingLeft: 25,
                              border: "none",
                            }}
                          >
                            <table
                              className="table"
                              style={{ width: "100%", marginTop: 0 }}
                            >
                              <tbody>
                                {item.variants.map((itemVariant, v) => (
                                  <tr key={v}>
                                    <td style={{ width: "5%" }}>
                                      <input
                                        ref={(ref) => {
                                          variantRef.current[itemVariant.id] =
                                            ref;
                                        }}
                                        disabled={
                                          isAllowOutOfStock
                                            ? false
                                            : itemVariant.is_out_of_stock
                                        }
                                        defaultChecked={
                                          getValues(
                                            "ordered_products"
                                          ).findIndex(
                                            (x) =>
                                              x.variant ===
                                              itemVariant.variant_id
                                          ) != -1
                                            ? true
                                            : false
                                        }
                                        type={"checkbox"}
                                        onChange={(e) => {
                                          variantOnChange(e, item, itemVariant);
                                        }}
                                      />
                                    </td>

                                    <td
                                      style={{
                                        width: "50%",
                                        textAlign: "left",
                                      }}
                                    >
                                      {itemVariant.variant_name}
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                      {itemVariant.stock}
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                      $ {itemVariant.price}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </Fragment>
                    ))}
                  </tbody>
                </table>
              </InfiniteScroll>
            </div>
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col sm="12">
                <div className="float-sm-right ">
                  <button
                    onClick={() => {
                      if (onChangeDialog) {
                        onChangeDialog(true);
                      }
                      closDialog();
                    }}
                    className="btn  btn-primary"
                  >
                    {" "}
                    Add
                  </button>
                </div>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </div>
    </Fragment>
  );
};

function areEqual(prevProps, nextProps) {
  return (
    JSON.stringify(prevProps.isProductValueChange) ==
    JSON.stringify(nextProps.isProductValueChange)
  );
  /*
    return true if passing nextProps to render would return
    the same result as passing prevProps to render,
    otherwise return false
    */
}

export default React.memo(SearchProductModal, areEqual);
