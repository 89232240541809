import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Col,
  CardBody,
  Row,
  Label,
  Table,
  Form,
} from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { setSalesFulfillmentDialog } from "../../../store/salesOrderStatusSlice";
import {
  getCreateData,
  setFromSettings,
  addSalesOrderFulfillment,
} from "../../../store/saleOrderFulfillmentSlice";
import { showSalesOrderFulfillment } from "../../../store/salesOrderSlice";
import ButtonLoader from "../../../../../share-components/ui/buttonLoader";

const defaultValues = {
  id: "",
  ordered_products: [],
  tracking_url: "",
  tracking_id: "",
  delivery_partner: "",
  total_no_of_boxes: "",
  total_gross_weight: "",
  total_net_weight: "",
};

const schema = yup.object().shape({
  delivery_partner: yup
    .object()
    .shape({ id: yup.string() })
    .nullable()
    .required("You must select a delivery partner."),
  // tracking_url: yup
  //   .string()
  //   .matches(
  //     /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
  //     "Invalid url!"
  //   )
  //   .required("You must enter tracking url"),
  tracking_url: yup.string().url("Invalid url!").nullable(),
  tracking_id: yup.string().required("You must enter tracking id"),
  total_gross_weight: yup
    .string()
    .required("You must enter total gross weight"),
  total_net_weight: yup.string().required("You must enter total net weight"),
  total_no_of_boxes: yup.string().required("You must enter total no of boxes"),
});

const SalesFulfillmentModal = () => {
  const dispatch = useDispatch();
  const [statusName, setStatusName] = useState("");
  const [hasPendingItems, setHasPendingItems] = useState(false);
  const [productData, setProductData] = useState([]);
  const [buttonDisable, setButtonDisable] = useState(false);
  const authData = useSelector(({ authReducer }) => authReducer);
  const salesFulfillmentDialogProps = useSelector(
    (state) => state.order.saleOrderStatus.salesFulfillmentDialogProps
  );
  const salesOrderFulfillmentState = useSelector(
    ({ order }) => order.salesOrderFulfilment
  );

  const order_id = salesFulfillmentDialogProps.data.id;

  const toggleModal = () => {
    closeDialog();
  };

  const closeDialog = () => {
    const obj = { props: { isOpen: false }, type: "new", data: "" };
    dispatch(setSalesFulfillmentDialog(obj));
  };

  useEffect(() => {
    if (salesFulfillmentDialogProps.data) {
      setStatusName(salesFulfillmentDialogProps.data.status || "");
      // setButtonDisable(!hasPendingItems);
    }
  }, [salesFulfillmentDialogProps.data]);

  useEffect(() => {
    dispatch(getCreateData()).then((res) => {
      dispatch(setFromSettings(res.payload));
    });
  }, []);

  const {
    handleSubmit,
    formState,
    reset,
    control,
    register,
    setValue,
    trigger,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { errors, isDirty } = formState;

  const onSubmit = (data) => {
    setButtonDisable(true);
    const fulfillmentData = {
      sales_order_id: order_id,
      ...data,
      delivery_partner: data.delivery_partner?.id,
      ordered_products: productData.map(function (item, index) {
        return {
          id: item.id,
          sales_order_line_item_id: item.sales_order_line_item_id,
          quantity: item.pending_quantity,
        };
      }),
    };
    console.log(fulfillmentData);
    dispatch(addSalesOrderFulfillment({ ...fulfillmentData })).then((res) => {
      setButtonDisable(false);
      closeDialog();
      reset(defaultValues);
    });
  };

  useEffect(() => {
    if (order_id) {
      dispatch(showSalesOrderFulfillment(order_id))
        .then((res) => {
          const hasPendingItems = res?.payload?.length > 0;

          setProductData(res.payload);
          setHasPendingItems(hasPendingItems);
          setButtonDisable(!hasPendingItems);
        })
        .catch((error) => {
          console.error("Error fetching data for the modal:", error);
        });
    }
  }, [dispatch, order_id]);

  return (
    <Modal
      isOpen={salesFulfillmentDialogProps.props.isOpen}
      toggle={toggleModal}
      size="xl"
    >
      <ModalHeader toggle={toggleModal}>
        <p
          style={{ fontSize: "20px" }}
        >{`Sales Order #${salesFulfillmentDialogProps.data.order_no}`}</p>
        <span
          style={{
            fontWeight: "lighter",
            color: "rgb(115, 115, 115)",
            fontSize: "16px",
          }}
        >
          Status :{" "}
          <span
            style={{
              textTransform: "uppercase",
            }}
          >
            {statusName}
          </span>
        </span>
      </ModalHeader>

      <ModalBody>
        <CardBody className="py-0">
          {hasPendingItems ? (
            <Form noValidate="" onSubmit={handleSubmit(onSubmit)}>
              <Row className="py-2">
                <Col md={12}>
                  <h5>Tracking Details</h5>
                  <Row>
                    <Col md={4} className="mt-2">
                      <FormGroup>
                        <Label for="selectOption">
                          Select Delivery Partner
                        </Label>
                        <Select
                          placeholder="Select Partners"
                          type="select"
                          name="trackingName"
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          value={getValues("delivery_partner")}
                          isClearable
                          options={
                            salesOrderFulfillmentState.formSettings
                              .delivery_partners
                          }
                          onChange={(e) => {
                            setValue("delivery_partner", e);
                            trigger("delivery_partner");
                          }}
                          className={` ${
                            errors.delivery_partner ? "is-invalid" : ""
                          }`}
                        />
                        <div className="invalid-feedback">
                          {errors.delivery_partner?.message}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label className="col-form-label">Tracking Id</Label>
                        <input
                          className="form-control"
                          {...register("tracking_id")}
                        />
                        <div style={{ color: "red" }}>
                          {errors.tracking_id?.message}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label className="col-form-label">Tracking Url</Label>
                        <input
                          className="form-control"
                          {...register("tracking_url")}
                        />
                        <div style={{ color: "red" }}>
                          {errors.tracking_url?.message}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="py-2">
                <Col md={12}>
                  <h5>Products</h5>
                  <div
                    className="table-responsive w-100 mt-2"
                    style={{ maxHeight: "300px" }}
                  >
                    {productData && (
                      <Table>
                        <thead className="thead-light">
                          <tr>
                            <th style={{ width: "40%" }}>Product</th>
                            <th style={{ textAlign: "right", width: "20%" }}>
                              Order Qty
                            </th>
                            <th style={{ textAlign: "right", width: "20%" }}>
                              fulfilment Qty
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {productData.length > 0 ? (
                            productData.map((item, i) => (
                              <tr key={i}>
                                <td>{item.product.sku}</td>
                                <td style={{ textAlign: "right" }}>
                                  <div style={{ marginTop: "1rem" }}>
                                    {item.order_quantity}
                                  </div>
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  <div style={{ marginTop: "1rem" }}>
                                    {item.pending_quantity}
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="3" style={{ textAlign: "center" }}>
                                No pending items available.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    )}
                    {!productData && (
                      <div style={{ textAlign: "center" }}>Loading...</div>
                    )}
                  </div>
                </Col>
              </Row>

              <Row className="py-2">
                <Col md={12}>
                  <h5>Pick Up Details</h5>
                  <Row>
                    <Col md={4}>
                      <FormGroup>
                        <Label className="col-form-label">
                          Total No Of Boxes
                        </Label>
                        <input
                          className="form-control"
                          {...register("total_no_of_boxes")}
                        />
                        <div style={{ color: "red" }}>
                          {errors.total_no_of_boxes?.message}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label className="col-form-label">
                          Total Gross Weight
                        </Label>
                        <input
                          className="form-control"
                          {...register("total_gross_weight")}
                        />
                        <div style={{ color: "red" }}>
                          {errors.total_gross_weight?.message}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label className="col-form-label">
                          Total Net Weight
                        </Label>
                        <input
                          className="form-control"
                          {...register("total_net_weight")}
                        />
                        <div style={{ color: "red" }}>
                          {errors.total_net_weight?.message}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {hasPendingItems && (
                <Row>
                  <Col md={12}>
                    <div className="pull-right">
                      <ButtonLoader
                        disabled={buttonDisable}
                        color="primary"
                        value="1"
                        type="submit"
                        btntext="Save"
                      ></ButtonLoader>
                    </div>
                  </Col>
                </Row>
              )}
            </Form>
          ) : (
            <>
              <div className="text-center">
                <p>No pending items available. All items are fulfilled!</p>
              </div>
            </>
          )}
        </CardBody>
      </ModalBody>
    </Modal>
  );
};

export default SalesFulfillmentModal;
