import React, { Fragment, useEffect, useRef, useState } from "react";
import { Col, FormGroup } from "reactstrap";
import JqxTable from "../../../../../share-components/table/JqxTable";
import * as ReactDOMServer from "react-dom/server";
import { numberWithCurrency } from "../../../../../utils/common";
import { setInventoryDialog } from "../../../store/salesOrderSlice";
import { useDispatch } from "react-redux";
import InventoryLocation from "./inventoryLocation";
// import { shippingTaxAmount } from "../../setting/calculation";
import { setOrderItemDiscountDialog } from "../../../store/draftOrderSlice";
import OrderItemDiscount from "../../../draft-order/form/content/itemDiscount";

const SaleOrderProductJqx = (props) => {
  const {
    setValue,
    trigger,
    getValues,
    orderedProducts,
    id,
    onChangeDialog,
    currencyChange,
    isTax,
  } = props;
  const dispatch = useDispatch();
  let gridRef = useRef(null);
  const [tableKey, setTableKey] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [discountData, setDiscountData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const datafields = [
    { name: "id", type: "number" },
    { name: "variant", type: "number" },
    { name: "product_id", type: "number" },
    { name: "product_name", type: "string" },
    { name: "image", type: "string" },
    { name: "location", type: "string" },
    { name: "sku", type: "string" },
    { name: "variant_name", type: "string" },
    { name: "quantity", type: "number" },
    { name: "back_order_quantity", type: "number" },
    { name: "price", type: "number" },
    { name: "total_price", type: "number" },
    { name: "stock", type: "number" },
    { name: "original_price", type: "number" },
    { name: "item_discount", type: "array" },
    { name: "discount", type: "number" },
    { name: "amount", type: "number" },
    { name: "is_tax", type: "boolean" },
    { name: "tax_amount", type: "number" },
    { name: "gross_amount", type: "number" },
    { name: "inventories", type: "array" },
    { name: "inventory_location", type: "array" },
    { name: "commodity", type: "string" },
  ];

  const columns = [
    { datafield: "id", hidden: true },
    { datafield: "variant", hidden: true },
    { datafield: "total_price", hidden: true },
    { datafield: "tax_amount", hidden: true },
    { datafield: "commodity", hidden: true },
    { datafield: "inventory_location", hidden: true },
    { datafield: "back_order_quantity", hidden: true },
    {
      text: "Product",
      datafield: "product_name",
      editable: false,
      columntype: "text",
      width: "33%",
      cellsrenderer(row, columnfield, value, defaulthtml, columnproperties) {
        const productDetails = getValues("ordered_products")[row];
        const html = ReactDOMServer.renderToString(
          <div style={{ textAlign: "center", padding: 5 }}>
            {productDetails && (
              <div className="row">
                <div className="col-md-3">
                  <img
                    className="img-fluid"
                    src={productDetails.image}
                    width="40"
                    alt=""
                  />
                </div>
                <div className="col-md-9">
                  <div className="row">
                    <p className={"font-primary"}>{value}</p>
                  </div>
                  {/* <div className="row">
                    <p>{productDetails.variant_name}</p>
                  </div> */}
                  <div className="row">
                    <p
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        textDecoration: "underline",
                      }}
                    >
                      {productDetails.sku}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
        return html;
      },
    },
    {
      text: "Available/Back Ordered",
      datafield: "stock",
      editable: false,
      columntype: "text",
      width: "14%",
      cellsalign: "right",
      align: "center",
      cellsrenderer(
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        rowdata
      ) {
        const productDetails = getValues("ordered_products")[row];
        const html = ReactDOMServer.renderToString(
          <div style={{ textAlign: "center", padding: 5 }}>
            {productDetails && (
              <div className="row">
                <div className="col-12">
                  <div className="col-md-12 p-1">
                    <div className="row">
                      <div className="col-4">
                        <p className="font-primary text-left font-weight-bolder">
                          Available
                        </p>
                      </div>
                      <div className="col-8">
                        <p className="text-right">{rowdata.stock}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <p className="font-primary text-left font-weight-bolder">
                          Back Qty
                        </p>
                      </div>
                      <div className="col-8">
                        <p className="text-right">
                          {rowdata.back_order_quantity}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
        return html;
      },
    },
    {
      text: "Quantity",
      datafield: "quantity",
      editable: true,
      columntype: "text",
      width: "6%",
      cellsalign: "right",
      align: "right",
      cellclassname: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        rowdata
      ) {
        return "editable-column";
      },
    },
    {
      text: "Location",
      datafield: "location",
      editable: false,
      columntype: "text",
      width: "14%",
      cellsalign: "left",
      align: "left",
      cellsrenderer(
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        rowData
      ) {
        const inventoryLocationLength = rowData.inventory_location.length;
        let inventory =
          inventoryLocationLength > 0
            ? rowData.inventory_location[0].name +
              " : " +
              rowData.inventory_location[0].quantity
            : "";
        if (inventoryLocationLength > 1) {
          inventory += ", ....";
        }
        const html = ReactDOMServer.renderToString(
          <div style={{ textAlign: "center", padding: 5, marginTop: 10 }}>
            <p style={{ whiteSpace: "pre-line" }}>{inventory}</p>
          </div>
        );
        return html;
      },
      // cellsformat: 'c2',
    },
    {
      text: "Rate",
      datafield: "price",
      editable: true,
      columntype: "text",
      width: "5%",
      cellsalign: "right",
      cellsformat: "c2",
      align: "right",
      validation: function (cell, value) {
        if (Number(value) < 1) {
          return { result: false, message: "amount should be grater than 0" };
        } else {
          return true;
        }
      },
    },
    // {
    //   text: "Tax",
    //   datafield: "is_tax",
    //   editable: true,
    //   columntype: "checkbox",
    //   width: "4%",
    //   cellsalign: "center",
    //   cellsformat: "c2",
    //   align: "center",
    // },
    {
      text: "Amount",
      datafield: "gross_amount",
      editable: false,
      columntype: "text",
      width: "19%",
      height: "100px",
      cellsalign: "center",
      // cellsformat: 'c2',
      align: "center",
      cellsrenderer(
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        rowdata
      ) {
        const productDetails = getValues("ordered_products")[row];
        const html = ReactDOMServer.renderToString(
          <div style={{ textAlign: "center", padding: 5 }}>
            {productDetails && (
              <div className="row">
                <div className="col-12">
                  <div className="col-md-12 p-1">
                    <div className="row">
                      <div className="col-12">
                        <p className="font-primary text-center font-weight-bolder">
                          {/* Sub / Tax{" "} */}
                          Sub / Discount
                        </p>
                      </div>
                      <div className="col-12">
                        <p className="text-center">
                          {numberWithCurrency(
                            rowdata.total_price,
                            getValues("currency").symbol
                          )}{" "}
                          {/* /{" "}
                          {numberWithCurrency(
                            rowdata.tax_amount,
                            getValues("currency").symbol
                          )}{" "} */}
                          {rowdata.item_discount ? (
                            <>
                              /
                              {numberWithCurrency(
                                rowdata.discount,
                                getValues("currency").symbol
                              )}
                            </>
                          ) : <> / 0.00</>}
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <p className="font-primary text-center font-weight-bolder">
                          Gross :{" "}
                          {numberWithCurrency(
                            rowdata.gross_amount,
                            getValues("currency").symbol
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
        return html;
      },
    },
  ];

  const getJqxRef = (jqx) => {
    if (jqx) {
      gridRef = jqx;
      if (gridRef && gridRef.removeEventListener) {
        gridRef.removeEventListener("cellvaluechanged", handleCellValueChanged);
      }

      if (gridRef && gridRef.addEventListener) {
        gridRef.addEventListener("cellvaluechanged", handleCellValueChanged);
      }

      setTimeout(() => {
        if (gridRef) {
          const myEle = document.getElementById(gridRef._id);
          if (myEle) {
            gridRef.updatebounddata();
          }
        }
      }, 1000);
    }
  };
  const onDelete = (data) => {
    setDataToHookForm();
  };
  useEffect(
    function () {
      if (currencyChange) {
        const gridInformation = gridRef.getdatainformation();
        for (let i = 0; i < gridInformation.rowscount; i++) {
          const rowData = gridRef.getrowdata(i);
          const originalPrice = rowData.original_price;
          const quantity = rowData.quantity;
          const singlePrice = originalPrice * Number(currencyChange);
          const isTax = rowData.is_tax;

          setTimeout(
            function () {
              gridRef.setcellvalue(i, "price", singlePrice);
              // setDataToHookForm();
              setTotalAmount(i, quantity, singlePrice, isTax);
              setDataToHookForm(true);
            },
            1000,
            false
          );
        }
        setTimeout(() => {
          const key = tableKey + 1;
          setTableKey(key);
        }, 1000);
      }
    },
    [currencyChange]
  );

  const handleCellValueChanged = (event) => {
    const row = event.args.rowindex;
    const column = event.args.datafield;

    if (column === "item_discount") {
      setTotalAmount(
        row,
        gridRef.getcellvalue(row, "quantity"),
        gridRef.getcellvalue(row, "price"),
        gridRef.getcellvalue(row, "is_tax")
      );
    }
  };

  useEffect(
    function () {
      if (isTax) {
        const taxString = isTax.split("-");
        const gridInformation = gridRef.getdatainformation();
        for (let i = 0; i < gridInformation.rowscount; i++) {
          const rowData = gridRef.getrowdata(i);
          const originalPrice = rowData.original_price;
          const quantity = rowData.quantity;
          const singlePrice = rowData.price;
          const tax = taxString[1] == "1" ? true : false;

          setTimeout(
            function () {
              gridRef.setcellvalue(i, "is_tax", tax);
              // setDataToHookForm();
              setTotalAmount(i, quantity, singlePrice, tax);
              setDataToHookForm(true);
            },
            1000,
            false
          );
        }
      }
    },
    [isTax]
  );

  useEffect(() => {
    if (gridRef) {
      const filteredData = getValues("ordered_products").filter((item) =>
        item.sku.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredData(filteredData);
      setTimeout(() => {
        gridRef.clear();
        gridRef.addrow(null, filteredData);
      }, 0);
    }
  }, [searchValue, gridRef, getValues]);

  const cellEdit = (event) => {
    const rowArgs = event.args;
    if (rowArgs.datafield == "quantity") {
      const stock = gridRef.getcellvalue(rowArgs.rowindex, "stock");
      let quantity = Number(rowArgs.value);
      if (Number(stock) < Number(quantity)) {
        gridRef.setcellvalue(
          rowArgs.rowindex,
          "back_order_quantity",
          Number(quantity) - Number(stock)
        );
        gridRef.setcellvalue(
          rowArgs.rowindex,
          rowArgs.datafield,
          Number(stock)
        );
        setTimeout(function () {
          gridRef.setcellvalue(
            rowArgs.rowindex,
            rowArgs.datafield,
            Number(stock)
          );
        }, 1000);
        quantity = Number(stock);
      } else {
        // gridRef.setcellvalue(
        //   rowArgs.rowindex,
        //   rowArgs.datafield,
        //   rowArgs.value
        // );

        gridRef.setcellvalue(
          rowArgs.rowindex,
          rowArgs.datafield,
          rowArgs.value
        );

        if (Number(rowArgs.value) <= Number(stock)) {
          gridRef.setcellvalue(rowArgs.rowindex, "back_order_quantity", 0);
        }
      }

      setInventoryLocations(
        rowArgs.rowindex,
        rowArgs.value,
        rowArgs.row.inventories
      );
      const singlePrice = gridRef.getcellvalue(rowArgs.rowindex, "price");
      const isTax = gridRef.getcellvalue(rowArgs.rowindex, "is_tax");
      setTotalAmount(rowArgs.rowindex, quantity, singlePrice, isTax);
    }

    if (rowArgs.row.item_discount) {
      let discount = Number(rowArgs.value);
      const singlePrice = gridRef.getcellvalue(rowArgs.rowindex, "price");
      const quantity = gridRef.getcellvalue(rowArgs.rowindex, "quantity");
      const taxAmount = gridRef.getcellvalue(rowArgs.rowindex, "tax_amount");
      const isTax = gridRef.getcellvalue(rowArgs.rowindex, "is_tax");

      if (
        rowArgs.row.item_discount &&
        rowArgs.row.item_discount.discount_type == 2
      ) {
        const amount =
          singlePrice * quantity + taxAmount * (1 - discount / 100);
        gridRef.setcellvalue(rowArgs.rowindex, "gross_amount", amount);
      } else {
        const discountedPrice = singlePrice * quantity + taxAmount - discount;
        gridRef.setcellvalue(rowArgs.rowindex, "gross_amount", discountedPrice);
        // setTotalAmount(rowArgs.rowindex, quantity, discountedPrice, isTax);
      }
      setTotalAmount(rowArgs.rowindex, quantity, singlePrice, isTax);
    }
    if (rowArgs.datafield == "is_tax") {
      const singlePrice = gridRef.getcellvalue(rowArgs.rowindex, "price");
      const quantity = gridRef.getcellvalue(rowArgs.rowindex, "quantity");
      setTotalAmount(rowArgs.rowindex, quantity, singlePrice, rowArgs.value);
    }
    if (rowArgs.datafield == "price") {
      const quantity = gridRef.getcellvalue(rowArgs.rowindex, "quantity");
      const isTax = gridRef.getcellvalue(rowArgs.rowindex, "is_tax");
      const finalPrice = rowArgs.row.item_discount
        ? gridRef.getcellvalue(rowArgs.rowindex, "price")
        : rowArgs.value;

      // if (isChecked) {
      if (
        rowArgs.row.item_discount &&
        rowArgs.row.item_discount?.discount_type == 2
      ) {
        const amount = finalPrice * quantity;
        gridRef.setcellvalue(rowArgs.rowindex, "gross_amount", amount);
      } else {
        setTotalAmount(rowArgs.rowindex, quantity, finalPrice, isTax);
      }
    }
    if (
      rowArgs.datafield != "quantity" &&
      rowArgs.datafield != "item_discount" &&
      rowArgs.datafield != "price"
    ) {
      gridRef.setcellvalue(rowArgs.rowindex, rowArgs.datafield, rowArgs.value);
    }
    setDataToHookForm(true);
  };

  const setInventoryLocations = (index, quantity, locations) => {
    const inventoryLocation = [];
    if (locations.length > 0) {
      locations.map(function (item, index) {
        const availableItem = Number(item.available);
        const locationQuantity =
          availableItem - quantity > 0 ? quantity : availableItem;
        if (quantity > 0) {
          inventoryLocation.push({
            location_id: item.location_id,
            name: item.name,
            quantity: locationQuantity,
          });
        }
        quantity = quantity - locationQuantity;
      });
    } else {
      quantity = 0;
    }

    gridRef.setcellvalue(index, "inventory_location", inventoryLocation);
  };

  const setTotalAmount = (index, quantity, singlePrice, isVat) => {
    const back_order_quantity = gridRef.getcellvalue(
      index,
      "back_order_quantity"
    );
    const itemDiscount = gridRef.getcellvalue(index, "item_discount");
    let discountAmount = 0;

    quantity = quantity + back_order_quantity;
    const totalPrice = quantity * singlePrice;
    const taxAmount = isVat ? totalPrice * 0.2 : 0;
    if (itemDiscount && itemDiscount.length > 0) {
      const discountValue = itemDiscount.value;
      if (itemDiscount.discount_type === 2) {
        // Percentage discount
        discountAmount = (totalPrice + taxAmount) * (discountValue / 100);
      } else {
        // Amount discount
        discountAmount = discountValue;
      }
    }

    gridRef.setcellvalue(index, "total_price", totalPrice);
    gridRef.setcellvalue(index, "tax_amount", taxAmount);
    gridRef.setcellvalue(
      index,
      "gross_amount",
      taxAmount + totalPrice - discountAmount
    );
  };
  const setDataToHookForm = (isTrigger = true) => {
    let orderData = [];
    const gridInformation = gridRef.getdatainformation();
    let taxTotal = 0;
    let itemDiscount = 0;
    for (let i = 0; i < gridInformation.rowscount; i++) {
      const rowData = gridRef.getrowdata(i);

      taxTotal += rowData.tax_amount;
      if (rowData.item_discount && rowData.item_discount.length > 0) {
        itemDiscount += rowData.item_discount.value;
      }
      orderData.push(rowData);
    }
    if (taxTotal) {
      // const shippingTax = shippingTaxAmount(getValues);
      setValue("tax", {
        is_charge: true,
        rate_name: "Vat",
        percentage: taxTotal,
      });
    } else {
      setValue("tax", "");
    }
    setValue("ordered_products", orderData);
    onChangeDialog(false);
  };
  const cellClickFunction = (arg) => {
    if (arg.datafield === "location") {
      gridRef.endcelledit(arg.rowindex, "quantity");
      const rowData = arg.row.bounddata;
      const data = {
        variant_id: rowData.variant,
        sku: rowData.sku,
        inventories: rowData.inventories,
        inventory_location: rowData.inventory_location,
      };
      dispatch(setInventoryDialog({ props: { isOpen: true }, data: data }));
    }

    // if (arg.datafield === "item_discount") {
    //   setSelectedRowIndex(arg.rowindex);
    //   gridRef.endcelledit(arg.rowindex, "item_discount");
    //   const rowData = arg.row.bounddata;
    //   const data = {
    //     variant_id: rowData.variant,
    //     sku: rowData.sku,
    //     item_discount: rowData.item_discount,
    //   };
    //   dispatch(
    //     setOrderItemDiscountDialog({ props: { isOpen: true }, data: data })
    //   );
    // }

    if (arg.datafield === "product_name") {
      setSelectedRowIndex(arg.rowindex);
      gridRef.endcelledit(arg.rowindex, "item_discount");
      const rowData = arg.row.bounddata;
      const data = {
        variant_id: rowData.variant,
        sku: rowData.sku,
        item_discount: rowData.item_discount,
      };
      dispatch(
        setOrderItemDiscountDialog({ props: { isOpen: true }, data: data })
      );
    }
  };

  return (
    <Fragment>
      <div style={{ width: "100%" }}>
        {getValues("ordered_products").length > 0 && (
          <>
            <div style={{ marginBottom: 10 }}>
              <Col md="5 input-with-icon pr-1 pl-0">
                <div>
                  <FormGroup className="m-0">
                    <i className="fa fa-search"></i>
                    <input
                      name="search"
                      type="text"
                      placeholder="Search by SKU"
                      value={searchValue}
                      onChange={(event) => setSearchValue(event.target.value)}
                      className={`form-control`}
                    />
                  </FormGroup>
                </div>
              </Col>
            </div>
            <JqxTable
              key={tableKey}
              deleteAll
              rowsheight={80}
              datafields={datafields}
              columns={columns}
              // data={filteredData}
              data={getValues("ordered_products")}
              myGrid={gridRef}
              getJqxRef={getJqxRef}
              // autoheight={getValues("ordered_products").length < 7}
              autoheight={true}
              isDelete
              scrollerbar
              height="500"
              onCellendedit={cellEdit}
              disableCreateRow
              cellClickDataField={["location", "product_name"]}
              onDelete={onDelete}
              cellClickFunction={cellClickFunction}
              currency={getValues("currency").symbol}
              searchValue={searchValue}
            />
          </>
        )}
      </div>
      <InventoryLocation
        orderGetValues={getValues}
        orderSetValue={setValue}
        orderTrigger={trigger}
        onChangeDialog={onChangeDialog}
      ></InventoryLocation>
      <OrderItemDiscount
        orderGetValues={getValues}
        orderSetValue={setValue}
        orderTrigger={trigger}
        onChangeDialog={onChangeDialog}
      ></OrderItemDiscount>
    </Fragment>
  );
};

function areEqual(prevProps, nextProps) {
  const prev = {
    productChange: prevProps.isProductValueChange,
    currencyChange: prevProps.currencyChange,
    isTax: prevProps.isTax,
  };
  const next = {
    productChange: nextProps.isProductValueChange,
    currencyChange: nextProps.currencyChange,
    isTax: nextProps.isTax,
  };
  return JSON.stringify(prev) == JSON.stringify(next);
}

export default React.memo(SaleOrderProductJqx, areEqual);
