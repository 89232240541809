import API from "../../../utils/api";

export const getShippersApi = async (filters) => {
  const response = await API.get("order/settings/shipper", {
    params: filters,
  });
  return { response };
};

export const addShipperApi = async (location) => {
  const response = await API.post("order/settings/shipper", location);
  return { response };
};

export const updateShipperApi = async (location, id) => {
  const response = await API.put(`order/settings/shipper/${id}`, location);
  return { response };
};

export const deleteShipperApi = async (id) => {
  const response = await API.delete(`order/settings/shipper/${id}`);
  return { response };
};
