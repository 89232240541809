import {Nav, NavItem, NavLink} from "reactstrap";
import React, {Fragment, useEffect, useState} from "react";

export const UpdateSellerNavItems = (props) => {
    const {VerticleTab,setVerticleTab,errors}=props;
    const [primaryDetails,setPrimaryDetails]=useState(false);
    useEffect(() => {
        setPrimaryDetails(false);
        if(errors.name){
            setPrimaryDetails(true);
        }
    }, [{...errors}]);
    return (
        <Nav className="nav flex-column nav-pills">
            <NavItem>
                <NavLink href="#javascript" className={VerticleTab === '1' ? 'active-element' : ''} onClick={() => setVerticleTab('1')}>Primary Details  {primaryDetails?<i className="icofont icofont-warning-alt"></i>:""}</NavLink>
            </NavItem>
            <NavItem>
                <NavLink href="#javascript" className={VerticleTab === '2' ? 'active-element' : ''} onClick={() => setVerticleTab('2')}>Brand Details</NavLink>
            </NavItem>
            <NavItem>
                <NavLink href="#javascript" className={VerticleTab === '3' ? 'active-element' : ''} onClick={() => setVerticleTab('3')}>Seller & Store Description</NavLink>
            </NavItem>
            {/* <NavItem>
                <NavLink href="#javascript" className={VerticleTab === '4' ? 'active-element' : ''} onClick={() => setVerticleTab('4')}>Extra Information</NavLink>
            </NavItem> */}
            <NavItem>
                <NavLink href="#javascript" className={VerticleTab === '5' ? 'active-element' : ''} onClick={() => setVerticleTab('5')}>Heading & Links</NavLink>
            </NavItem>
            <NavItem>
                <NavLink href="#javascript" className={VerticleTab === '6' ? 'active-element' : ''} onClick={() => setVerticleTab('6')}>Customer Service Contact Details</NavLink>
            </NavItem>
            <NavItem>
                <NavLink href="#javascript" className={VerticleTab === '7' ? 'active-element' : ''} onClick={() => setVerticleTab('7')}>Returns Warehouse Address</NavLink>
            </NavItem>
            <NavItem>
                <NavLink href="#javascript" className={VerticleTab === '8' ? 'active-element' : ''} onClick={() => setVerticleTab('8')}>Shopify Details</NavLink>
            </NavItem>
            <NavItem>
                <NavLink href="#javascript" className={VerticleTab === '9' ? 'active-element' : ''} onClick={() => setVerticleTab('9')}>Upload Images</NavLink>
            </NavItem>
            <NavItem>
                <NavLink href="#javascript" className={VerticleTab === '10' ? 'active-element' : ''} onClick={() => setVerticleTab('10')}>Banner Images</NavLink>
            </NavItem>
        </Nav>
    );
}