import * as yup from "yup";


export const defaultValues = {
    id:'',
    trading_name: '',
    name: '',
    is_registered: false,
    country_id: '',
    phone_number:'',
    registration_number:'',
    vat_number:'',
    company_name:'',
    email:'',
    website:'',
    line_of_business:'',
    incorporated_date:'',
    address_line_1:'',
    address_line_2:'',
    town_1:'',
    state_1:'',
    postcode_1:'',
    address_country_1:'',
    shop_address_line_1:'',
    shop_address_line_2:'',
    shop_town_1:'',
    shop_state_1:'',
    shop_postcode_1:'',
    shop_address_country_1:'',
    address_line_2_1:'',
    address_line_2_2:'',
    town_2:'',
    state_2:'',
    postcode_2: '',
    address_2:'',
    address_country_2:'',
    contact_name:'',
    title:'',
    first_name:'',
    last_name:'',
    designation:'',
    mobile:'',
    whatssapp:'',
    contact_email:'',
    confirm_email:'',
    seller_brands:'',
    sell_brands:'',
    new_style_duration:'',
    sell_duration:'',
    target_audience:'',
    business_model:'',
    manufacturing_pattern:'',
    other_marketplace:'',
    women_categories:'',
    men_categories:'',
    children_categories:'',
    gift:'',
    spend_per_piece:'',
    monthly_sales:'',
    how_know:'',
    news_letter:'',
    buyer_type:'',
    is_vattable:'',
    physical_store:'',
    sourceReason:'',
    terms:'',
};