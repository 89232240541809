import API from "../../utils/api";

export const getProductGroupsApi = async (filters) => {
  const response = await API.get("product-group", {
    params: filters,
  });
  return { response };
};

export const addProductGroupApi = async (location) => {
  const response = await API.post("product-group", location);
  return { response };
};

export const updateProductGroupApi = async (location, id) => {
  const response = await API.put(`product-group/${id}`, location);
  return { response };
};

export const deleteProductGroupApi = async (id) => {
  const response = await API.delete(`product-group/${id}`);
  return { response };
};
