import React, { Fragment, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { NotFoundData } from "../../../../constant";
import ProductView from "./productView";
import { useDispatch } from "react-redux";
import {
  setMediaDialog,
  setProductDialog,
  setProductFilter,
} from "../../store/prodcutSlice";
import { getProductRibbonColor } from "./../../../../utils/common";

const CardListing = (props) => {
  const {
    data,
    totalCount,
    pageChange,
    currentPage,
    productState,
    history,
    onPageSizeChange,
    selectProductRef,
    selectedProductIds,
    setselectedProductIds,
  } = props;
  const dispatch = useDispatch();
  const singleProductChange = (e, id) => {
    const selectedProductArray = [...selectedProductIds];
    let productIndex = selectedProductArray.findIndex((aItem) => aItem === id);
    if (productIndex != -1) {
      if (!e.target.checked) {
        selectedProductArray.splice(productIndex, 1);
      }
    } else {
      selectedProductArray.push(id);
    }
    setselectedProductIds(selectedProductArray);
  };

  return (
    <>
      {data.length < 1 ? (
        <div className="search-not-found text-center">
          <div>
            <img
              className="img-fluid second-search"
              src={require("../../../../assets/images/search-not-found.png")}
              alt=""
            />
            <p>{NotFoundData}</p>
          </div>
        </div>
      ) : (
        <Row className="gridRow">
          {data.map((item, i) => (
            <div
              className={`col-sm-${productState.filter.gridColumnNo}  col-grid-box`}
              key={i}
            >
              <Card>
                <div className="search-not-found text-center">
                  <div>
                    <img className="img-fluid second-search" alt="" />
                    <p></p>
                  </div>
                </div>
                <div className="product-box">
                  <div className="product-img">
                    <span
                      className={`ribbon  ${getProductRibbonColor(
                        item.status
                      )}`}
                    >
                      {item.brand}

                    </span>
                    <div
                      className={`image-crop-center card-height-${productState.filter.gridColumnNo}`}
                    >
                      <img
                        className="img-fluid scale-image-8"
                        src={item.url}
                        alt=""
                      />
                    </div>
                    <div className="product-hover">
                      <ul>
                        <li
                          onClick={() => {
                            let obj = {
                              props: { isOpen: true },
                              data: { id: item.id },
                            };
                            dispatch(setProductDialog(obj));
                          }}
                        >
                          <Button color="default" data-toggle="modal">
                            <i className="icon-eye"></i>
                          </Button>
                        </li>
                        <li>
                          <Link
                            to={`${process.env.PUBLIC_URL}/product/${item.id}/edit`}
                          >
                            <i className="icon-pencil"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="product-details">
                    <Row>
                      <Col md={12}>
                        <h4
                          onClick={() => {}}
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            width: "100%",
                            whiteSpace: "nowrap",
                          }}
                          className="font-primary"
                        >
                          <a
                            style={{ textDecoration: "none", color: "#3E4A56" }}
                            href={item.product_url}
                            target="_blank"
                          >
                            {item.name}{" "}
                          </a>
                        </h4>
                      </Col>
                    </Row>
                    <Row className="mt-1">
                      <Col md={8}>
                        <p
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            width: "100%",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {item.code}
                        </p>
                      </Col>
                      <Col md={4} className="text-right">
                        <input
                          ref={(ref) => {
                            selectProductRef.current[item.id] = ref;
                          }}
                          type="checkbox"
                          onChange={(e) => {
                            singleProductChange(e, item.id);
                          }}
                          checked={
                            selectedProductIds.findIndex(
                              (aItem) => aItem === item.id
                            ) != -1
                              ? true
                              : false
                          }
                        />
                      </Col>
                    </Row>

                    <Row className="mt-1">
                      <Col md={productState.filter.gridColumnNo == 2 ? 12 : 6}>
                        <Row>
                          <Col md={12}>
                            <div className="product-price">
                              {"Stocks : "} {item.stock}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            {item.selling_type != 0 && (
                              <div className="f-12">
                                {item.selling_type != 3 && (
                                  <>
                                    {" "}
                                    {"Pack : "} {item.pack_stock}{" "}
                                  </>
                                )}
                                {item.selling_type == 1 && <> | </>}
                                {item.selling_type != 2 && (
                                  <>
                                    {"Single : "} {item.single_Stock}
                                  </>
                                )}
                              </div>
                            )}
                          </Col>
                        </Row>
                      </Col>
                      <Col md={productState.filter.gridColumnNo == 2 ? 12 : 6}>
                        <div className="product-price text-right">
                          <>{item.sale_price ? "$" + item.sale_price : item.price ? "$" + item.price : ""}</>
                          {/* {item.sale_price ? ( */}
                          {/* <> */}
                          {/* <del> {item.price ? '$' + item.price : ""}</del> */}
                          {/* <span className="ml-1">
                                {" "}
                                {item.sale_price ? "$" + item.sale_price : ""}
                              </span> */}
                          {/* </> */}
                          {/* ) : ( */}
                          {/* <>{item.price ? "$" + item.price : ""}</> */}
                          {/* )} */}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div style={{ fontSize: 12 }}></div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card>
            </div>
          ))}
        </Row>
      )}
      <Row>
        <Col md={12}>
          <div style={{ display: "inline-flex", float: "right" }}>
            <div className="mr-1">
              <Input
                type="select"
                name="select"
                onChange={onPageSizeChange}
                className="form-control digits"
                defaultValue={productState.filter.pageSize}
              >
                <option value="12">12</option>
                <option value="24">24</option>
                <option value="48">48</option>
                <option value="96">96</option>
              </Input>
            </div>
            <div>
              <ReactPaginate
                className="pagination"
                activeClassName="pagination-active"
                breakLabel="..."
                nextLabel="next >"
                onPageChange={pageChange}
                pageRangeDisplayed={5}
                pageCount={totalCount}
                forcePage={currentPage}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </Col>
      </Row>
      <ProductView></ProductView>
    </>
  );
};
export default CardListing;
