import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Badge,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import Select from "react-select";

import DatePicker from "react-datepicker";
import moment from "moment";

import { useDispatch } from "react-redux";
import {
  getSubCategories,
  setProductSubCategory,
} from "../../../store/prodcutSlice";
import "react-tagsinput/react-tagsinput.css";
import { mapIdSearchAutoSuggestion } from "../../../../../utils/mapper";
import { taxonomySuggestionApi } from "../../../../../services/manage-product/productService";
import SunRichEditor from "../../../../../share-components/ui/sunRichEditor";
import CustomTagsInput from "../../../../../share-components/ui/customTagsInput";

const editorConfiguration = {
  toolbar: [
    "bold",
    "italic",
    "bulletedList",
    "numberedList",
    "fontColor",
    "fontBackgroundColor",
  ],
};

const General = (props) => {
  const {
    errors,
    register,
    setValue,
    trigger,
    getValues,
    productState,
    currentUser,
    productStates,
  } = props;
  const [tags, setTags] = useState([]);
  let styleRef = useRef([]);
  const dispatch = useDispatch();

  const [val, setVal] = useState("");
  const combineTags = () => {
    let tag = [];
    tag.push(getValues("product_type").name);

    if (getValues("product_category")) {
      tag.push(getValues("product_category").name);
    }
    if (getValues("product_sub_category")) {
      tag.push(getValues("product_sub_category").name);
    }
    if (getValues("country")) {
      tag.push(getValues("country").name);
    }
    if (getValues("season")) {
      tag.push(getValues("season").name);
    }
    if (getValues("product_group")) {
      tag.push(getValues("product_group").name);
    }
    getValues("tags").map((item, i) => {
      tag.push(item.name);
    });
    getValues("styles").map((item, i) => {
      tag.push(item.name);
    });
    getValues("custom_tags").map((item, i) => {
      tag.push(item);
    });
    setTags(tag);
  };
  useEffect(() => {
    combineTags();
    if (!getValues("meta_description")) {
      setMetaDescription();
    }
    if (!getValues("meta_title")) {
      setMetaTitle();
    }
  }, []);

  const nameAvailableCharacters = () => {
    const brandLength = brandLengthCharacters();
    const nameLength = getValues("name").length;
    return brandLength + nameLength;
  };
  const brandLengthCharacters = () => {
    return (getValues("brand")?.name).length;
  };
  const setMetaTitle = () => {
    const word = currentUser.modules.includes("is-meta-whole-sale")
      ? "Wholesale"
      : getValues("brand")?.name;
    setValue("meta_title", word + " " + getValues("name"));
    trigger("meta_title");
  };
  const setMetaDescription = () => {
    const regex = /<[^>]+>/g;
    const newString = getValues("summary")
      ? getValues("summary").replace(regex, "").substring(0, 250)
      : "";
    const str = newString.replace(/&nbsp;/g, "");

    const final = str.toString().trimStart();

    setValue("meta_description", final);
    trigger("meta_description");
  };
  const typePromiseOption = (inputValue) =>
    new Promise((resolve) => {
      const data = {
        value: inputValue,
      };
      setTimeout(() => {
        resolve(mapIdSearchAutoSuggestion(taxonomySuggestionApi(data)));
      });
    });

  return (
    <Fragment>
      <Card>
        <CardBody>
          <div className="form-row mb-3">
            <Col md="3">
              <FormGroup>
                <Label className="col-form-label">Brand</Label>
                <Select
                  getOptionLabel={(option) => option.name}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 100 }) }}
                  defaultValue={getValues("brand")}
                  getOptionValue={(option) => option.id}
                  {...register("brand")}
                  onChange={(e) => {
                    setValue("brand", e);
                    setMetaTitle();
                    trigger("brand");
                  }}
                  className={` ${errors.brand ? "is-invalid" : ""}`}
                  options={productState.brands}
                />

                <div className="invalid-feedback">{errors.brand?.message}</div>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label className="col-form-label">Product Title </Label>
                <input
                  name="name"
                  type="text"
                  {...register("name")}
                  onKeyDown={(e) => {
                    trigger("name");
                  }}
                  onBlur={(e) => {
                    setMetaTitle();
                  }}
                  className={`form-control ${errors.name ? "is-invalid" : ""}`}
                />
                <div className="invalid-feedback">{errors.name?.message}</div>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label className="col-form-label">Style Number</Label>
                <input
                  name="code"
                  type="text"
                  {...register("code")}
                  className={`form-control ${errors.code ? "is-invalid" : ""}`}
                />
                <div className="invalid-feedback">{errors.code?.message}</div>
              </FormGroup>
            </Col>
          </div>
          <div className="form-row mb-3">
            <Col md="12">
              <Label className="col-form-label">Product Summary</Label>
              <input
                type="text"
                className="form-control"
                defaultValue={getValues("summary")}
                maxLength={250}
                onChange={(e) => {
                  setValue("summary", e.target.value);
                  setMetaDescription();
                }}
              />
              <small className="form-text text-muted">
                {getValues("summary")?.length || 0}/250 characters
              </small>
              <div className="invalid-feedback"></div>
              <div className="invalid-feedback"></div>
            </Col>
          </div>
          <div className="form-row mb-3">
            <Col md="12">
              <Label className="col-form-label">Product Description</Label>
              <SunRichEditor
                height={200}
                defaultValue={getValues("description")}
                onChange={(content) => {
                  setValue("description", content);
                //   setMetaDescription();
                }}
              ></SunRichEditor>

              <div className="invalid-feedback"></div>
            </Col>
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <div className="form-row mb-3">
            <Col md="3">
              <FormGroup>
                <Label className="col-form-label">Product Type</Label>
                <Select
                  getOptionLabel={(option) => option.name}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 100 }) }}
                  defaultValue={getValues("product_type")}
                  getOptionValue={(option) => option.id}
                  {...register("product_type")}
                  onChange={(e) => {
                    setValue("product_type", e);
                    trigger("product_type");
                    combineTags();
                  }}
                  className={` ${errors.product_type ? "is-invalid" : ""}`}
                  options={productState.types}
                />

                <div className="invalid-feedback">
                  {errors.product_type?.message}
                </div>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label className="col-form-label">Product Category</Label>
                <Select
                  getOptionLabel={(option) => option.name}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 100 }) }}
                  defaultValue={getValues("product_category")}
                  getOptionValue={(option) => option.id}
                  {...register("product_category")}
                  onChange={(e) => {
                    setValue("product_category", e);
                    trigger("product_category");
                    combineTags();
                    dispatch(getSubCategories(e.id)).then((res) => {
                      dispatch(setProductSubCategory(res.payload));
                    });
                  }}
                  className={` ${errors.product_category ? "is-invalid" : ""}`}
                  options={productState.categories}
                />

                <div className="invalid-feedback">
                  {errors.product_category?.message}
                </div>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label className="col-form-label">Product Sub Category</Label>
                <Select
                  getOptionLabel={(option) => option.name}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 100 }) }}
                  defaultValue={getValues("product_sub_category")}
                  getOptionValue={(option) => option.id}
                  {...register("product_sub_category")}
                  onChange={(e) => {
                    setValue("product_sub_category", e);
                    setValue("styles", []);
                    trigger("product_sub_category");

                    combineTags();
                  }}
                  className={` ${
                    errors.product_sub_category ? "is-invalid" : ""
                  }`}
                  options={productState.sub_categories}
                />

                <div className="invalid-feedback">
                  {errors.product_sub_category?.message}
                </div>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label className="col-form-label">Product Group</Label>
                <Select
                  getOptionLabel={(option) => option.name}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 100 }) }}
                  defaultValue={getValues("product_group")}
                  getOptionValue={(option) => option.id}
                  {...register("product_group")}
                  onChange={(e) => {
                    setValue("product_group", e);
                    trigger("product_group");
                    combineTags();
                  }}
                  className={` ${errors.product_group ? "is-invalid" : ""}`}
                  options={productState.product_groups}
                />

                <div className="invalid-feedback">
                  {errors.product_group?.message}
                </div>
              </FormGroup>
            </Col>
          </div>

          <div className="form-row mb-3">
            <Col md="5">
              <div className="form-row ">
                <Col md="3">
                  <FormGroup>
                    <Label className="col-form-label">Tag Type</Label>
                    <Select
                      getOptionLabel={(option) => option.name}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 100 }),
                      }}
                      defaultValue={getValues("tag_type")}
                      getOptionValue={(option) => option.id}
                      {...register("tag_type")}
                      onChange={(e) => {
                        setValue("tag_type", e);
                        trigger("tag_type");
                      }}
                      className={` ${errors.tag_type ? "is-invalid" : ""}`}
                      options={productState.tag_settings}
                    />

                    <div className="invalid-feedback">
                      {errors.tag_type?.message}
                    </div>
                  </FormGroup>
                </Col>
                <Col md="9">
                  <FormGroup>
                    <Label className="col-form-label">Product Tags</Label>
                    <Select
                      getOptionLabel={(option) => option.name}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 100 }),
                      }}
                      defaultValue={getValues("tags")}
                      isMulti
                      getOptionValue={(option) => option.id}
                      {...register("tags")}
                      onChange={(e) => {
                        setValue("tags", e);
                        trigger("tags");
                        combineTags();
                      }}
                      className={` ${errors.tags ? "is-invalid" : ""}`}
                      options={productState.tags}
                    />

                    <div className="invalid-feedback">
                      {errors.tags?.message}
                    </div>
                  </FormGroup>
                </Col>
              </div>
            </Col>
            <Col md="3">
              <Label className="col-form-label">Related Product Handle</Label>
              <div>
                <div className="input-group">
                  <CustomTagsInput
                    value={getValues("custom_tags")}
                    tagChangeEvent={(value) => {
                      setValue("custom_tags", value);
                      combineTags();
                    }}
                  ></CustomTagsInput>
                </div>
              </div>
              <div className="invalid-feedback">
                {errors.custom_tags?.message}
              </div>
            </Col>
            <Col md="2">
              <FormGroup>
                <Label className="col-form-label">Season</Label>
                <Select
                  getOptionLabel={(option) => option.name}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 100 }),
                  }}
                  defaultValue={getValues("season")}
                  getOptionValue={(option) => option.id}
                  {...register("season")}
                  onChange={(e) => {
                    setValue("season", e);
                    trigger("season");
                    combineTags();
                  }}
                  className={` ${errors.season ? "is-invalid" : ""}`}
                  options={productState.seasons}
                />

                <div className="invalid-feedback">{errors.season?.message}</div>
              </FormGroup>
            </Col>
            <Col md="2">
              <Label className="col-form-label">Year</Label>
              <FormGroup>
                <DatePicker
                  name="manufactured_year"
                  className={`form-control digits ${
                    errors.manufactured_year ? "is-invalid" : ""
                  }`}
                  selected={
                    getValues("manufactured_year")
                      ? new Date(getValues("manufactured_year"))
                      : ""
                  }
                  onChange={(date) => {
                    setValue("manufactured_year", moment(date).format("YYYY"));
                    trigger("manufactured_year");
                  }}
                  showYearPicker
                  style={{ minHeight: "auto" }}
                  dateFormat="yyyy"
                />
              </FormGroup>
            </Col>
          </div>
          <div className="form-row mb-3"></div>
          <div className="form-row mb-3">
            <Col md="12">
              <Label className="col-form-label">Styles</Label>
              <div>
                <span>
                  Selecting styles that apply to your product makes it easier
                  for customers to find it.{" "}
                  {currentUser.role.id != 1 ? "Please select up to 3." : ""}
                </span>
              </div>
              <div className="mt-3">
                <Col md="12">
                  <div className="form-row mb-3">
                    {productState.styles
                      .filter(function (value, index) {
                        return (
                          value.category.filter(function (v, i) {
                            return (
                              v.id == getValues("product_sub_category")?.id
                            );
                          }).length > 0
                        );
                      })
                      .map((item, i) => (
                        <Col md="3" key={item.id}>
                          <div className=" ">
                            <input
                              id={`inline-${i}`}
                              type="checkbox"
                              ref={(ref) => {
                                styleRef.current[i] = ref;
                              }}
                              value={JSON.stringify(item)}
                              onChange={(e) => {
                                const isLimit =
                                  currentUser.role.id != 1
                                    ? getValues("styles").length < 3
                                    : true;

                                let currentStyle = JSON.parse(e.target.value);
                                let styles = getValues("styles");
                                let styleIndex = styles.findIndex(
                                  (x) => x.id === currentStyle.id
                                );
                                if (styleIndex != "-1") {
                                  if (!e.target.checked) {
                                    styles.splice(styleIndex, 1);
                                  }
                                } else {
                                  if (isLimit) {
                                    styles.push({
                                      id: currentStyle.id,
                                      name: currentStyle.name,
                                    });
                                  } else {
                                    styleRef.current[i].checked = false;
                                  }
                                }
                                setValue("styles", styles);
                                combineTags();
                              }}
                              defaultChecked={
                                getValues("styles").findIndex(
                                  (x) => x.id === item.id
                                ) != -1
                                  ? true
                                  : false
                              }
                            />
                            <Label for={`inline-${i}`} className="ml-2">
                              {item.name}
                            </Label>
                          </div>
                        </Col>
                      ))}
                  </div>
                </Col>
              </div>
            </Col>
          </div>
          <div className="form-row mb-3">
            <Col md="12">
              <Label className="col-form-label">Tags</Label>
              <div>
                {tags.map((item, i) => (
                  <Badge color="primary" key={i}>
                    {item}
                  </Badge>
                ))}
              </div>
            </Col>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default General;
