import React, {useEffect, useState} from 'react';


function InputPlus(props) {
    const {defaultValue,changeOrderQuantity,cardId,key} = props;
    const [quantity, setQuantity] = useState(defaultValue);
    useEffect(()=>{
             setQuantity(defaultValue);
    },[defaultValue])
    const handelChange = (e) => {
        changeOrderQuantity(e.target.value,cardId);
        setQuantity(e.target.value);
    }

    const addToMinus = (isAdd) => {
        let newQuantity = Number(quantity);
        if (isAdd) {
            newQuantity = newQuantity + 1;
        } else {
            newQuantity = newQuantity - 1;
        }
        changeOrderQuantity(newQuantity,cardId);
        setQuantity(newQuantity);

    }

    return (
        <div style={{display: "inline-flex"}} key={key}>
            <button className="btn-primary btn btn-xs" onClick={() => {
                addToMinus(false);
            }}><i className="fa fa-minus"></i>
            </button>
            <input   value={quantity} onChange={handelChange} type="number" className="form-control w-25 text-center"/>
            <button className="btn-primary btn btn-xs" onClick={() => {
                addToMinus(true);
            }}><i className="fa fa-plus"></i>
            </button>
        </div>
    );
}

export default InputPlus;