import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, Card, Nav } from "reactstrap";
import ModalFormSubmit from "../../../../share-components/form/modalFormSubmit";
import Breadcrumb from "../../../../layout/breadcrumb";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import withReducer from "../../../../store/withReducer";
import orderSettingReducer from "../store/store";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import {
  closeEditDialog,
  deleteDeliveryPartner,
  getOrderDeliveryPartnerSettings,
  openEditDialog,
  openNewDialog,
  storeDeliveryPartner,
  updateDeliveryPartner,
} from "../store/deliveryPartnerSlice";
import DeliveryPartnerSettingForm from "./deliveryPartnerForm";

const defaultValues = {
  id: "",
  name: "",
};
const dataMenu = [
  {
    type: 0,
    text: "Order",
    link: "/order",
  },
  {
    type: 1,
    text: "Order Setting",
    link: "",
  },
  {
    type: 1,
    text: "Delivery Partner",
    link: "",
  },
];
const schema = yup.object().shape({
  name: yup.string().required("This is required field"),
});
const OrderDeliveryPartnerSetting = () => {
  const [deliveryPartners, setDeliveryPartners] = useState([]);
  const [isCreated, setIsCreated] = useState(false);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState,
    reset,
    control,
    register,
    setValue,
    trigger,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { errors } = formState;

  const onSubmitForm = (dialog, data) => {
    const formData = {
      id: data.id,
      name: data.name,
    };
    if (dialog.type === "new") {
      dispatch(storeDeliveryPartner({ ...formData })).then((res) => {
        if (!res.error) {
          setIsCreated(true);
          closeDialog(dialog.type);
        }
      });
    } else {
      dispatch(updateDeliveryPartner({ ...formData })).then((res) => {
        if (!res.error) {
          closeDialog(dialog.type);
          setIsCreated(true);
        }
      });
    }
  };

  const closeDialog = () => {
    dispatch(closeEditDialog());
  };

  const onEdit = (row) => {
    dispatch(openEditDialog(row));
  };

  const onDelete = (row) => {
    dispatch(deleteDeliveryPartner(row.id));
    setIsCreated(true);
  };

  useEffect(() => {
    dispatch(getOrderDeliveryPartnerSettings()).then((res) => {
      setDeliveryPartners(res.payload);
      setIsCreated(false);
    });
  }, [dispatch, isCreated]);

  const tableColumns = [
    {
      name: "Delivery Partner",
      selector: (row) => row.name,
      sortable: true,
      center: false,
    },
  ];

  return (
    <Fragment>
      <Container fluid className="">
        <Breadcrumb data={dataMenu} title="Delivery Partner" />
        <div>
          <div>
            <Row>
              <Col sm="12">
                <Card>
                  <Row>
                    <Col sm="6">
                      <Nav tabs className="border-tab"></Nav>
                    </Col>
                    <Col sm="6">
                      <div className="float-sm-right p-3">
                        <a
                          className="btn btn-sm btn-primary"
                          onClick={() => {
                            dispatch(openNewDialog());
                          }}
                        >
                          {" "}
                          <i className="fa fa-plus"></i> Add New Method
                        </a>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>

            <CommonDataTable
              headerColumns={tableColumns}
              gridData={deliveryPartners}
              onEdit={onEdit}
              // onDelete={onDelete}
            />
          </div>

          <ModalFormSubmit
            defaultValues={defaultValues}
            schema={schema}
            formState={formState}
            handleSubmit={handleSubmit}
            onSubmitForm={onSubmitForm}
            noAction
            reset={reset}
            title="Delivery Partner"
            selector="orderSetting"
            dialog={({ deliveryPartnerSetting }) =>
              deliveryPartnerSetting.deliveryPartnerSetting.addDialog
            }
            closeDialog={closeDialog}
            setValue={setValue}
            getValues={getValues}
            btnText="Save"
          >
            <DeliveryPartnerSettingForm
              control={control}
              errors={errors}
              register={register}
              setValue={setValue}
              trigger={trigger}
              getValues={getValues}
            />
          </ModalFormSubmit>
        </div>
      </Container>
    </Fragment>
  );
};

export default withReducer([{ deliveryPartnerSetting: orderSettingReducer }])(
  OrderDeliveryPartnerSetting
);
