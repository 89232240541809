import React, { Fragment, useRef } from "react";
import JqxTable from "../../../../../../share-components/table/JqxTable";
import * as ReactDOMServer from "react-dom/server";
import { jqx } from "../../../../../../custom_modules/jqx/jqwidgets-react-tsx/jqxgrid";
import { useDispatch, useSelector } from "react-redux";
import { findIndexes } from "../../../../../../utils/common";

const ReturnJqx = (props) => {
  const {
    setValue,
    trigger,
    getValues,
    returnState,
    orderedProducts,
    orderDiscount,
  } = props;
  console.log("Return ", getValues("discount"));
  const dispatch = useDispatch();
  let gridRef = useRef(null);
  let selectRef = useRef(null);
  const orderState = useSelector(({ order }) => order.order);

  const reasonSources = (reasonData) => {
    console.log("Reason Data:", reasonData);
    return {
      datatype: "array",
      datafields: [
        { name: "id", type: "string" },
        { name: "name", type: "string" },
        { name: "is_restock", type: "string" },
      ],
      localdata: reasonData,
    };
  };

  const reasonAdapter: any = new jqx.dataAdapter(
    reasonSources(returnState.settings.return_reasons),
    { autoBind: true }
  );

  const datafields = [
    { name: "order_quantity", type: "number" },
    { name: "product", type: "array" },
    { name: "quantity", type: "number" },
    { name: "original_price", type: "number" },
    { name: "total_price", type: "number" },
    { name: "approve_line_item_id", type: "number" },
    { name: "re_stock", type: "string" },
    { name: "warehouse_declined", type: "string" },
    { name: "is_declined", type: "boolean" },
    { name: "reason", type: "string" },
    { name: "is_selected", type: "boolean" },
  ];

  const columns = [
    { datafield: "approve_line_item_id", hidden: true },
    { datafield: "tax_amount", hidden: true },
    {
      text: "Product",
      datafield: "product",
      editable: false,
      columntype: "text",
      align: "center",
      width: "35.6%",
      cellsrenderer(row, columnfield, value, defaulthtml, columnproperties) {
        const productDetails = getValues("ordered_products")[row].product;
        const html = ReactDOMServer.renderToString(
          <div style={{ textAlign: "center", padding: 5 }}>
            {productDetails && (
              <div className="row">
                <div className="col-md-3">
                  <img
                    className="img-fluid"
                    src={productDetails.image}
                    width="40"
                    alt=""
                  />
                </div>
                <div className="col-md-9">
                  <div className="row">
                    <p className={"font-primary"}>{productDetails.name}</p>
                  </div>
                  <div className="row">
                    <p>{productDetails.option}</p>
                  </div>
                  <div className="row">
                    <p>{productDetails.sku}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
        return html;
      },
    },
    {
      text: "Quantity",
      datafield: "quantity",
      editable: false,
      columntype: "text",
      width: "8%",
      cellsalign: "right",
      align: "center",
    },
    {
      text: "Rate",
      datafield: "original_price",
      editable: false,
      columntype: "text",
      width: "8%",
      cellsalign: "right",
      align: "center",
      cellsformat: "c2",
      cellsrenderer(row, columnfield, value, defaulthtml, columnproperties) {
        const productDetails = getValues("ordered_products")[row];
        const html = ReactDOMServer.renderToString(
          <div style={{ textAlign: "right", padding: 5, marginTop: 24 }}>
            {productDetails && (
              <div>
                {productDetails?.currency?.symbol} {productDetails.original_price}
              </div>
            )}
          </div>
        );
        return html;
      },
    },
    {
      text: "Amount",
      datafield: "total_price",
      editable: false,
      columntype: "text",
      width: "8%",
      cellsalign: "right",
      align: "center",
      cellsformat: "c2",
      cellsrenderer(row, columnfield, value, defaulthtml, columnproperties) {
        const productDetails = getValues("ordered_products")[row];
        const html = ReactDOMServer.renderToString(
          <div style={{ textAlign: "right", padding: 5, marginTop: 24 }}>
            {productDetails && (
              <div>
                {productDetails?.currency?.symbol} {productDetails.total_price}
              </div>
            )}
          </div>
        );
        return html;
      },
    },
    {
      text: "Reason",
      datafield: "reason",
      editable: false,
      columntype: "text",
      width: "12%",
      cellsalign: "left",
      align: "center",
      cellsformat: "c2",
    },
    {
      text: "Re Stock",
      datafield: "re_stock",
      editable: false,
      columntype: "text",
      width: "8%",
      cellsalign: "left",
      align: "center",
      cellsformat: "c2",
    },
    {
      text: "Warehouse Declined",
      datafield: "warehouse_declined",
      editable: false,
      columntype: "text",
      width: "10%",
      cellsalign: "left",
      align: "center",
      cellsformat: "c2",
    },
    {
      text: "Decline",
      datafield: "is_declined",
      editable: true,
      columntype: "checkbox",
      width: "8%",
      cellsalign: "right",
      align: "center",
      cellsformat: "c2",
    },
  ];
  const getJqxRef = (jqx) => {
    if (jqx) {
      gridRef = jqx;

      setTimeout(() => {
        if (gridRef) {
          const myEle = document.getElementById(gridRef._id);
          if (myEle) {
            gridRef.updatebounddata();
          }
        }
      }, 1000);
    }
  };
  const onDelete = (data) => {
    setDataToHookForm();
  };

  const cellEdit = (event) => {
    const rowArgs = event.args;
    if (rowArgs.datafield == "return_quantity") {
      const singlePrice = gridRef.getcellvalue(
        rowArgs.rowindex,
        "original_price"
      );
      setTotalAmount(rowArgs.rowindex, rowArgs.value, singlePrice);
    }
    gridRef.setcellvalue(rowArgs.rowindex, rowArgs.datafield, rowArgs.value);
    setDataToHookForm();
  };

  const setTotalAmount = (index, quantity, singlePrice) => {
    const totalPrice = quantity * singlePrice;
    gridRef.setcellvalue(index, "total_price", totalPrice);
  };
  const setDataToHookForm = () => {
    let orderData = [];

    const gridInformation = gridRef.getdatainformation();
    for (let i = 0; i < gridInformation.rowscount; i++) {
      const rowData = gridRef.getrowdata(i);
      orderData.push(rowData);
    }
    setValue("ordered_products", orderData);
  };

  const calculateTotals = (orderData) => {
    let totalCreditAmount = 0;
    let totalDiscount = 0;
    let subTotalCredit = 0;
    let shipment = 0;
    let totalTax = 0;
    let netTotalCredit = 0;
    let discountAmount = 0;
    let subTotalPrice = 0;
    let shippingAmount = 0;
    let taxAmount = 0;

    orderData.forEach((item) => {
      const returnQty = item.return_quantity || 0;

      totalCreditAmount += returnQty * item.original_price;

      if (item.discount) {
        if (item.discount.discount_type == 1) {
          discountAmount = Number(item.discount.value);
        } else {
          discountAmount = subTotalPrice * (Number(item.discount.value) / 100);
        }
      }
      console.log("discount ", discountAmount);
      if (item.shipping) {
        if (item.shipping.shipping_type == 2) {
          shippingAmount = Number(item.shipping.price);
        }
      }
      if (discountAmount) {
        totalDiscount += returnQty * discountAmount;
      }
      if (item.tax) {
        if (item.tax.is_charge) {
          const totalTaxablePrice = subTotalPrice - discountAmount;
          taxAmount = Number(item.tax.percentage);
        }
      }

      subTotalCredit = totalCreditAmount - totalDiscount;

      totalTax += returnQty * taxAmount;

      shipment = shippingAmount;

      netTotalCredit = subTotalCredit + shipment + totalTax;
    });

    props.onTotalsChange({
      totalCreditAmount,
      totalDiscount,
      subTotalCredit,
      shipment,
      totalTax,
      netTotalCredit,
    });
  };

  const handelRowSelect = (event) => {
    const orderProducts = getValues("ordered_products");
    const rowIndexes = gridRef.getselectedrowindexes();

    const orderData = orderProducts.map((item, index) => {
      const taxAmount = item.is_tax ? item.rate * 0.2 : 0;
      const isSelected = rowIndexes.includes(index);
      const quantity = isSelected ? item.order_quantity : 0;
      const taxTotalAmount = taxAmount + item.rate;

      const discount = getValues("discount");
      const shipping = getValues("shipping");
      const tax = getValues("tax");

      return {
        ...item,
        return_quantity: 1,
        total_price: item.total_price,
        is_selected: isSelected,
        discount: discount,
        shipping: shipping,
        tax: tax,
      };
    });

    calculateTotals(orderData);

    const selectedData = orderData
      .filter((item, index) => rowIndexes.includes(index))
      .map((item) => ({ ...item, is_selected: true }));

    setValue("ordered_products", orderData);
    trigger("ordered_products");

    props.handleSelectedRows(selectedData);
  };

  return (
    <Fragment>
      <div style={{ width: "100%" }}>
        {getValues("ordered_products").length > 0 && (
          <JqxTable
            // deleteAll
            rowsheight={75}
            datafields={datafields}
            columns={columns}
            data={getValues("ordered_products")}
            myGrid={gridRef}
            getJqxRef={getJqxRef}
            autoheight={getValues("ordered_products").length < 7}
            // isDelete
            scrollerbar
            height="500"
            onCellendedit={cellEdit}
            disableCreateRow
            // onDelete={onDelete}
            onRowselect={handelRowSelect}
            onRowunselect={handelRowSelect}
            selectionmode={"checkbox"}
            selectedrowindexes={findIndexes(
              getValues("ordered_products"),
              (item) => item.is_selected
            )}
          />
        )}
      </div>
    </Fragment>
  );
};

function areEqual(prevProps, nextProps) {
  return (
    JSON.stringify(prevProps.orderedProducts) !=
    JSON.stringify(nextProps.orderedProducts)
  );
}

export default ReturnJqx;
