import API from "../../../utils/api";

export const getDeliveryPartnersApi = async (filters) => {
  const response = await API.get("order/settings/delivery-partner", {
    params: filters,
  });
  return { response };
};

export const addDeliveryPartnerApi = async (location) => {
  const response = await API.post("order/settings/delivery-partner", location);
  return { response };
};

export const updateDeliveryPartnerApi = async (location, id) => {
  const response = await API.put(`order/settings/delivery-partner/${id}`, location);
  return { response };
};

export const deleteDeliveryPartnerApi = async (id) => {
  const response = await API.delete(`order/settings/delivery-partner/${id}`);
  return { response };
};