import React, { Fragment } from "react";
import { numberWithCommasWithoutPounds } from "../../../../../utils/common";
import { Col, Table } from "reactstrap";
import BinLocationModal from "./binLocationModal";
import { setSalesOrderBinLocationDialogProps } from "../../../store/salesOrderSlice";
import { useDispatch, useSelector } from "react-redux";

const SaleOrderProductJqx = (props) => {
  const { getValues, setValue, trigger, binButton } = props;
  const dispatch = useDispatch();

  const productsResponseToTable = (response) => {
    return response.map((res) => {
      return {
        line_item_id: res.id,
        product_id: res.product_id,
        product_image: res.image,
        variant: res.variant,
        total_price: res.total_price,
        tax_amount: res.tax_amount,
        product_name: res.product_name,
        variant_name: res.variant_name,
        sku: res.sku,
        stock: res.stock,
        back_order_quantity: res.back_order_quantity,
        quantity: res.quantity,
        location: res.location,
        total_invoiced_qty: res.total_invoiced_qty,
        total_fulfilled_qty: res.total_fulfilled_qty,
        gross_amount: res.gross_amount,
        size_id: res.size_id,
        size_maxtrix: res.size_maxtrix,
        item_discount: res.item_discount,
      };
    });
  };

  const createBinLocation = (item) => {
    const data = {
      props: { isOpen: true },
      type: "edit",
      data: {
        id: item.line_item_id,
        sku: item.sku,
        orderedQty: item.quantity,
        location: item.location,
      },
    };

    dispatch(setSalesOrderBinLocationDialogProps(data));
  };

  return (
    <Fragment>
      <Col md={12}>
        <div
          className="table-responsive w-100"
          style={{ maxHeight: "400px", overflowY: "auto" }}
        >
          <Table>
            <thead
              className="thead-light"
              style={{ position: "sticky", top: 0, zIndex: 1 }}
            >
              <tr>
                <th style={{ width: "28%" }}>Product</th>
                <th style={{ textAlign: "center", width: "10%" }}>Available</th>
                <th style={{ textAlign: "center", width: "12%" }}>
                  Back Ordered ({getValues("sum_back_order_quantity")})
                </th>
                <th style={{ textAlign: "center", width: "10%" }}>
                  Order Qty ({getValues("sum_quantity")})
                </th>
                <th style={{ textAlign: "center", width: "9%" }}>
                  Invoiced Qty
                </th>
                <th style={{ textAlign: "center", width: "9%" }}>
                  Fulfilled Qty
                </th>
                <th style={{ textAlign: "center", width: "22%" }}>Amount</th>
              </tr>
            </thead>
            <tbody>
              {productsResponseToTable(getValues("ordered_products")).map(
                (item, i) => (
                  <tr key={i}>
                    <td>
                      <div className="row">
                        <div className="col-md-3">
                          <img
                            className="img-fluid"
                            src={item.product_image}
                            width="40"
                            alt=""
                          />
                        </div>
                        <div className="col-md-9">
                          <div className="row">
                            <p className={"font-primary"}>
                              {item.product_name}
                            </p>
                          </div>
                          <div className="row">
                            <p>{item.option}</p>
                          </div>
                          <div className="row d-flex">
                            <p>{item.sku}</p>
                            {binButton && (
                              <button
                                onClick={() => createBinLocation(item)}
                                className="ml-auto mr-1"
                                style={{ background: "none" }}
                                title="Create Fulfillment"
                              >
                                <i
                                  className="fa fa-external-link"
                                  style={{
                                    fontSize: 16,
                                    color: "rgb(0, 128, 255)",
                                  }}
                                ></i>
                              </button>
                            )}
                          </div>
                          {item.size_id == 1 ? (
                            <div className="row">
                              <p>{item.size_maxtrix}</p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <div style={{ marginTop: "1rem" }}>{item.stock}</div>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <div style={{ marginTop: "1rem" }}>
                        {item.back_order_quantity}
                      </div>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <div style={{ marginTop: "1rem" }}>{item.quantity}</div>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <div style={{ marginTop: "1rem" }}>
                        {item.total_invoiced_qty}
                      </div>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <div style={{ marginTop: "1rem" }}>
                        {item.total_fulfilled_qty}
                      </div>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <div className="col-12">
                        <div className="row">
                          <div className="col-12">
                            <div className="row">
                              <div className="col-12">
                                <p className="font-primary text-center font-weight-bolder">
                                  Sub / Discount
                                </p>
                              </div>
                              <div className="col-12">
                                <p className="text-center">
                                  {getValues("currency")?.symbol}
                                  {numberWithCommasWithoutPounds(
                                    item.total_price
                                  )}{" "}
                                  / {getValues("currency")?.symbol}
                                  {numberWithCommasWithoutPounds(
                                    item.item_discount
                                  )}
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <p className="font-primary text-center font-weight-bolder">
                                  Gross : {getValues("currency")?.symbol}
                                  {numberWithCommasWithoutPounds(
                                    item.gross_amount
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </div>
      </Col>
      <BinLocationModal
        orderSetValue={setValue}
        orderTrigger={trigger}
        orderGetValues={getValues}
      ></BinLocationModal>
    </Fragment>
  );
};

export default SaleOrderProductJqx;
